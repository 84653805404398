<script setup lang="ts">
import { computed, type PropType } from 'vue'

const tagClassMap = {
    blue: 'bg-blue-100 text-blue-800',
    gray: 'bg-gray-100 text-gray-800',
    green: 'bg-green-100 text-green-800',
    orange: 'bg-orange-100 text-orange-800',
    red: 'bg-red-100 text-red-800',
    yellow: 'bg-yellow-100 text-yellow-800',
    cyan: 'bg-cyan-100 text-cyan-900',
    fuchsia: 'bg-fuchsia-100 text-fuchsia-900',
    pink: 'text-pink-800 bg-pink-100',
    stone: 'text-stone-800 bg-stone-100',
    lightBlue: 'text-secondary-500 bg-secondary-50',
    fonkyOrange: 'text-primary-800 bg-primary-100',
    fonkyBlue: 'text-secondary-800 bg-secondary-100',
} as const

type Color = keyof typeof tagClassMap

const props = defineProps({
    color: {
        type: String as PropType<Color>,
        default: 'gray',
    },
})

const selectedColorStyle = computed(() => tagClassMap[props.color] || '')
</script>

<template>
    <span class="inline-flex rounded-full px-2 text-xs font-semibold leading-5" :class="selectedColorStyle">
        <slot></slot>
    </span>
</template>
