import './axios'
import './echo'

import { createInertiaApp } from '@inertiajs/vue3'
import { createApp, h, type App } from 'vue'
import Layout from './layouts/Layout.vue'

import * as Sentry from '@sentry/vue'
import FloatingVue from 'floating-vue'
import { i18nVue } from 'laravel-vue-i18n'
import { ZiggyVue } from 'ziggy-js'

import '@vuepic/vue-datepicker/dist/main.css'
import 'floating-vue/dist/style.css'
import '../css/app.css'

createInertiaApp({
    progress: {
        color: '#DA5D24',
    },
    resolve(name) {
        const pages = import.meta.glob('./pages/**/*.vue', { eager: true })

        const page = pages[`./pages/${name}.vue`] as any

        page.default.layout = page.default.layout || Layout

        return page
    },
    setup({ el, App, props, plugin }) {
        const vueApp = createApp({ render: () => h(App, props) })

        vueApp.use(plugin)
        vueApp.use(ZiggyVue)
        vueApp.use(FloatingVue)
        vueApp.use(i18nVue, {
            resolve(lang: string) {
                const langs = import.meta.glob('../lang/*.json', {
                    eager: true,
                }) as any

                return langs[`../lang/${lang}.json`].default
            },
        })

        if (import.meta.env.VITE_APP_ENV !== 'local') {
            configureSentry(vueApp)
        }

        vueApp.mount(el)
    },
    title: (title) => (title ? `${title} - Mijn Fonky` : 'Mijn Fonky'),
})

function configureSentry(app: App) {
    Sentry.init({
        app: app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.VITE_APP_ENV,
        // Performance Monitoring
        tracesSampleRate: 0,
        // Session Replay
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 1.0,
    })
}
