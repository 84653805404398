<script setup lang="ts">
import ContractStatusLabel from '@app/components/contracts/ContractStatusLabel.vue'
import ActivityFeedTemplate from '@app/components/ui/activityFeed/ActivityFeedTemplate.vue'
import ActivityFeedTemplateHeader from '@app/components/ui/activityFeed/ActivityFeedTemplateHeader.vue'
import type { ContractStatusActivityFeed } from '@app/types/activity-feed'
import type { ContractStatus } from '@app/types/contracts'
import { PlayIcon } from '@heroicons/vue/24/outline'
import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'

const props = defineProps<{
    activity: ContractStatusActivityFeed
}>()

const statuses = computed(() => usePage().props.contractStatuses as ContractStatus[])

const oldStatus = computed(() => statuses.value.find((status) => status.slug === props.activity.old_status))
const newStatus = computed(() => statuses.value.find((status) => status.slug === props.activity.new_status))
</script>

<template>
    <ActivityFeedTemplate>
        <template #icon>
            <PlayIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
        </template>
        <template #header>
            <ActivityFeedTemplateHeader :name="activity.causer_full_name" :header="$t('activities.updated_status')" :created_at="activity.created_at" />
        </template>
        <template #content>
            {{ $t('attributes.status') }}
            {{ $t('activities.updated_from') }}
            <ContractStatusLabel :status="oldStatus" />
            {{ $t('activities.updated_to') }}
            <ContractStatusLabel :status="newStatus" />
        </template>
    </ActivityFeedTemplate>
</template>
