<script setup lang="ts" generic="T extends { id: number }">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useActiveButton } from '@app/composables/useDetailCard'
import { useUserCan } from '@app/composables/useUserCan'
import { DocumentIcon } from '@heroicons/vue/24/solid'
import NoteForm from './NoteForm.vue'

defineProps<{ model: T; notableType: string }>()

const { toggle } = useActiveButton()
const { can } = useUserCan()
</script>

<template>
    <RoundButton
        v-if="can('note.store')"
        :label="$t('note.action')"
        @click="
            toggle(NoteForm, {
                notableId: model.id,
                notableType,
            })
        "
    >
        <DocumentIcon class="h-4 w-4" aria-hidden="true" />
    </RoundButton>
</template>
