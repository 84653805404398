<script setup>
import { useLocale } from '@app/composables/useLocale'

const { dbTranslate } = useLocale()

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    avatarSrc: {
        type: String,
        required: true,
    },
    avatarName: {
        type: String,
        required: true,
    },
    primaryName: {
        type: String,
        required: true,
    },
    secondaryName: {
        type: String,
        required: true,
    },
    primaryTranslate: {
        type: Boolean,
        default: false,
    },
    secondaryTranslate: {
        type: Boolean,
        default: false,
    },
})
</script>

<template>
    <div class="flex items-center">
        <div class="h-10 w-10 flex-shrink-0">
            <img class="h-10 w-10 rounded-full" :src="item[avatarSrc]" :alt="item[avatarName]" />
        </div>
        <div class="ml-4">
            <div class="font-medium text-gray-900">
                {{ primaryTranslate ? dbTranslate(item[primaryName]) : item[primaryName] }}
            </div>
            <div class="text-gray-500">
                {{ secondaryTranslate ? dbTranslate(item[secondaryName]) : item[secondaryName] }}
            </div>
        </div>
    </div>
</template>
