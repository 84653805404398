<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { ArrowRightEndOnRectangleIcon } from '@heroicons/vue/24/solid'
import { useUserCan } from '@app/composables/useUserCan'
import { computed, type PropType } from 'vue'
import type { Authenticatable } from '@app/types/shared'
import { useForm } from '@inertiajs/vue3'
import { route } from 'ziggy-js'

const { can, isSuperAdmin, isSameUser } = useUserCan()

const props = defineProps({
    user: {
        type: Object as PropType<Authenticatable>,
        required: true,
    },
})

const show = computed(() => can('auth.admin.login-as-user') && !isSuperAdmin(props.user) && !isSameUser(props.user))

const form = useForm({
    user_type: props.user.type,
})

function loginAsUser() {
    form.post(route('user.admin.login-as-user', props.user.id))
}
</script>

<template>
    <RoundButton v-if="show" :label="$t('navigation.login_as_user')" @click="loginAsUser">
        <ArrowRightEndOnRectangleIcon class="h-4 w-4" aria-hidden="true" />
    </RoundButton>
</template>
