<script setup lang="ts">
import { addOrRemoveValueFromArray } from '@app/utils/array'
import { computed, inject, type ModelRef } from 'vue'

const model = inject('multi-toggle:model') as ModelRef<Array<String | Number>>

const props = defineProps({
    value: {
        type: [String, Number],
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
})

const selected = computed(() => model.value.includes(props.value))
</script>

<template>
    <button
        type="button"
        @click="addOrRemoveValueFromArray(model, value)"
        :class="[
            selected ? 'bg-primary-500 text-white' : 'bg-zinc-100 text-zinc-500',
            disabled && 'opacity-50',
            'flex h-8 w-8 cursor-pointer items-center justify-center rounded-full text-xs font-medium uppercase leading-4 tracking-wide',
        ]"
        :disabled="disabled"
    >
        <slot>{{ value }}</slot>
    </button>
</template>
