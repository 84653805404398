<script setup lang="ts">
import { useUserCan } from '@app/composables/useUserCan'
import Settings from '@app/layouts/Settings.vue'
import type { Region, ResourceCollection } from '@app/types/shared'
import { Link } from '@inertiajs/vue3'
import { route } from 'ziggy-js'
import DataTable from '@app/components/ui/table/DataTable.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'
import Button from '@app/components/ui/button/Button.vue'

defineProps<{ regions: ResourceCollection<Region> }>()

const { can } = useUserCan()

const columns = [
    {
        type: 'string',
        header: 'ID',
        properties: { name: 'id' },
    },
    {
        type: 'string',
        header: 'attributes.name',
        properties: { name: 'name', translate: true },
    },
    {
        name: 'actions',
        header: 'actions.header',
        show: can('region.store'),
    },
]
</script>

<template>
    <Settings group="general">
        <div class="mb-4 md:flex md:items-center md:justify-between">
            <div class="flex place-items-center">
                <Button v-if="can('region.store')" :as="Link" :href="route('settings.region.create')"> {{ $t('actions.create') }} </Button>
            </div>
        </div>
        <DataTable class="flex w-full flex-col rounded-lg border border-gray-100 transition-all duration-500" :items="regions" :columns="columns">
            <template #filter>
                <FilterSection class="mb-2" />
            </template>
            <template v-slot:column.actions="{ item }">
                <Link v-if="can('region.store')" class="mr-2 text-primary-600" :href="route('settings.region.edit', { slug: item.slug })">
                    {{ $t('actions.update') }}
                </Link>
            </template>
        </DataTable>
    </Settings>
</template>
