<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import Input from '@app/components/ui/form/Input.vue'
import { useModelSelect } from '@app/composables/useModelSelect'
import type { Client } from '@app/types/shared'
import { router, useForm } from '@inertiajs/vue3'
import { type PropType } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps({
    clients: {
        type: Array as PropType<Client[]>,
        required: true,
    },
})

const selectedClient = useModelSelect(props.clients)

const form = useForm({
    email: '',
    first_name: '',
    last_name: '',
    client_id: selectedClient.value?.id || null,
})

function submit() {
    form.post(route('user.client.store'))
}

function back() {
    router.get(route('user.client.index'))
}
</script>

<template>
    <Form :form @submit="submit" class="max-w-xl">
        <FormSection>
            <FormField class="col-span-12" prop="email" :label="$t('attributes.email')">
                <Input id="email" v-model="form.email" />
            </FormField>

            <FormField class="col-span-12 md:col-span-6" prop="first_name" :label="$t('attributes.first_name')">
                <Input id="first_name" v-model="form.first_name" />
            </FormField>

            <FormField class="col-span-12 md:col-span-6" prop="last_name" :label="$t('attributes.last_name')">
                <Input id="last_name" v-model="form.last_name" />
            </FormField>

            <FormField prop="client_id" :label="$t('attributes.client')" class="col-span-12">
                <Dropdown
                    :items="clients"
                    :translateDb="false"
                    propertyName="name"
                    label="Select client"
                    v-model="selectedClient"
                    v-model:form="form.client_id"
                />
            </FormField>
        </FormSection>

        <FormActions>
            <Button type="button" color="white" @click="back">{{ $t('buttons.cancel') }}</Button>
            <SaveButton />
        </FormActions>
    </Form>
</template>
