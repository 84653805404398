<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import Input from '@app/components/ui/form/Input.vue'
import TranslatableInput from '@app/components/ui/form/TranslatableInput.vue'
import { useLocale } from '@app/composables/useLocale'
import { useModelSelect } from '@app/composables/useModelSelect'
import Settings from '@app/layouts/Settings.vue'
import type { JobTitle, WorkType } from '@app/types/shared'
import { useForm } from '@inertiajs/vue3'
import { route } from 'ziggy-js'

const props = defineProps<{ jobTitle?: JobTitle; workTypes: WorkType[] }>()

const { initializeEmptyTranslatablePropertyModel } = useLocale()

const selectedWorkType = useModelSelect(props.workTypes, props.jobTitle?.work_type_id)

const form = useForm({
    name: props.jobTitle?.name || initializeEmptyTranslatablePropertyModel(),
    slug: props.jobTitle?.slug || '',
    work_type_id: selectedWorkType.value?.id,
})

function submit() {
    if (props.jobTitle) {
        form.put(route('settings.job-title.update', props.jobTitle.id))
    } else {
        form.post(route('settings.job-title.store'))
    }
}
</script>

<template>
    <Settings group="general">
        <Form :form="form" @submit="submit" class="max-w-xl">
            <FormField prop="name" class="col-span-12">
                <TranslatableInput prop="name" :label="$t('attributes.name')" v-model="form.name" />
            </FormField>

            <FormField prop="slug" class="col-span-12" label="Slug">
                <Input v-model="form.slug" />
            </FormField>

            <FormField prop="work_type_id" class="col-span-12" :label="$t('attributes.work_type')">
                <Dropdown
                    :label="$t('attributes.work_type')"
                    :items="workTypes"
                    v-model:form="form.work_type_id"
                    v-model="selectedWorkType"
                    propertyName="name"
                />
            </FormField>

            <FormActions>
                <Button :disabled="form.processing">{{ $t('buttons.save') }}</Button>
            </FormActions>
        </Form>
    </Settings>
</template>
