<script setup lang="ts">
import MutedMicrophone from '@app/components/icons/MutedMicrophone.vue'
import { useSettings } from '@app/composables/settings/useSettings'
import { useChannel } from '@app/composables/useEcho'
import { useTimer } from '@app/composables/useTimer'
import type { CallRecipient, CallStatus } from '@app/types/calls'
import { getMfsLeadCreateUrl } from '@app/utils/mfs'
import { MicrophoneIcon, PhoneXMarkIcon } from '@heroicons/vue/20/solid'
import { InformationCircleIcon, PlusIcon } from '@heroicons/vue/24/outline'
import { Link } from '@inertiajs/vue3'
import { trans } from 'laravel-vue-i18n'
import { computed, onMounted, ref, watch } from 'vue'
import CloseModalButton from './CloseModalButton.vue'
import RecordLabel from './RecordLabel.vue'

const { recipient, status, collapsed, muted, direction } = defineProps<{
    recipient: CallRecipient
    status: CallStatus
    collapsed: boolean
    direction: 'incoming' | 'outgoing'
    muted: boolean
}>()

const emit = defineEmits(['update:muted', 'dismiss', 'disconnect', 'createLead'])

const minRecordingDuration = useSettings('min-call-duration-seconds', 30)

const { stopTimer, resetTimer, startTimer, stopwatch, seconds } = useTimer()

const showRecordingLabel = ref(false)

if (direction === 'outgoing') {
    watch(seconds, () => {
        if (seconds.value >= minRecordingDuration.value) {
            showRecordingLabel.value = true
        }
    })
}

const isInProgress = computed(() => status === 'in-progress')
const isCallCompleted = computed(() => ['disconnected', 'missed'].includes(status))
const translatedCallStatus = computed(() => trans(`call.status.${status}`))
const showLeadActions = computed(() => !collapsed || isCallCompleted.value)

function handleCallTimer() {
    if (!isInProgress.value) {
        stopTimer()
    } else {
        resetTimer()
        startTimer()
    }
}

const { subscribe } = useChannel('recruitment')

onMounted(() => {
    handleCallTimer()

    subscribe('.recruitment.lead.created', (e: any) => {
        if (recipient.id) return

        if (e.phone_number === recipient.phone_number) {
            emit('createLead', e.id)
        }
    })
})

watch(isInProgress, handleCallTimer)
</script>

<template>
    <CloseModalButton @click="emit('dismiss')" v-if="isCallCompleted" />

    <div class="flex w-full flex-col gap-4 pr-2 ease-in-out">
        <div v-if="!collapsed" class="flex items-start justify-between">
            <div>
                <p class="text-base font-semibold leading-6 text-white">{{ recipient.full_name }}</p>
                <p class="mt-1 text-sm text-white">{{ recipient.phone_number }}</p>
            </div>

            <RecordLabel v-if="showRecordingLabel && !isCallCompleted" />
        </div>

        <div class="flex items-center justify-between gap-x-4 transition-all">
            <div class="rounded-full bg-zinc-50 p-2 font-mono text-lg font-medium leading-6 text-primary-600" v-if="status !== 'missed'">
                {{ stopwatch }}
            </div>

            <div v-if="!collapsed" class="items-center overflow-hidden text-base font-normal leading-5 text-white">
                {{ translatedCallStatus }}
            </div>

            <div class="flex items-center gap-4">
                <div v-if="showLeadActions">
                    <Link v-if="recipient.href" class="mx-auto flex w-8 cursor-pointer flex-col items-center" :href="recipient.href">
                        <span class="sr-only">{{ $t('Open') }}</span>
                        <div
                            class="flex h-9 w-9 items-center justify-center rounded-full bg-gray-200 transition-all duration-150 ease-in-out hover:bg-gray-300"
                        >
                            <InformationCircleIcon class="h-5 w-5 text-stone-700" aria-hidden="true" />
                        </div>
                    </Link>
                    <a
                        v-else
                        class="mx-auto flex w-8 cursor-pointer flex-col items-center"
                        :href="getMfsLeadCreateUrl()"
                        target="_blank"
                        :title="$t('call.actions.create_lead')"
                    >
                        <span class="sr-only">{{ $t('call.actions.create_lead') }}</span>
                        <div
                            class="flex h-9 w-9 items-center justify-center rounded-full bg-gray-200 transition-all duration-150 ease-in-out hover:bg-gray-300"
                        >
                            <PlusIcon class="h-5 w-5 text-stone-700" aria-hidden="true" />
                        </div>
                    </a>
                </div>

                <RecordLabel v-if="showRecordingLabel && collapsed && !isCallCompleted" />

                <div v-if="isInProgress && !collapsed">
                    <button class="mx-auto flex w-8 cursor-pointer flex-col items-center">
                        <span class="sr-only">{{ !muted ? $t('call.actions.mute') : $t('call.actions.unmute') }}</span>
                        <div
                            class="flex h-9 w-9 items-center justify-center rounded-full bg-gray-200 transition-all duration-150 ease-in-out hover:bg-gray-300"
                        >
                            <MicrophoneIcon v-if="!muted" class="h-5 w-5 text-stone-700" aria-hidden="true" @click="emit('update:muted', true)" />
                            <MutedMicrophone v-else class="h-4 w-4 text-red-700" aria-hidden="true" @click="emit('update:muted', false)" />
                        </div>
                    </button>
                </div>
                <div v-if="status === 'ringing' || isInProgress">
                    <button class="mx-auto flex w-8 cursor-pointer flex-col items-center transition-all duration-150 ease-in-out" @click="emit('disconnect')">
                        <span class="sr-only">{{ $t('call.actions.hangup') }}</span>
                        <div class="flex h-9 w-9 items-center justify-center rounded-full bg-gray-200 hover:bg-gray-300">
                            <PhoneXMarkIcon class="h-5 w-5 text-red-700" aria-hidden="true" />
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
