<script setup lang="ts">
import { useColor } from '@app/composables/useColor'
import Tag from './Tag.vue'
import { computed } from 'vue'

const props = defineProps({
    status: {
        type: String,
        required: true,
    },
})

const { getFunnelBlockStatusColor } = useColor()

const color = computed(() => getFunnelBlockStatusColor(props.status))
</script>

<template>
    <Tag :color="color"><slot></slot></Tag>
</template>
