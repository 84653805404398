import LeadCallbackAppointment from '@app/components/recruitment/leads/outcomes/LeadCallbackAppointment.vue'
import LeadDecline from '@app/components/recruitment/leads/outcomes/LeadDecline.vue'
import LeadOnHold from '@app/components/recruitment/leads/outcomes/LeadOnHold.vue'
import LeadUpdateStatus from '@app/components/recruitment/leads/outcomes/LeadUpdateStatus.vue'
import LeadFillClientInformation from '@app/components/recruitment/leads/outcomes/approves/LeadFillClientInformation.vue'
import LeadRecruitmentAppointmentApprove from '@app/components/recruitment/leads/outcomes/approves/LeadRecruitmentAppointmentApprove.vue'
import LeadWorkshopApprove from '@app/components/recruitment/leads/outcomes/approves/LeadWorkshopApprove.vue'
import type { Lead } from '@app/types/recruitment'
import { computed, markRaw } from 'vue'
import { useModelSelect } from '../useModelSelect'

// This mapping could be replaced by loading components asynchronous by using `defineAsyncComponent`.
// However, this loads the component when required, which makes the transition look weird.
export function useLeadOutcomes(lead: Lead) {
    const outcomeComponents: Record<string, unknown> = {
        'call-attempt': markRaw(LeadUpdateStatus),
        'callback-appointment': markRaw(LeadCallbackAppointment),
        declined: markRaw(LeadDecline),
        'no-show': markRaw(LeadUpdateStatus),
        'on-hold': markRaw(LeadOnHold),
        'appointment-cancelled': markRaw(LeadUpdateStatus),
        'in-progress': markRaw(LeadUpdateStatus),
        started: markRaw(LeadUpdateStatus),
        completed: markRaw(LeadUpdateStatus),

        // funnel blocks
        introduction: markRaw(LeadRecruitmentAppointmentApprove),
        workshop: markRaw(LeadRecruitmentAppointmentApprove),
        onboarding: markRaw(LeadWorkshopApprove),
        interested: markRaw(LeadFillClientInformation),
    }

    const canBeSelectedMoreThanOnce = ['call-attempt', 'callback-appointment']

    const outcomes = computed(() => {
        return lead.status_outcomes
            .filter((item) => !!outcomeComponents[item.slug])
            .filter((item) => canBeSelectedMoreThanOnce.includes(item.slug) || item.slug !== lead.funnel_block_status.slug)
    })

    const selectedOutcome = useModelSelect(outcomes, undefined, 'slug')

    function getOutcomeComponentBySlug(slug: string) {
        return outcomeComponents[slug]
    }

    return {
        getOutcomeComponentBySlug,
        outcomes,
        selectedOutcome,
    }
}
