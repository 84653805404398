<script setup lang="ts">
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/vue/20/solid'
import { format } from 'date-fns'

defineProps({
    weekday: {
        type: String,
        required: true,
    },
    day: {
        type: Date,
        required: true,
    },
    active: {
        type: Boolean,
        default: false,
    },
    sortable: {
        type: Boolean,
        default: false,
    },
    sortedDay: {
        type: Date,
    },
})

const emit = defineEmits(['sort'])
</script>

<template>
    <div
        class="transition-color flex items-center justify-between bg-white px-4 py-3 pb-3 pt-2 duration-200 md:flex-row"
        :class="sortedDay === day ? 'bg-zinc-50' : 'bg-white'"
        @click="emit('sort', day)"
    >
        <div class="flex items-center gap-2">
            <span>{{ weekday }} </span>
            <span
                :class="[
                    active ? 'rounded-full bg-primary-600 text-white' : 'text-zinc-900',
                    'flex h-8 w-8 flex-none items-center justify-center font-semibold md:mt-0',
                ]"
                >{{ format(day, 'd') }}</span
            >
        </div>
        <ChevronDownIcon v-if="sortable && sortedDay === day" class="transition-color h-5 w-5 cursor-pointer text-primary-600 duration-200" />
        <ChevronUpIcon v-if="sortable && sortedDay !== day" class="transition-color h-5 w-5 cursor-pointer text-zinc-300 duration-200" />
    </div>
</template>
