<script setup lang="ts">
import VueDatePicker, { type TimeModel } from '@vuepic/vue-datepicker'
import { computed, ref, watch, type PropType } from 'vue'
import { getTimeFromTimeObject, timeStringToModel } from '@app/utils/date'
import { format } from 'date-fns'
import { isArray } from 'lodash'

type Range = [string | null, string | null]

const model = defineModel<[string | null, string | null] | null>({
    required: true,
})

const props = defineProps({
    errors: Object,
    date: {
        type: Object as PropType<Date>,
        required: true,
    },
    durationInMinutes: {
        type: Number,
        default: 60,
    },
})

function extractTimeModelFromDate(dateStr?: string | null) {
    const timeStr = dateStr?.split(' ')[1] || null

    return timeStringToModel(timeStr)
}

function convertTimeModelToDate(model: TimeModel | null) {
    return model ? `${format(props.date, 'yyyy-MM-dd')} ${getTimeFromTimeObject(model)}` : null
}

function populateRange(timeModel: TimeModel | null, boundary: 'from' | 'to') {
    const date = convertTimeModelToDate(timeModel)

    const rangeIndex = boundary === 'from' ? 0 : 1
    const oppositeIndex = boundary === 'from' ? 1 : 0

    if (isArray(model.value)) {
        if (date === null && model.value[oppositeIndex] === null) {
            model.value = null
        } else {
            model.value[rangeIndex] = date
        }
    } else {
        const rangeIndex = boundary === 'from' ? 0 : 1

        const range: Range = [null, null]
        range[rangeIndex] = date

        model.value = range
    }
}

const from = computed({
    get: () => extractTimeModelFromDate(model.value?.[0]),
    set: (value) => populateRange(value, 'from'),
})

const to = computed({
    get: () => extractTimeModelFromDate(model.value?.[1]),
    set: (value) => populateRange(value, 'to'),
})

const minTimeTo = computed(() => {
    return from.value ?? undefined
})

const timeInputOptions = ref({
    format: 'HH:mm',
})

watch(from, (newValue) => {
    if (newValue === null) {
        to.value = null
    } else {
        to.value = {
            hours: +newValue.hours + props.durationInMinutes / 60,
            minutes: newValue.minutes,
            seconds: 0,
        }
    }
})
</script>

<template>
    <div>
        <div class="flex items-center gap-4">
            <div class="md:w-1/3">
                <VueDatePicker
                    v-model="from"
                    time-picker
                    text-input
                    :config="{ modeHeight: 140 }"
                    :text-input-options="timeInputOptions"
                    position="left"
                    placeholder="from"
                    :start-time="{ hours: 15, minutes: 0 }"
                    :teleport="true"
                    :minutes-increment="15"
                />
            </div>
            <div class="md:w-1/3">
                <VueDatePicker
                    v-model="to"
                    time-picker
                    :config="{ modeHeight: 140 }"
                    text-input
                    :text-input-options="timeInputOptions"
                    placeholder="to"
                    position="left"
                    :start-time="{ hours: 16, minutes: 0 }"
                    :teleport="true"
                    :minutes-increment="15"
                    :min-time="minTimeTo"
                    :readonly="!from"
                />
            </div>
            <div class="flex gap-2 md:w-1/3">
                <slot name="actions"></slot>
            </div>
        </div>
    </div>
</template>
