<script setup lang="ts">
import { getDateFromDateTime, getTimeFromDateTime } from '@app/utils/date'
import { CalendarIcon } from '@heroicons/vue/20/solid'
import { computed } from 'vue'

const props = defineProps({
    from: {
        type: String,
        required: true,
    },
    to: {
        type: String,
        required: true,
    },
})

const date = computed(() => getDateFromDateTime(props.from, 'medium'))
const timeFrom = computed(() => getTimeFromDateTime(props.from))
const timeTo = computed(() => getTimeFromDateTime(props.to))
</script>

<template>
    <div class="flex items-start gap-1">
        <CalendarIcon class="h-5 w-5 text-gray-400" />

        <time :datetime="from" class="leading-6">{{
            $t('recruitment.call_appointment.details.date', {
                date,
                from: timeFrom,
                to: timeTo,
            })
        }}</time>
    </div>
</template>
