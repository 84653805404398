<script setup lang="ts">
import ContractForm from '@app/components/contracts/forms/ContractForm.vue'
import type { Contract, ContractModel, GeneratedFiles } from '@app/types/contracts'
import { useForm } from '@inertiajs/vue3'
import { route } from 'ziggy-js'

const emit = defineEmits(['content:closed'])
const props = defineProps<{ model: Contract & GeneratedFiles }>()

const form = useForm<ContractModel>({
    branch_id: props.model.branch_id,
    contract_hour_type_id: props.model.contract_hour_type_id,
    duration_months: props.model.duration_months,
    email: props.model.email,
    job_title_id: props.model.job_title.id,
    gross_monthly_salary: props.model.gross_monthly_salary.amount,
    min_hours: props.model.min_hours,
    max_hours: props.model.max_hours,
    sign_by: props.model.sign_by,
    start_date: new Date(props.model.start_date),
    trial_period: props.model.trial_period,
    include_phone_allowance: !!props.model.phone_allowance_amount,
    phone_allowance_amount: props.model.phone_allowance_amount?.amount || null,
    retirement_provision: props.model.retirement_provision || 'appendix',
    involves_visiting_other_locations: props.model.involves_visiting_other_locations,
    business_entity: props.model.business_entity || '',
})
</script>

<template>
    <ContractForm :form :action="route('contract.change', props.model.id)" @content:closed="$emit('content:closed')" :readonly="['email']" />
</template>
