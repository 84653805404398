<script setup lang="ts">
import WeekdayForm from '@app/components/ui/calendar/WeekdayForm.vue'
import { computed, ref, watch } from 'vue'
import FieldError from '@app/components/ui/form/FieldError.vue'
import { MinusCircleIcon } from '@heroicons/vue/24/outline'
import { PlusCircleIcon } from '@heroicons/vue/24/outline'
import { format } from 'date-fns'
import { useLocale } from '@app/composables/useLocale'
import { ucfirst } from '@app/utils/transform'

const props = defineProps({
    availability: {
        type: Object,
        required: true,
    },
    errors: {
        type: Object,
    },
    durationInMinutes: {
        type: Number,
        default: 60,
    },
})

const slots = ref(props.availability.slots)
const error = computed(() => {
    if (props.errors?.recruitment_availabilities) {
        return props.errors.recruitment_availabilities[props.availability.day]
    }
})

const emit = defineEmits(['change'])
const { getLocale } = useLocale()

function addSlot() {
    slots.value.push(null)
}

function removeSlot(index: number) {
    slots.value.splice(index, 1)
}

watch(slots.value, () => {
    emit('change', props.availability.day, slots.value)
})

watch(
    () => props.availability,
    () => {
        slots.value = props.availability.slots
    }
)
</script>

<template>
    <div>
        <div class="text-sm leading-4 text-zinc-500">
            {{ ucfirst(format(availability.day, 'EEEE', { locale: getLocale() })) }}
            {{ format(availability.day, 'dd-MM-yyyy', { locale: getLocale() }) }}
        </div>
        <div v-for="(_, index) in slots">
            <WeekdayForm class="mt-2" v-model="slots[index]" :date="availability.day" :duration-in-minutes="durationInMinutes">
                <template #actions>
                    <button class="flex items-center" @click="removeSlot(index)">
                        <MinusCircleIcon class="h-6 w-6 text-gray-500" />
                    </button>
                    <button class="flex items-center" @click="addSlot" v-if="index + 1 === slots.length">
                        <PlusCircleIcon class="h-6 w-6 text-gray-500" />
                    </button>
                </template>
            </WeekdayForm>
        </div>

        <FieldError :message="error" class="mt-2" />
    </div>
</template>
