<script setup>
import { useLocale } from '@app/composables/useLocale'
import { computed } from 'vue'

const { dbTranslate } = useLocale()

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    name: {
        type: String,
        required: true,
    },
    relation: String,
    translate: {
        type: Boolean,
        default: false,
    },
    color: String,
    on_null: {
        type: String,
        default: null,
    },
})

const getColor = () => {
    let color = props.color ? props.color : 'gray-900'
    return 'text-' + color
}

const value = computed(() => {
    if (props.relation) {
        return props.item[props.relation] ? props.item[props.relation][props.name] : props.on_null
    }
    return props.item[props.name] != null ? props.item[props.name] : props.on_null
})
</script>

<template>
    <div :class="getColor()">{{ translate ? dbTranslate(value) : value }}</div>
</template>
