import { isSameDay, parse } from 'date-fns'

function isStaticHoliday(date: string) {
    return /^\d{2}\-\d{2}$/.test(date)
}

function parseExcludedDate(date: string, targetDate: Date) {
    if (isStaticHoliday(date)) {
        return parse(date, 'MM-dd', targetDate)
    }

    return parse(date, 'yyyy-MM-dd', new Date())
}

/**
 * Determine whether excluded dates from the OpeningHours package include the provided date.
 */
export function isExcluded(targetDate: Date, excludedDates: string[]) {
    return excludedDates.some((item) => {
        const parsedDate = parseExcludedDate(item, targetDate)

        return isSameDay(parsedDate, targetDate)
    })
}
