<script setup>
import { TabGroup, TabList, Tab } from '@headlessui/vue'
import ConversationItem from './ConversationItem.vue'
import { ref } from 'vue'
import InputFilter from '../ui/table/filters/InputFilter.vue'
import InfiniteScrollList from '../ui/list/InfiniteScrollList.vue'
import { route } from 'ziggy-js'
import { applyFilter } from '@app/utils/filter'

const props = defineProps({
    leadId: Number,
    items: Object,
})

const tabs = [
    { name: 'whatsapp.tab_active', onClick: handleActiveTabClick },
    { name: 'whatsapp.tab_archived', onClick: handleArchivedTabClick },
]

const TAB_ACTIVE = 0
const TAB_ARCHIVED = 1

const emit = defineEmits(['select'])

const selectedTabIndex = ref(!!route().params.archived ? TAB_ARCHIVED : TAB_ACTIVE)

const filterOptions = {
    preserveScroll: false,
    preserveState: false,
    only: ['whatsappMessages'],
}

function handleActiveTabClick() {
    applyFilter({ archived: null }, filterOptions)

    selectedTabIndex.value = TAB_ACTIVE
}

function handleArchivedTabClick() {
    applyFilter({ archived: 1 }, filterOptions)

    selectedTabIndex.value = TAB_ARCHIVED
}

function isTabSelected(tabIndex) {
    return tabIndex === selectedTabIndex.value
}
</script>

<template>
    <div class="order-first block w-1/3 max-w-sm flex-shrink-0">
        <div class="relative flex h-full flex-col border-r border-gray-200 bg-gray-100">
            <TabGroup
                class="overflow-x-auto whitespace-nowrap border-b border-gray-200 bg-white px-4 pt-4 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400"
            >
                <TabList>
                    <Tab
                        v-for="(tab, index) in tabs"
                        class="rounded-t-md bg-gray-100 px-4 py-2 text-sm font-semibold text-gray-400"
                        :class="{
                            'bg-gray-200': isTabSelected(index),
                            'text-gray-700': isTabSelected(index),
                        }"
                        :disabled="isTabSelected(index)"
                        @click="tab.onClick"
                    >
                        {{ $t(tab.name) }}
                    </Tab>
                </TabList>
            </TabGroup>

            <div class="flex h-16 flex-col justify-center bg-white p-6">
                <InputFilter :item="route().params.name" label="whatsapp.search" only="whatsappMessages"></InputFilter>
            </div>

            <InfiniteScrollList
                :initialData="items"
                v-slot="{ items }"
                only="whatsappMessages"
                routeName="api.v1.whatsapp.index"
                class="min-h-0 flex-1 divide-y divide-gray-200 overflow-y-auto border-b border-t border-gray-200 scrollbar-thin scrollbar-track-gray-200 scrollbar-thumb-gray-700"
            >
                <ConversationItem
                    v-for="conversation in items"
                    :key="conversation.id"
                    :conversation="conversation"
                    :selected="conversation.lead_id === props.leadId"
                    @select="emit('select', conversation)"
                />
            </InfiniteScrollList>
        </div>
    </div>
</template>
