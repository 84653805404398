<script setup>
import { computed, onMounted } from 'vue'
import { router } from '@inertiajs/vue3'
import { route } from 'ziggy-js'
import DataTable from '@app/components/ui/table/DataTable.vue'
import { useNavigation } from '@app/composables/layout/useNavigation'
import ToggleFavorite from '@app/components/calls/ToggleFavorite.vue'
import { useUserCan } from '@app/composables/useUserCan'
import Filters from '@app/components/recruitment/calls/Filters.vue'
import Audio from '@app/components/ui/audio/Audio.vue'
import { useChannel } from '@app/composables/useEcho'
import { reloadPartial } from '@app/utils/inertia'
import Link from '@app/components/ui/table/column/Link.vue'

defineProps({
    users: Array,
    calls: Object,
    statuses: Array,
    callStatuses: Array,
    callAttemptOptions: Array,
    directions: Array,
})

const { getRedirectToLeadHref } = useNavigation()
const { can } = useUserCan()

const { subscribe } = useChannel('recruitment')

onMounted(() => {
    subscribe('.call.record.stored', () => reloadPartial(['calls']))
    subscribe('.call.completed', (payload) => {
        if (payload.direction === 'incoming') {
            reloadPartial(['calls'])
        }
    })
})

const params = route().params

const columns = computed(() => [
    {
        type: 'tag',
        header: 'recruitment.leads.index.status',
        properties: { name: 'lead_status' },
        colorPicker: 'getFunnelBlockStatusColor',
        colorProperty: 'lead_status_slug',
    },
    { name: 'status', header: 'recruitment.calls.index.status' },
    {
        type: 'multi-line-date',
        header: 'recruitment.calls.index.created_at',
        properties: { name: 'created_at' },
        sort: 'created_at',
    },
    { name: 'direction', header: 'recruitment.calls.index.direction' },
    { name: 'lead', header: 'lead' },
    {
        type: 'string',
        header: 'user',
        properties: { name: 'full_name', relation: 'user' },
    },
    { name: 'audio', header: 'record' },
    { name: 'star', header: 'call.starred' },
])

function handleToggleFavorite(item) {
    router.put(
        route('call.star', { id: item.id }),
        {
            is_starred: item.is_starred,
        },
        {
            preserveScroll: true,
        }
    )
}
</script>

<template>
    <div class="flex items-start gap-4 scrollbar-hide">
        <DataTable :items="calls" :columns="columns" only="calls">
            <template #filter>
                <Filters
                    class="transition-all duration-500"
                    :callStatuses="callStatuses"
                    :directions="directions"
                    :statuses="statuses"
                    :params="params"
                    :users="users"
                    :callAttemptOptions="callAttemptOptions"
                />
            </template>

            <template v-slot:column.status="{ item }">
                {{ $t(`call.status.${item.status}`) }}
            </template>

            <template v-slot:column.direction="{ item }">
                {{ $t(`call.direction.${item.direction}`) }}
            </template>

            <template v-slot:column.lead="{ item }">
                <Link v-if="item.lead" :link="{ label: item.lead.full_name, href: getRedirectToLeadHref(item.lead) }" />
            </template>

            <template v-slot:column.audio="{ item }">
                <div class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <Audio
                        v-if="item.record"
                        :key="item.record.twilio_id"
                        :src="`/calls/records/${item.record.twilio_id}.mp3`"
                        :estimated-duration="item.record.duration_sec"
                    />
                </div>
            </template>

            <template v-slot:column.star="{ item }">
                <div class="flex justify-center text-center" v-if="item.record">
                    <ToggleFavorite :editable="can('call.star')" v-model="item.is_starred" @update:modelValue="() => handleToggleFavorite(item)" />
                </div>
            </template>
        </DataTable>
    </div>
</template>
