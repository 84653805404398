<script setup lang="ts">
import { useForm } from '@inertiajs/vue3'
import Form from '@app/components/ui/form/Form.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import Input from '@app/components/ui/form/Input.vue'
import Button from '@app/components/ui/button/Button.vue'
import TranslatableInput from '@app/components/ui/form/TranslatableInput.vue'
import type { Channel, ChannelType } from '@app/types/recruitment'
import { route } from 'ziggy-js'
import { router } from '@inertiajs/vue3'
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'
import { useModelSelect } from '@app/composables/useModelSelect'
import { translatedSelectPlaceholder } from '@app/utils/form'
import FormActions from '@app/components/ui/form/FormActions.vue'
import { useLocale } from '@app/composables/useLocale'

const props = defineProps<{
    channel?: Channel
    channel_types: ChannelType[]
}>()

const { initializeEmptyTranslatablePropertyModel } = useLocale()

const form = useForm({
    name: props.channel?.name || initializeEmptyTranslatablePropertyModel(),
    call_attempts: props.channel?.call_attempts,
    channel_type_id: props.channel?.channel_type_id,
})

const selectedChannelType = useModelSelect<ChannelType>(props.channel_types, props.channel?.channel_type_id)

function submit() {
    if (props.channel) {
        form.put(route('settings.recruitment.channel.update', props.channel.id))
    } else {
        form.post(route('settings.recruitment.channel.store'))
    }
}
</script>

<template>
    <Form :form="form" @submit="submit" class="max-w-xl">
        <FormSection>
            <FormField prop="name" class="col-span-12">
                <TranslatableInput prop="name" :label="$t('attributes.name')" v-model="form.name" />
            </FormField>

            <FormField prop="call_attempts" class="col-span-12 sm:col-span-6" :label="$t('attributes.call_attempts')">
                <Input id="call_attempts" v-model.number="form.call_attempts" type="number" />
            </FormField>

            <FormField prop="channel_type_id" :label="$t('attributes.type')" class="col-span-12 sm:col-span-6">
                <Dropdown
                    v-model="selectedChannelType"
                    v-model:form="form.channel_type_id"
                    :items="channel_types"
                    propertyName="name"
                    :label="translatedSelectPlaceholder('attributes.channel_type')"
                    :showLabel="false"
                    :translateDb="true"
                />
            </FormField>
        </FormSection>

        <FormActions>
            <Button color="black" type="button" @click="router.get(route('settings.recruitment.channel.index'))">{{ $t('buttons.back') }}</Button>
            <Button :disabled="form.processing">{{ $t('buttons.save') }}</Button>
        </FormActions>
    </Form>
</template>
