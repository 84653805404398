<script setup lang="ts">
import LeadRecruitmentAppointmentForm from '@app/components/recruitment/leads/forms/LeadRecruitmentAppointmentForm.vue'
import type { Lead } from '@app/types/recruitment'

const props = defineProps<{
    lead: Lead
}>()

// make funnel block non-reactive to not rerender the form whenever a funnel block changes
const funnelBlock = props.lead.next_funnel_block

const emit = defineEmits(['content:closed'])
</script>

<template>
    <LeadRecruitmentAppointmentForm
        class="mt-2 max-w-sm sm:px-4"
        action="recruitment.appointment.recruitment.store"
        :lead="lead"
        :funnel="lead.funnel"
        :funnel-block="funnelBlock"
        @content:closed="$emit('content:closed')"
    />
</template>
