<script setup>
import { useLocale } from '@app/composables/useLocale'

const { dbTranslate } = useLocale()

defineProps({
    item: {
        type: Object,
        required: true,
    },
    primaryName: {
        type: String,
        required: true,
    },
    secondaryName: {
        type: String,
        required: true,
    },
    primaryTranslate: {
        type: Boolean,
        default: false,
    },
    secondaryTranslate: {
        type: Boolean,
        default: false,
    },
})
</script>

<template>
    <div>
        <div class="text-gray-900">
            {{ primaryTranslate ? dbTranslate(item[primaryName]) : item[primaryName] }}
        </div>
        <div class="text-gray-500">
            {{ secondaryTranslate ? dbTranslate(item[secondaryName]) : item[secondaryName] }}
        </div>
    </div>
</template>
