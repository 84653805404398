<script setup lang="ts">
import { useMoney } from '@app/composables/useMoney'

const { formatEuros } = useMoney()

defineProps({
    amount: {
        type: Number,
        required: true,
    },
})
</script>

<template>
    <button class="w-full rounded-lg border border-slate-200 bg-white py-2 text-lg font-semibold text-black hover:bg-teal-500 hover:text-white">
        {{ formatEuros(amount) }}
    </button>
</template>
