<script setup lang="ts">
import OrderCard from '@app/components/orders/orders/OrderCard.vue'
import DataTable from '@app/components/ui/table/DataTable.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'
import DropdownDateFilter from '@app/components/ui/table/filters/DropdownDateFilter.vue'
import DropdownMultiFilter from '@app/components/ui/table/filters/DropdownMultiFilter.vue'
import InputFilter from '@app/components/ui/table/filters/InputFilter.vue'
import ResetFilterButton from '@app/components/ui/table/filters/ResetFilterButton.vue'
import Tag from '@app/components/ui/tag/Tag.vue'
import { useColor } from '@app/composables/useColor'
import { useDetailCard } from '@app/composables/useDetailCard'
import { useLocale } from '@app/composables/useLocale'
import type { Order, OrderStatus } from '@app/types/orders'
import type { Branch, ClientProject, ResourceCollection } from '@app/types/shared'
import { reloadPartial } from '@app/utils/inertia'
import { trans } from 'laravel-vue-i18n'
import { computed, toRefs, type PropType } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps({
    orders: {
        type: Object as PropType<ResourceCollection<Order>>,
        required: true,
    },
    branches: {
        type: Array as PropType<Branch[]>,
        required: true,
    },
    clientProjects: {
        type: Array as PropType<ClientProject[]>,
        required: true,
    },
    statuses: {
        type: Object as PropType<ResourceCollection<OrderStatus>>,
        required: true,
    },
    selectedOrder: Object as PropType<Order>,
})

const { dbTranslate } = useLocale()
const { getOrderStatusColor } = useColor()
const { selectedOrder } = toRefs(props)
const { toggleCard } = useDetailCard(selectedOrder, 'selectedOrder')

const params = route().params

const columns = computed(() => [
    {
        type: 'string',
        header: 'attributes.customer',
        properties: { relation: 'customer', name: 'full_name' },
        on_null: trans('attributes.unknown'),
    },
    {
        type: 'string',
        header: 'attributes.client_project',
        properties: { name: 'client_project' },
    },
    {
        type: 'string',
        header: 'attributes.branch_id',
        properties: { name: 'branch' },
    },
    {
        name: 'status',
        header: 'attributes.status',
    },
    {
        type: 'string',
        header: 'attributes.created_by',
        properties: { name: 'creator' },
    },
    {
        type: 'multi-line-date',
        header: 'attributes.ordered_at',
        properties: { name: 'ordered_at' },
        sort: 'ordered_at',
    },
])
</script>

<template>
    <div class="scrollbar-hiden flex items-start gap-4">
        <DataTable
            class="flex w-full flex-col rounded-lg border border-gray-100 transition-all duration-500"
            :class="[!selectedOrder ? 'inline-block' : 'hidden lg:inline-block']"
            :items="orders"
            :columns="columns"
            @row:clicked="(order: Order) => toggleCard(order.id)"
            :activeId="selectedOrder?.id"
            only="orders"
            :clickable="true"
        >
            <template #filter>
                <FilterSection>
                    <DropdownMultiFilter :label="$t('attributes.client_project')" :items="clientProjects" slug="clientProject" />
                    <DropdownMultiFilter :label="$t('attributes.branch_id')" :items="branches" slug="branch" />
                    <DropdownMultiFilter :label="$t('attributes.status')" :items="statuses" slug="status" />
                    <DropdownDateFilter slug="ordered_at" :label="$t('attributes.date')" />
                    <InputFilter :item="params.external_id" propertyName="external_id" only="orders" />
                    <ResetFilterButton :href="route('orders.order.index')" />
                </FilterSection>
            </template>
            <template v-slot:column.status="{ item }">
                <Tag :color="getOrderStatusColor(item.status.slug)">{{ dbTranslate(item.status.name) }}</Tag>
            </template>
        </DataTable>
        <Transition name="slide-fade" mode="out-in">
            <template v-if="selectedOrder">
                <OrderCard
                    :order="selectedOrder"
                    class="md:min-w-[29rem] lg:w-2/3"
                    @close="toggleCard(selectedOrder.id)"
                    @reload="reloadPartial(['selectedOrder', 'orders'])"
                />
            </template>
        </Transition>
    </div>
</template>
