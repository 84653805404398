<script setup lang="ts">
import { Link } from '@inertiajs/vue3'
import type { ResourceCollection } from '@app/types/shared'
import type { AnonymizeSetting } from '@app/types/anonymize'
import { useUserCan } from '@app/composables/useUserCan'
import { route } from 'ziggy-js'
import Settings from '@app/layouts/Settings.vue'
import DataTable from '@app/components/ui/table/DataTable.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'

defineProps<{
    anonymizeSettings: ResourceCollection<AnonymizeSetting>
}>()

const { can } = useUserCan()

const columns = [
    {
        type: 'string',
        header: 'ID',
        properties: { name: 'id' },
        sort: 'id',
    },
    {
        type: 'string',
        header: 'attributes.subject_type',
        properties: { name: 'subject_type' },
        sort: 'subject_type',
    },
    {
        type: 'string',
        header: 'attributes.anonymize_after_amount_of_days',
        properties: { name: 'anonymize_after_amount_of_days' },
        sort: 'anonymize_after_amount_of_days',
    },
    {
        name: 'actions',
        header: 'actions.header',
        show: can('anonymize-settings.store'),
    },
]
</script>

<template>
    <Settings group="general">
        <DataTable class="flex w-full flex-col rounded-lg border border-gray-100 transition-all duration-500" :items="anonymizeSettings" :columns="columns">
            <template #filter>
                <FilterSection class="mb-2" />
            </template>
            <template v-slot:column.actions="{ item }">
                <Link v-if="can('anonymize-settings.store')" class="mr-2 text-primary-600" :href="route('settings.anonymize.edit', { id: item.id })">
                    {{ $t('actions.update') }}
                </Link>
            </template>
        </DataTable>
    </Settings>
</template>
