<script setup>
import { computed } from 'vue'
import DropdownMultiFilter from '@app/components/ui/table/filters/DropdownMultiFilter.vue'
import DropdownFilter from '@app/components/ui/table/filters/DropdownFilter.vue'
import DropdownDateFilter from '@app/components/ui/table/filters/DropdownDateFilter.vue'
import DropdownPerPageFilter from '@app/components/ui/table/filters/DropdownPerPageFilter.vue'
import LeadStatusFilter from '../leads/LeadStatusFilter.vue'
import InputFilter from '@app/components/ui/table/filters/InputFilter.vue'
import ResetFilterButton from '@app/components/ui/table/filters/ResetFilterButton.vue'
import ShowFiltersButton from '@app/components/ui/button/ShowFiltersButton.vue'
import { useFilter } from '@app/composables/useFilter'
import { trans } from 'laravel-vue-i18n'
import { route } from 'ziggy-js'
import { callFilters } from '@app/utils/recruitment/filters'

const { showFilters, toggleFilters } = useFilter()

const props = defineProps({
    statuses: Array,
    callAttemptOptions: Array,
    callStatuses: Array,
    users: Array,
    leadStatuses: Array,
    directions: Array,
    params: Object,
})

const directionFilters = computed(() => {
    return props.directions.map((direction) => ({
        name: `call.direction.${direction}`,
        slug: direction,
    }))
})

const statusFilters = computed(() => {
    return props.callStatuses.map((status) => ({
        name: trans(`call.status.${status}`),
        slug: status,
    }))
})

const starredFilters = [
    {
        name: 'call.starred',
        slug: 1,
    },
]
</script>

<template>
    <div class="flex flex-wrap justify-between px-4">
        <ShowFiltersButton :showFilters="showFilters" @click="toggleFilters" class="md:hidden" />
        <div class="flex grow flex-wrap items-end space-x-4 pb-2 pr-4 scrollbar-hide md:flex" :class="{ hidden: !showFilters, block: showFilters }">
            <div class="flex flex-wrap items-end gap-4">
                <LeadStatusFilter :statuses="statuses" :callAttemptOptions="callAttemptOptions" only="calls" />
                <DropdownMultiFilter :items="statusFilters" :label="$t('recruitment.calls.index.status')" only="calls" slug="call_status" by="slug" />
                <DropdownFilter
                    :items="directionFilters"
                    :label="$t('recruitment.calls.index.direction')"
                    :translateDb="false"
                    only="calls"
                    propertyName="name"
                    by="slug"
                    slug="direction"
                />
                <DropdownMultiFilter :items="users" propertyName="full_name" :label="$t('user')" only="calls" by="id" slug="user_id" />
                <DropdownDateFilter :label="$t('recruitment.calls.index.created_at')" only="calls" :item="params.created_at" slug="created_at" />
                <DropdownFilter
                    :items="starredFilters"
                    :label="$t('call.starred')"
                    :translateDb="false"
                    only="calls"
                    propertyName="name"
                    by="slug"
                    slug="is_starred"
                />
                <ResetFilterButton :href="route('recruitment.call.index', callFilters)" />
            </div>
        </div>
        <div class="flex grow flex-wrap items-end justify-end gap-4 py-2 md:flex-nowrap">
            <InputFilter :item="params.name" only="calls" class="w-full md:w-auto md:max-w-4xl" />
            <DropdownPerPageFilter only="calls" class="shrink-0" />
        </div>
    </div>
</template>
