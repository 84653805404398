import { router } from '@inertiajs/vue3'
import { computed, ref, watch } from 'vue'
import { route } from 'ziggy-js'
import { useNavigation } from '../layout/useNavigation'

export function useTwilioCalls(user) {
    const DISMISS_DELAY_MS = 30000 // 30 seconds

    const CALL_COMPLETED = 'completed'
    const CALL_FAILED = 'failed'
    const CALL_IN_PROGRESS = 'in-progress'

    const { getRedirectToLeadHref } = useNavigation()
    const callDirection = ref()
    const recipient = ref(null)
    const callStatus = ref(null)
    const callInProgress = computed(() => ['initializing', 'ringing', 'in-progress'].includes(callStatus.value))
    const callCompletedOrFailed = ref()
    const showCallOverlay = ref(false)
    const muted = ref(false)

    let call
    let device
    let overlayTimeout

    watch(muted, () => {
        call?.mute(muted.value)
    })

    const connectTwilioDevice = async () => {
        if (device) {
            return
        }

        console.log('Logging in to the Fonky call center ...')
        const data = await axios.post(route('twilio.access-token'))

        const { Device } = await import('@twilio/voice-sdk')

        device = new Device(data.data.token, {
            tokenRefreshMs: 3000,
            codecPreferences: ['opus', 'pcmu'],
        })

        console.log('Logged in as: ' + data.data.identity + '.')
        setupHandlers()
        device.register()
    }

    const setupHandlers = () => {
        device.on('error', (error) => handleError(error))
        device.on('incoming', (call) => handleIncomingCall(call))
        device.on('tokenWillExpire', () => handleTokenWillExpire())
    }

    const handleTokenWillExpire = async () => {
        if (device) {
            const data = await axios.post(route('twilio.access-token'))
            await device.updateToken(data.data.token)
        }
    }

    const handleError = (error) => {
        console.log('Twilio error: ' + error.message)
        callCompletedOrFailed.value = 'failed'
    }

    /**
     * @param {{ id?: number, full_name?: string, phone_number: string, href?: string }} outgoingCallRecipient
     */
    async function startOutgoingCall(outgoingCallRecipient) {
        console.log(`Making outgoing call to ${outgoingCallRecipient.phone_number}...`)

        callDirection.value = 'outgoing'
        callCompletedOrFailed.value = null
        recipient.value = outgoingCallRecipient
        recipient.value.href = outgoingCallRecipient.id ? getRedirectToLeadHref(outgoingCallRecipient) : null
        callStatus.value = 'initializing'

        await connectTwilioDevice()
        await updateCallAvailabilityToInCall()

        const params = { To: outgoingCallRecipient.phone_number, userId: user.id }

        if (outgoingCallRecipient.id) {
            params.leadId = outgoingCallRecipient.id
        }

        call = await device.connect({ params })

        call.on('ringing', () => (callStatus.value = 'ringing'))
        call.on('accept', () => (callStatus.value = 'in-progress'))
        call.on('disconnect', (call) => disconnectCall(call))

        showOverlay()
    }

    const setCompletedOrFailed = (oldCallStatus) => {
        callCompletedOrFailed.value = oldCallStatus === CALL_IN_PROGRESS ? CALL_COMPLETED : CALL_FAILED
    }

    const resetRefs = () => {
        muted.value = false
    }

    const updateCallAvailabilityToInCall = async () => {
        router.post(
            route('call.availability.update'),
            {
                status: 'in-call',
            },
            { preserveScroll: true, only: ['user'] }
        )
    }

    const updateCallAvailabilityToAfterwork = async () => {
        router.post(
            route('call.availability.update'),
            {
                status: 'administration',
            },
            {
                preserveScroll: true,
                only: ['user'],
                async onSuccess() {
                    await disconnectTwilioDevice()
                },
            }
        )
    }

    const disconnectTwilioDevice = async () => {
        if (device) {
            console.log('Logging out ... ')
            await device.destroy()
            console.log('Successfully logged out.')
            device = null
        }
    }

    const endCall = () => {
        console.log('Hanging up ...')
        device.disconnectAll()
        console.log('Hung up.')
    }

    const handleIncomingCall = async (incomingCall) => {
        recipient.value = {
            phone_number: incomingCall.customParameters.get('from'),
        }

        callCompletedOrFailed.value = null
        callStatus.value = 'ringing'
        callDirection.value = 'incoming'

        console.log('Incoming call from ' + incomingCall.customParameters.get('from') + ' ...')
        if (incomingCall.customParameters.get('leadName')) {
            console.log('Incoming call from ' + incomingCall.customParameters.get('leadName') + '.')
        }
        if (incomingCall.customParameters.get('amountOfCalls')) {
            console.log('There have been ' + incomingCall.customParameters.get('amountOfCalls') + ' earlier contacts.')
        }

        if (incomingCall.customParameters.get('leadId')) {
            await loadLead(incomingCall.customParameters.get('leadId'))
        }

        call = incomingCall

        call.on('cancel', () => missedIncoming())
        call.on('disconnect', (call) => disconnectCall(call))

        showOverlay()
    }

    function showOverlay() {
        clearTimeout(overlayTimeout)
        showCallOverlay.value = true
    }

    async function loadLead(leadId) {
        const response = await axios.get(route('recruitment.lead.show', leadId))

        recipient.value = response.data.lead
        recipient.value.href = response.data.lead ? getRedirectToLeadHref(response.data.lead) : null
    }

    const missedIncoming = async () => {
        console.log('Missing incoming call ...')
        callStatus.value = 'missed'
        resetRefs()

        overlayTimeout = setTimeout(() => {
            showCallOverlay.value = false
        }, DISMISS_DELAY_MS)
        console.log('Incoming call missed.')
    }

    async function disconnectCall(call) {
        const oldCallStatus = callStatus.value

        const isAnswered = callStatus.value === 'in-progress'
        callStatus.value = isAnswered ? 'disconnected' : 'missed'

        await updateCallAvailabilityToAfterwork()

        console.log(`${callDirection.value} call disconnected.`)
        setCompletedOrFailed(oldCallStatus)

        resetRefs()

        overlayTimeout = setTimeout(() => {
            showCallOverlay.value = false
        }, DISMISS_DELAY_MS)
    }

    const acceptIncomingCall = async () => {
        console.log('Accepting incoming call ...')
        await call?.accept()
        await updateCallAvailabilityToInCall()
        callStatus.value = CALL_IN_PROGRESS
        console.log('Accepted incoming call.')
    }

    const rejectIncomingCall = async () => {
        console.log('Rejecting incoming call ...')
        showCallOverlay.value = false
        await call?.reject()
        callStatus.value = 'rejected'
        resetRefs()
        console.log('Rejected incoming call.')
    }

    return {
        connectTwilioDevice,
        disconnectTwilioDevice,
        startOutgoingCall,
        endCall,
        acceptIncomingCall,
        rejectIncomingCall,
        callStatus,
        callCompletedOrFailed,
        recipient,
        loadLead,
        showCallOverlay,
        callDirection,
        muted,
        callInProgress,
    }
}
