export const tailwindColors = {
    fonkyBlue: '#24a1da',
    fonkyOrange: '#da5d24',
    zinc400: '#A1A1AA',
    zinc300: '#D4D4D8',
}

export function* createColorPicker(colors: string[]): Generator<string> {
    let index = 0

    while (true) {
        yield colors[index]

        index = (index + 1) % colors.length
    }
}
