<script setup lang="ts">
import { computed, useSlots } from 'vue'

const emit = defineEmits(['update:modelValue'])

defineProps({
    modelValue: {
        type: [Boolean, Number, null],
        default: false,
    },
    value: {
        type: [Boolean, Number],
        default: false,
    },
})

const hasLabel = computed(() => useSlots().default)
</script>

<template>
    <label class="inline-flex items-center">
        <input
            :value="modelValue"
            type="radio"
            class="border-gray-300 text-primary-700 transition-all focus:ring-primary-600"
            @input="emit('update:modelValue', value)"
            :checked="modelValue === value"
        />

        <span v-if="hasLabel" class="ml-2 text-sm font-normal leading-5 text-gray-500">
            <slot></slot>
        </span>
    </label>
</template>
