<script setup lang="ts">
import { useLanguageSwitcher } from '@app/composables/useLanguageSwitcher'
import { useLocale } from '@app/composables/useLocale'
import { useLogout } from '@app/composables/useLogout'
import type { PageProps } from '@app/types/inertia'
import type { User } from '@app/types/shared'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { router, usePage } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps<{ user: User }>()
const { logout } = useLogout(props.user)
const { switchLanguage } = useLanguageSwitcher()
const { dbTranslate } = useLocale()
const page = usePage<PageProps>()
const userLanguage = computed(() => page.props.userLanguage)
</script>

<template>
    <div class="hidden items-center space-x-6 md:flex">
        <Menu as="div" class="relative inline-block flex-shrink-0">
            <div>
                <MenuButton class="flex text-sm focus:outline-none" id="profile-button">
                    <span class="sr-only">Open user menu</span>
                    <a href="#" class="group block w-full flex-shrink-0">
                        <div class="flex items-center">
                            <div class="rounded-full focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                                <img class="inline-block h-9 w-9 rounded-full" :src="user.profile_photo_url" :alt="user.full_name" />
                            </div>
                            <div class="ml-3 max-w-48 text-left">
                                <p class="truncate text-sm font-medium leading-5 text-zinc-700">
                                    {{ user.full_name }}
                                </p>
                                <p v-if="user.job_title" class="text-xs font-normal leading-4 text-zinc-500">
                                    {{ dbTranslate(user.job_title.name) }}
                                </p>
                            </div>
                        </div>
                    </a>
                </MenuButton>
            </div>
            <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
            >
                <MenuItems
                    class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                    <MenuItem class="block px-4 py-2 text-sm leading-5 text-gray-700 transition hover:bg-gray-100 focus:bg-gray-100 focus:outline-none">
                        <button @click="router.get(route('user.profile.show'))" class="flex w-full">
                            {{ $t('navigation.account_management.settings') }}
                        </button>
                    </MenuItem>

                    <MenuItem class="block px-4 py-2 text-sm leading-5 text-gray-700 transition hover:bg-gray-100 focus:bg-gray-100 focus:outline-none">
                        <button class="flex w-full" @click="switchLanguage">
                            {{ $t('navigation.account_management.language') }}:
                            {{ dbTranslate(userLanguage?.name) }}
                        </button>
                    </MenuItem>

                    <MenuItem
                        class="block px-4 py-2 text-sm leading-5 text-gray-700 transition hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
                        id="logout-button"
                    >
                        <button class="flex w-full" @click="logout">
                            {{ $t('navigation.account_management.logout') }}
                        </button>
                    </MenuItem>
                </MenuItems>
            </transition>
        </Menu>
    </div>
</template>
