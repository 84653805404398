<script setup lang="ts">
import { useFilter } from '@app/composables/useFilter'
import ShowFiltersButton from '../button/ShowFiltersButton.vue'
import InputFilter from './filters/InputFilter.vue'
import DropdownPerPageFilter from './filters/DropdownPerPageFilter.vue'

defineProps({
    showPerPageOptions: {
        type: Boolean,
        default: true,
    },
    enableSearch: {
        type: Boolean,
        default: false,
    },
    textSearchParam: String,
})

const { showFilters, toggleFilters } = useFilter()
</script>

<template>
    <div class="flex flex-wrap justify-between px-4">
        <ShowFiltersButton v-if="$slots.default" :showFilters="showFilters" @click="toggleFilters" class="md:hidden" />
        <div class="flex grow flex-wrap items-end space-x-4 pb-2 pr-4 scrollbar-hide md:block" :class="{ hidden: !showFilters, block: showFilters }">
            <div class="flex flex-wrap items-end gap-4">
                <slot></slot>
            </div>
        </div>
        <div class="flex grow flex-wrap items-end justify-end gap-4 py-2 md:flex-nowrap">
            <InputFilter v-if="enableSearch" :item="textSearchParam" only="leads" class="w-full md:w-auto md:max-w-4xl" />
            <DropdownPerPageFilter v-if="showPerPageOptions" class="shrink-0" />
        </div>
    </div>
</template>
