<script setup>
import Button from '@app/components/ui/button/Button.vue'
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import Settings from '@app/layouts/Settings.vue'
import { useForm } from '@inertiajs/vue3'
import { ref } from 'vue'

const props = defineProps({
    marketingChannel: Object,
    channels: Object,
})

const selectedChannel = ref(props.marketingChannel?.channel)

const form = useForm({
    channel_id: selectedChannel.value?.id || null,
})

function update() {
    form.put(route('settings.recruitment.marketing-channel.update', props.marketingChannel.id))
}
</script>

<template>
    <Settings group="recruitment">
        <Form :form="form" @submit="update" class="max-w-xl">
            <FormSection title="Update marketing channel">
                <FormField prop="channel_id" label="Channel" class="col-span-12">
                    <Dropdown :items="channels" label="Select a channel" propertyName="name" v-model="selectedChannel" v-model:form="form.channel_id" />
                </FormField>
            </FormSection>

            <FormActions>
                <Button :disabled="form.processing">{{ $t('buttons.save') }}</Button>
            </FormActions>
        </Form>
    </Settings>
</template>
