<script setup lang="ts">
import { useLocale } from '@app/composables/useLocale'
import type { Order } from '@app/types/orders'
import { getZonedDateTime } from '@app/utils/date'
import { type PropType } from 'vue'

defineProps({
    order: {
        type: Object as PropType<Order>,
        required: true,
    },
})

const { dbTranslate } = useLocale()
</script>

<template>
    <div class="mt-4 flex w-full flex-wrap justify-start gap-4 text-left">
        <div class="mr-6 flex flex-col justify-start space-y-2">
            <div class="flex flex-col flex-wrap">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('attributes.status') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ dbTranslate(order.status.name) }}</span>
            </div>
            <div class="flex flex-col flex-wrap">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('attributes.client_project') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ order.client_project.name }}</span>
            </div>
            <div class="flex flex-col flex-wrap">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('attributes.branch_id') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ order.branch.name }}</span>
            </div>
            <div class="flex flex-col flex-wrap">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('attributes.application') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ order.application.name }}</span>
            </div>
            <div class="flex flex-col flex-wrap">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('attributes.ordered_at') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ getZonedDateTime(order.ordered_at) }}</span>
            </div>
            <div class="flex flex-col flex-wrap">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('attributes.created_by') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ order.creator.full_name }}</span>
            </div>
            <div class="flex flex-col flex-wrap">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('attributes.external_id') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ order.external_id }}</span>
            </div>
        </div>
    </div>
</template>
