import { convertPathToDataURL, dataURLToFile } from '@app/utils/image'
import type { InertiaForm } from '@inertiajs/vue3'
import { uniqueId } from 'lodash'
import { onMounted, ref, watch } from 'vue'

export function useSignatureField(form: InertiaForm<any>, field: string, currentSignaturePath: string | null) {
    const signatureModel = ref<string | null>(null)

    onMounted(async () => {
        if (currentSignaturePath) {
            signatureModel.value = await convertPathToDataURL(currentSignaturePath)
        }
    })

    watch(signatureModel, async () => {
        if (signatureModel.value) {
            const file = await dataURLToFile(signatureModel.value, uniqueId() + '.png')

            form[field] = file
        } else {
            form[field] = null
        }
    })

    return {
        signatureModel,
    }
}
