import type { RecruitmentAvailability } from '@app/types/recruitment'
import { getAppointmentDateFromDateTime, getTimeFromDateTime } from '@app/utils/date'
import { differenceInMinutes, endOfWeek, format, isPast, isWeekend, startOfWeek } from 'date-fns'

export function getAppointmentColors(availability: RecruitmentAvailability) {
    const to = new Date(availability.to)
    const isCompleted = isPast(to)

    if (availability.funnel_block_slug === 'introduction') {
        return availability.slots_taken > 0 && !isCompleted ? 'primary' : 'primaryLight'
    }

    if (availability.funnel_block_slug === 'workshop') {
        return availability.slots_taken > 0 && !isCompleted ? 'secondary' : 'secondaryLight'
    }
}

// Safelisted col-start- in tailwind.config.js.
// https://tailwindcss.com/docs/content-configuration#using-regular-expressions
export function getDayClass(date: string) {
    let dayNumber = format(new Date(date), 'i')

    return 'relative flex  col-start-' + dayNumber
}

export function getEventTitle(availability: RecruitmentAvailability) {
    return availability.branch_name + ' - ' + availability.funnel_block_name
}

export function getThisWeeksAvailabilities(availability: RecruitmentAvailability, date: Date) {
    let from = new Date(availability.from)
    let to = new Date(availability.to)

    if (isWeekend(from)) {
        return false
    }

    return from >= startOfWeek(date, { weekStartsOn: 1 }) && to <= endOfWeek(date, { weekStartsOn: 1 })
}

export function getSlotsTakenStyled(availability: RecruitmentAvailability) {
    return availability.slots_taken + '/' + availability.slots
}

function calculateRowNumberFromDate(dateStr: string, startingHours: number) {
    const date = new Date(dateStr)

    const rowsFromHours = ((date.getHours() - startingHours) % 12) * 4
    const rowsFromMinutes = Math.floor(date.getMinutes() / 15)

    return rowsFromHours + rowsFromMinutes
}

export function calculateRowSpanFromDuration(from: string, to: string) {
    const dateFrom = new Date(from)
    const dateTo = new Date(to)

    const durationInMinutes = Math.abs(differenceInMinutes(dateFrom, dateTo))

    return Math.floor(durationInMinutes / 15)
}

export function getTimeStyle(availability: RecruitmentAvailability, startingHours: number) {
    const rowOffset = 2
    const rowNumber = calculateRowNumberFromDate(availability.from, startingHours)
    const rowSpan = calculateRowSpanFromDuration(availability.from, availability.to)

    return `grid-row: ${rowOffset + rowNumber} / span ${rowSpan}`
}

export function getFromToWithSlotsTaken(availability: RecruitmentAvailability) {
    return (
        getAppointmentDateFromDateTime(availability.from, 'short') +
        ' ' +
        getTimeFromDateTime(availability.from) +
        ' - ' +
        getTimeFromDateTime(availability.to) +
        ' (' +
        getSlotsTakenStyled(availability) +
        ')'
    )
}
