<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useUserCan } from '@app/composables/useUserCan'
import type { Shift } from '@app/types/shifts'
import { TrashIcon } from '@heroicons/vue/24/solid'
import { router } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps<{ shift: Shift }>()

const { can } = useUserCan()

const show = computed(() => can('shifts.shift.destroy'))

const deleteShift = () => {
    router.delete(route('shifts.shift.destroy', props.shift.id))
}
</script>

<template>
    <RoundButton v-if="show" :label="$t('actions.delete')" @click="deleteShift">
        <TrashIcon class="size-5" aria-hidden="true" />
    </RoundButton>
</template>
