<script setup lang="ts">
import CancelButton from '@app/components/ui/button/CancelButton.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import type { ShiftAvailability, ShiftMember } from '@app/types/shifts'
import { addOrRemoveValueFromArray } from '@app/utils/array'
import { router, useForm } from '@inertiajs/vue3'
import { type Component, type ShallowRef, inject } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps({
    model: {
        type: Object,
        required: true,
    },
})

const form = useForm({
    availabilities: props.model.shiftMembers ? props.model.shiftMembers.map((shiftMember: ShiftMember) => shiftMember.shift_availability_id) : [],
})

const activeButton = inject('activeButton') as ShallowRef<Component>

const setShiftMembers = () => {
    router.post(route('shifts.shift.set-members', { id: props.model.id, shift_availability_ids: form.availabilities }), {
        only: ['selectedShift'],
    })

    activeButton.value = null
}

const addOrRemoveToSelectedAvailabilities = (availability: ShiftAvailability) => {
    addOrRemoveValueFromArray(form.availabilities, availability.id)
}
</script>

<template>
    <Form :form="form">
        <FormSection title="Set shift members">
            <FormField class="col-span-12 flex gap-2 sm:col-span-6" prop="availabilities">
                <button
                    v-for="availability in model.availabilities"
                    @click="addOrRemoveToSelectedAvailabilities(availability)"
                    :class="[
                        form.availabilities.includes(availability.id) ? 'bg-primary-500 text-white' : 'bg-zinc-100 text-zinc-500',
                        'leading-2 flex h-8 cursor-pointer items-center justify-center rounded-full p-2 text-xs font-medium tracking-wide',
                    ]"
                >
                    {{ availability.userable.full_name }}
                </button>
                <div v-if="model.availabilities.length === 0">No users available for this shift!</div>
            </FormField>
        </FormSection>

        <FormActions>
            <CancelButton @click="activeButton = null" />
            <SaveButton :disabled="form.processing" @click="setShiftMembers" />
        </FormActions>
    </Form>
</template>
