<script setup lang="ts">
import ActivityFeedTemplate from '@app/components/ui/activityFeed/ActivityFeedTemplate.vue'
import ActivityFeedTemplateHeader from '@app/components/ui/activityFeed/ActivityFeedTemplateHeader.vue'
import type { FunnelActivityFeed } from '@app/types/activity-feed'
import type { Funnel } from '@app/types/recruitment'
import { FunnelIcon } from '@heroicons/vue/20/solid'
import { usePage } from '@inertiajs/vue3'
import { computed, inject } from 'vue'

const props = defineProps<{
    activity: FunnelActivityFeed
}>()

const funnels = inject<Funnel[]>('funnels')
const getFunnel = (slug: string) => {
    return funnels.find((funnel: Funnel) => {
        return funnel.slug === slug
    })
}

const oldFunnel = computed(() => getFunnel(props.activity.old_funnel))
const newFunnel = computed(() => getFunnel(props.activity.new_funnel))

const getFunnelName = (funnel: Funnel) => {
    return funnel.name[usePage().props.userLanguage.slug]
}
</script>

<template>
    <ActivityFeedTemplate>
        <template #icon>
            <FunnelIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
        </template>
        <template #header>
            <ActivityFeedTemplateHeader
                :name="activity.causer_full_name"
                :header="$t('activities.recruitment.lead_updated_funnel_header')"
                :created_at="activity.created_at"
            />
        </template>
        <template #content>
            {{ $t('attributes.funnel') }}
            {{ $t('activities.updated_from') }}
            <span class="text-sm font-semibold uppercase leading-4 text-zinc-900">{{ getFunnelName(oldFunnel) }}</span>
            {{ $t('activities.updated_to') }}
            <span class="text-sm font-semibold uppercase leading-4 text-zinc-900">{{ getFunnelName(newFunnel) }}</span>
        </template>
    </ActivityFeedTemplate>
</template>
