/**
 *
 * Add to array if the element does not exist, remove otherwise.
 */
export function addOrRemoveValueFromArray<T>(array: Array<T>, value: T): Array<T> {
    const index = array.indexOf(value)
    if (index > -1) {
        array.splice(index, 1)
    } else {
        array.push(value)
    }

    return array
}

export function isNotEmptyArray<T>(variable: T) {
    return !!(Array.isArray(variable) && variable.length)
}
