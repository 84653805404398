<script setup lang="ts">
import { onMounted } from 'vue'
import { updateFunnelBlockStatus } from '@app/utils/recruitment/lead'
import type { Lead } from '@app/types/recruitment'

const emit = defineEmits(['content:closed'])

const props = defineProps<{
    lead: Lead
    outcome: { slug: string }
}>()

onMounted(async () => {
    updateFunnelBlockStatus(props.lead.id, props.outcome.slug)
    setTimeout(() => emit('content:closed'), 1000)
})
</script>
<template></template>
