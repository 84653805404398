<script setup lang="ts">
import { computed } from 'vue'
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { XCircleIcon } from '@heroicons/vue/24/solid'
import type { Contract } from '@app/types/contracts'
import { router } from '@inertiajs/vue3'
import { route } from 'ziggy-js'
import { useUserCan } from '@app/composables/useUserCan'

const props = defineProps<{ contract: Contract }>()

const { can } = useUserCan()

const show = computed(() => can('contract.destroy') && props.contract.status.slug === 'new')

function deleteContract() {
    router.delete(route('contract.destroy', props.contract.id))
}
</script>

<template>
    <RoundButton v-if="show" :label="$t('actions.delete')" @click="deleteContract">
        <XCircleIcon class="size-5" aria-hidden="true" />
    </RoundButton>
</template>
