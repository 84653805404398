<script setup lang="ts">
import ContentTabs from '@app/components/layout/ContentTabs.vue'
import AddNote from '@app/components/notes/AddNote.vue'
import LeadCard from '@app/components/recruitment/leads/LeadCard.vue'
import CallLead from '@app/components/recruitment/leads/actions/CallLead.vue'
import EditLead from '@app/components/recruitment/leads/actions/EditLead.vue'
import ManageRecruitmentAppointment from '@app/components/recruitment/leads/actions/ManageRecruitmentAppointment.vue'
import RestoreLead from '@app/components/recruitment/leads/actions/RestoreLead.vue'
import SelectLeadOutcome from '@app/components/recruitment/leads/actions/SelectLeadOutcome.vue'
import SendEmail from '@app/components/recruitment/leads/actions/SendEmail.vue'
import SendWhatsappMessage from '@app/components/recruitment/leads/actions/SendWhatsappMessage.vue'
import DataTable from '@app/components/ui/table/DataTable.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'
import FromToDate from '@app/components/ui/table/column/FromToDate.vue'
import DropdownFutureDateFilter from '@app/components/ui/table/filters/DropdownFutureDateFilter.vue'
import DropdownMultiFilter from '@app/components/ui/table/filters/DropdownMultiFilter.vue'
import ResetFilterButton from '@app/components/ui/table/filters/ResetFilterButton.vue'
import { useNavigation } from '@app/composables/layout/useNavigation'
import { useLeadAppointmentTabs } from '@app/composables/recruitment/useLeadAppointmentTabs'
import { useDetailCard } from '@app/composables/useDetailCard'
import { useLocale } from '@app/composables/useLocale'
import type { Funnel, FunnelBlock, Lead } from '@app/types/recruitment'
import type { Branch, ResourceCollection, User } from '@app/types/shared'
import { reloadPartial } from '@app/utils/inertia'
import { recruitmentAppointmentFilters } from '@app/utils/recruitment/filters'
import { computed, toRefs, type PropType } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps({
    user: {
        type: Object as PropType<User>,
        required: true,
    },
    appointments: {
        type: Object as PropType<ResourceCollection<any>>,
        required: true,
    },
    users: Array as PropType<User[]>,
    selectedLead: Object as PropType<Lead>,
    funnels: {
        type: Array as PropType<Funnel[]>,
        required: true,
    },
    appointmentFunnelBlocks: {
        type: Array as PropType<FunnelBlock[]>,
        required: true,
    },
    branches: {
        type: Array as PropType<Branch[]>,
        required: true,
    },
})

const { selectedLead } = toRefs(props)

const { toggleCard } = useDetailCard(selectedLead, 'selectedLead')
const { dbTranslate } = useLocale()
const { currentRoute } = useNavigation()
const params = route().params

const tabs = useLeadAppointmentTabs(currentRoute)

const columns = computed(() => [
    {
        name: 'name',
        header: 'lead',
    },
    {
        name: 'funnel_block',
        header: 'attributes.funnel_block',
    },
    {
        name: 'date',
        header: 'recruitment.call_appointment.date',
    },
    {
        name: 'user',
        header: 'attributes.user',
    },
    {
        name: 'funnel',
        header: 'attributes.funnel',
    },
])
</script>

<template>
    <ContentTabs bottomBorder :items="tabs" />
    <div class="flex items-start gap-4 scrollbar-hide">
        <DataTable
            class="flex w-full flex-col rounded-lg border border-gray-100 transition-all duration-500"
            :class="[!selectedLead ? 'inline-block' : 'hidden lg:inline-block']"
            :items="appointments"
            :columns="columns"
            :activeId="selectedLead?.ongoing_recruitment_appointment?.id"
            :clickable="true"
            @row:clicked="(appointment) => toggleCard(appointment.lead_id)"
            only="appointments"
        >
            <template #filter>
                <FilterSection :enable-search="true" :text-search-param="params.name">
                    <DropdownFutureDateFilter slug="date" :label="$t('recruitment.call_appointment.date')" />

                    <DropdownMultiFilter :label="$t('attributes.branch_id')" :items="branches" by="slug" slug="branch" />

                    <DropdownMultiFilter :label="$t('attributes.funnel')" :items="funnels" by="slug" slug="funnel" />

                    <DropdownMultiFilter :label="$t('attributes.funnel_block')" :items="appointmentFunnelBlocks" by="slug" slug="funnel_block" />

                    <DropdownMultiFilter :label="$t('attributes.user')" propertyName="full_name" :items="users" by="id" slug="user" />

                    <ResetFilterButton :href="route('recruitment.appointment.recruitment.index', recruitmentAppointmentFilters)" />
                </FilterSection>
            </template>

            <template v-slot:column.name="{ item }">
                <div class="text-gray-900">
                    {{ item.lead.full_name }}
                </div>
                <div class="text-gray-500">
                    {{ item.lead.branch.name }}
                </div>
            </template>

            <template v-slot:column.funnel="{ item }">
                <div class="text-gray-900">
                    {{ dbTranslate(item.lead.funnel.name) }}
                </div>
            </template>

            <template v-slot:column.funnel_block="{ item }">
                <div class="text-gray-900">
                    {{ dbTranslate(item.lead.funnel_block.name) }}
                </div>
            </template>

            <template v-slot:column.date="{ item }">
                <FromToDate :from="item.recruitment_availability.from" :to="item.recruitment_availability.to" class="gap-2" />
            </template>

            <template v-slot:column.user="{ item }">
                <div class="text-gray-900">
                    {{ item.recruitment_availability.user.full_name }}
                </div>
            </template>
        </DataTable>

        <Transition name="slide-fade" mode="out-in">
            <LeadCard
                v-if="selectedLead"
                :lead="selectedLead"
                class="md:min-w-[29rem] lg:w-2/3"
                :dismissable="true"
                @close="toggleCard(selectedLead.id)"
                @reload="reloadPartial(['selectedLead', 'appointments'])"
            >
                <CallLead :lead="selectedLead" />
                <SendWhatsappMessage :lead="selectedLead" />
                <SendEmail :lead="selectedLead" />
                <AddNote :model="selectedLead" notable-type="recruitment_lead" />
                <EditLead :lead="selectedLead" />
                <RestoreLead :lead="selectedLead" />
                <ManageRecruitmentAppointment :lead="selectedLead" />
                <SelectLeadOutcome :lead="selectedLead" />
            </LeadCard>
        </Transition>
    </div>
</template>
