<script setup lang="ts">
import { useLocale } from '@app/composables/useLocale'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import { Link } from '@inertiajs/vue3'
import { computed, inject, type Component } from 'vue'

type NavItem = {
    id: string
    name: string
    icon?: Component
    href: string
    active: boolean
    show?: boolean
    items?: NavItem[]
    external_link: {
        type: boolean
        default: false
    }
}

const props = defineProps<{
    item: NavItem
    showName: boolean
}>()

const { translate } = useLocale()
const mobileMenuOpen = inject('mobileMenuOpen')
const sideBarOpen = inject('sideBarOpen')

function isVisible(item: NavItem) {
    return typeof item.show === 'undefined' || item.show
}

const visibleChildren = computed(() => props.item.items?.filter(isVisible))

const showMenuItem = computed(() => {
    if (visibleChildren.value) {
        return visibleChildren.value.length > 0
    }

    return isVisible(props.item)
})

const closeSidebar = () => {
    mobileMenuOpen.value = false
    sideBarOpen.value = false
}
</script>

<template>
    <div v-if="showMenuItem" class="flex w-full flex-col gap-1" @click="!sideBarOpen ? (sideBarOpen = !sideBarOpen) : ''">
        <Disclosure :defaultOpen="item.active" v-if="item.items" v-slot="{ open }">
            <DisclosureButton
                :id="item.id"
                :class="[
                    item.active ? 'bg-neutral-900 text-white' : 'bg-fonky-black text-zinc-200 hover:bg-neutral-900 hover:text-white',
                    !showName ? 'justify-center px-3 py-2 text-white' : 'justify-between p-2',
                    !showName && item.active ? 'bg-primary-400' : '',
                ]"
                class="transition-color group flex items-center gap-3 rounded text-sm font-medium leading-5 duration-300 ease-out"
            >
                <div class="flex items-center gap-3">
                    <component :is="item.icon" class="h-6 w-6 shrink-0 group-hover:text-white" aria-hidden="true" />
                    <span v-show="showName">{{ translate(item.name) }}</span>
                </div>
                <ChevronRightIcon
                    v-show="showName"
                    :class="[
                        open ? 'rotate-90 text-gray-400' : 'text-gray-500',
                        'ui-open:rotate-90 ui-open:transform ml-auto h-5 w-5 shrink-0 transition-all duration-150 ease-in-out',
                    ]"
                    aria-hidden="true"
                />
            </DisclosureButton>
            <transition
                enter-active-class="transition duration-150 ease-in"
                enter-from-class="transform -translate-y-10 opacity-0"
                enter-to-class="transform opacity-100"
            >
                <DisclosurePanel v-show="showName" as="ul" class="mt-1 flex flex-col gap-1 pl-2 pr-0">
                    <li v-for="subItem in visibleChildren">
                        <Link
                            :id="subItem.id"
                            :aria-current="subItem.active ? 'page' : undefined"
                            @click="closeSidebar()"
                            :href="subItem.href"
                            :class="[
                                subItem.active ? 'bg-primary-400 text-white' : 'bg-fonky-black text-zinc-200 hover:bg-neutral-900 hover:text-white',
                                'transition-color group flex w-full items-center justify-between rounded py-2 pl-8 pr-2 text-sm font-medium leading-5 duration-300 ease-out',
                            ]"
                        >
                            {{ translate(subItem.name) }}
                        </Link>
                    </li>
                </DisclosurePanel>
            </transition>
        </Disclosure>
        <template v-else>
            <a
                v-if="item.external_link"
                :href="item.href"
                target="_blank"
                class="transition-color group flex items-center justify-start gap-3 rounded bg-fonky-black p-2 text-sm font-medium leading-5 text-zinc-200 duration-300 ease-out hover:bg-neutral-900 hover:text-white"
            >
                <component :is="item.icon" class="h-6 w-6 shrink-0 group-hover:text-white" aria-hidden="true" />
                <span v-show="showName">{{ translate(item.name) }}</span>
            </a>
            <Link
                v-else
                @click="closeSidebar()"
                :href="item.href"
                :class="[
                    item.active ? 'bg-primary-400 text-white' : 'bg-fonky-black text-zinc-200 hover:bg-neutral-900 hover:text-white',
                    !showName ? 'justify-center px-3 py-2' : 'justify-start p-2',
                ]"
                class="transition-color group flex items-center gap-3 rounded text-sm font-medium leading-5 duration-300 ease-out"
            >
                <component :is="item.icon" class="h-6 w-6 shrink-0 group-hover:text-white" aria-hidden="true" />
                <span v-show="showName">{{ translate(item.name) }}</span>
            </Link>
        </template>
    </div>
</template>
