<script setup lang="ts" generic="T">
import { useHumanReadableDate } from '@app/composables/date/useHumanReadableDate'
import type { Taskable } from '@app/types/task'
import { CheckIcon } from '@heroicons/vue/16/solid'

defineProps<{ task: Taskable<T> }>()

const { getHumanReadableDate } = useHumanReadableDate()
</script>

<template>
    <p v-if="task.completed_at" class="flex gap-2">
        <CheckIcon v-if="task.completed_at" class="size-5 text-green-700" />

        <span class="text-gray-600">{{ getHumanReadableDate(task.completed_at) }}</span>
    </p>
</template>
