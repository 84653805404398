<script lang="ts" setup>
import { computed } from 'vue'
import last from 'lodash/last'
import { DocumentIcon } from '@heroicons/vue/24/outline'
import { extractFileExtension } from '@app/utils/file'

const props = defineProps({
    url: {
        type: String,
        required: true,
    },
})

const fileName = computed(() => last(props.url.split('/')))
const fileExtension = computed(() => (fileName.value ? extractFileExtension(fileName.value) : null))
</script>

<template>
    <div class="mt-2 inline-flex items-center gap-x-2 rounded-md border border-gray-300 bg-white p-2 text-sm">
        <DocumentIcon class="h-5 w-5" />
        {{ fileName }}
        <span v-if="fileExtension" class="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold uppercase text-blue-800">
            {{ fileExtension }}
        </span>
    </div>
</template>
