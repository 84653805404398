<script setup lang="ts">
import { ref } from 'vue'

defineProps<{
    show: boolean
}>()

const expanded = ref(false)
const emit = defineEmits(['button:clicked'])

const handleClick = () => {
    expanded.value = !expanded.value
    emit('button:clicked')
}
</script>

<template>
    <div v-if="show && expanded" class="flex cursor-pointer text-xs text-primary-500 duration-100 hover:text-primary-600" @click="handleClick()">
        {{ $t('buttons.read_less') }}
    </div>
    <div v-if="show && !expanded" class="mt-1 flex cursor-pointer text-xs text-primary-500 duration-100 hover:text-primary-600" @click="handleClick()">
        {{ $t('buttons.read_more') }}
    </div>
</template>
