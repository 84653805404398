<script setup>
import { Dialog, DialogPanel, DialogTitle, TransitionRoot, TransitionChild } from '@headlessui/vue'
import { useForm } from '@inertiajs/vue3'
import Button from '@app/components/ui/button/Button.vue'

let emit = defineEmits(['permissions:close'])

const props = defineProps({
    role: Object,
    show: Boolean,
    permissions: Array,
})

const form = useForm({
    permission_ids: props.role.permission_ids,
    role_id: props.role.id,
})

const submitPermissions = () => {
    form.post(route('settings.role.update-permissions'), {
        preserveScroll: true,
        onSuccess: () => emit('permissions:close'),
    })
}
</script>

<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="relative z-10" @close="emit('permissions:close')">
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>
            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 sm:p-0">
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <DialogPanel
                            class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                        >
                            <form @submit.prevent="submitPermissions()">
                                <div class="bg-white p-4 sm:pb-4 md:p-6">
                                    <div class="mt-3 sm:ml-4 sm:mt-0">
                                        <DialogTitle as="h4" class="text-xl font-semibold leading-6"> Change Permissions </DialogTitle>
                                        <div class="mt-4">
                                            <div v-for="permission in permissions" class="space-y-2">
                                                <div>
                                                    <label class="inline-flex items-center">
                                                        <input
                                                            v-model="form.permission_ids"
                                                            class="text-secondary form-checkbox rounded-xl bg-gray-200"
                                                            type="checkbox"
                                                            :checked="props.role.permission_ids.includes(permission.id)"
                                                            :value="permission.id"
                                                        />
                                                        <span class="ml-2 text-sm md:text-base">{{ permission.name }}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex gap-2 bg-gray-50 px-4 py-3 sm:flex-row-reverse sm:px-6">
                                    <Button :disabled="form.processing">{{ $t('buttons.save') }}</Button>
                                    <Button color="white" @click="emit('permissions:close')">{{ $t('buttons.cancel') }}</Button>
                                </div>
                            </form>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
