<script setup lang="ts">
import { Link } from '@inertiajs/vue3'
import { computed, type PropType } from 'vue'

type LinkProp = {
    label: string
    href: string
    method?: 'get' | 'post' | 'put' | 'delete'
    data?: Record<string, any>
}

const props = defineProps({
    link: {
        type: Object as PropType<LinkProp>,
        required: true,
    },
    show: {
        type: Boolean,
        default: true,
    },
})

const method = computed(() => props.link.method || 'get')

const as = computed(() => (method.value === 'get' ? 'a' : 'button'))
</script>

<template>
    <Link v-if="show" :href="link.href" :method="method" :as="as" :data="link.data" class="text-primary-600 hover:text-primary-500">{{ link.label }}</Link>
</template>
