<script setup lang="ts">
import Card from '@app/components/ui/card/Card.vue'
import type { ContractModel } from '@app/types/contracts'
import { useForm } from '@inertiajs/vue3'
import { route } from 'ziggy-js'
import ContractForm from './ContractForm.vue'

defineEmits(['close'])

const form = useForm<ContractModel>({
    branch_id: null,
    job_title_id: null,
    contract_hour_type_id: null,
    duration_months: null,
    gross_monthly_salary: null,
    min_hours: null,
    max_hours: null,
    start_date: null,
    sign_by: null,
    email: '',
    trial_period: true,
    involves_visiting_other_locations: false,
    include_phone_allowance: false,
    phone_allowance_amount: null,
    retirement_provision: 'appendix',
    business_entity: 'Fonky B.V.',
})
</script>

<template>
    <Card :dismissable="true" @close="$emit('close')">
        <div class="text-left text-xl font-semibold leading-7 text-zinc-900">
            {{ $t('contract.create.title') }}
        </div>
        <ContractForm :form :title="$t('contract.create')" :action="route('contract.store')" @content:closed="$emit('close')" />
    </Card>
</template>
