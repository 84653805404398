<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useUserCan } from '@app/composables/useUserCan'
import type { ShiftAvailability } from '@app/types/shifts'
import { CalendarIcon } from '@heroicons/vue/24/solid'
import { computed } from 'vue'

const props = defineProps<{ availability: ShiftAvailability }>()

const { can } = useUserCan()

// change the logic below
const show = computed(() => can('shifts.shift.destroy'))
</script>

<template>
    <RoundButton v-if="show" label="Create shift">
        <CalendarIcon class="size-5" aria-hidden="true" />
    </RoundButton>
</template>
