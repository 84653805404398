<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useUserCan } from '@app/composables/useUserCan'
import type { User } from '@app/types/shared'
import { ArchiveBoxArrowDownIcon } from '@heroicons/vue/24/solid'
import { router } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps<{ user: User }>()

const { can } = useUserCan()

const show = computed(() => can('user.recruitment-selection.store') && props.user.lead?.funnel.slug !== 'recruitment-selection')

function moveUserRecruitmentSelection() {
    router.post(
        route('user.recruitment-selection.store', props.user.id),
        {},
        {
            only: ['users', 'selectedUser'],
        }
    )
}
</script>

<template>
    <RoundButton v-if="show" :label="$t('user.recruitment-selection.move')" @click="moveUserRecruitmentSelection">
        <ArchiveBoxArrowDownIcon class="h-4 w-4" aria-hidden="true" />
    </RoundButton>
</template>
