<script setup lang="ts">
import ContentTabs from '@app/components/layout/ContentTabs.vue'
import AvailabilityCard from '@app/components/shifts/availabilities/AvailabilityCard.vue'
import CreateAvailabilityForm from '@app/components/shifts/availabilities/forms/CreateAvailabilityForm.vue'
import ShiftAvailabilityEvent from '@app/components/shifts/availabilities/ShiftAvailabilityEvent.vue'
import Button from '@app/components/ui/button/Button.vue'
import Divider from '@app/components/ui/calendar/header/Divider.vue'
import Header from '@app/components/ui/calendar/header/Header.vue'
import WeekSwitcher from '@app/components/ui/calendar/header/WeekSwitcher.vue'
import Weekdays from '@app/components/ui/calendar/Weekdays.vue'
import DropdownMultiFilter from '@app/components/ui/table/filters/DropdownMultiFilter.vue'
import ResetFilterButton from '@app/components/ui/table/filters/ResetFilterButton.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'
import UserNameAndAvatar from '@app/components/users/UserNameAndAvatar.vue'
import { useNavigation } from '@app/composables/layout/useNavigation'
import { useDetailCard } from '@app/composables/useDetailCard'
import { type Branch, type ClientProject, type LocationType, type User, type WorkType } from '@app/types/shared'
import { type ShiftAvailability } from '@app/types/shifts'
import { applyFilter } from '@app/utils/filter'
import { eachDayOfInterval, endOfWeek, format, isPast, startOfWeek } from 'date-fns'
import { computed, ref } from 'vue'
import { route } from 'ziggy-js'

const { shiftAvailabilityTabs } = useNavigation()

const { branches, locationTypes, users, clientProjects, workTypes } = defineProps<{
    users: User[]
    branches: Branch[]
    locationTypes: LocationType[]
    clientProjects: ClientProject[]
    workTypes: WorkType[]
}>()

const emit = defineEmits(['create-shift:close', 'close'])

const selectedDay = ref(route().params.week ? new Date(route().params.week) : new Date())

const isPastWeek = computed(() => isPast(endOfWeek(selectedDay.value, { weekStartsOn: 1 })))

function switchWeek(date: Date) {
    applyFilter(
        { week: format(date, 'yyyy-MM-dd') },
        {
            only: ['users'],
            onSuccess() {
                selectedDay.value = date
            },
        }
    )
}

const openShiftForm = ref(false)
const sortedDay = ref<Date | undefined>()
const selectedEvent = ref<ShiftAvailability | null>(null)
const { toggleCard } = useDetailCard(selectedEvent, 'selectedEvent')
const openAvailabilityForm = ref(false)

const weekdays = computed(() =>
    eachDayOfInterval({
        start: startOfWeek(selectedDay.value, { weekStartsOn: 1 }),
        end: endOfWeek(selectedDay.value, { weekStartsOn: 1 }),
    })
)

const sortUsers = (day: Date) => {
    sortedDay.value = day
    users.sort((a, b) => {
        const hasAvailabilityA = a.shift_availabilities.some((availability) => new Date(availability.from).toDateString() === day.toDateString())
        const hasAvailabilityB = b.shift_availabilities.some((availability) => new Date(availability.from).toDateString() === day.toDateString())

        return hasAvailabilityA === hasAvailabilityB ? 0 : hasAvailabilityA ? -1 : 1
    })
}

function selectEvent(event: ShiftAvailability) {
    selectedEvent.value = event
}

const handleOpenAvailability = () => {
    openAvailabilityForm.value = true
}

const handleClick = (user, day) => {
    const hasNoAvailability = !user.shift_availabilities.some((availability) => new Date(availability.from).toDateString() === day.toDateString())

    if (hasNoAvailability) {
        handleOpenAvailability()
    }
}
</script>

<template>
    <div>
        <ContentTabs bottomBorder :items="shiftAvailabilityTabs" />
        <div class="min-h-[24px]">
            <div class="flex items-start gap-4 scrollbar-hide">
                <div class="flex h-full w-full flex-col">
                    <Header :date="selectedDay">
                        <WeekSwitcher :model-value="selectedDay" @update:model-value="switchWeek" />
                        <Divider />
                        <Button :disabled="isPastWeek" color="white" @click="handleOpenAvailability">
                            {{ $t('calendar.availability.add') }}
                        </Button>
                        <Divider />
                        <Button class="relative"> Create a new shift </Button>
                    </Header>

                    <FilterSection :showPerPageOptions="false" class="-ml-4">
                        <DropdownMultiFilter border :items="clientProjects" :label="$t('attributes.client_project')" slug="client_project" />
                        <DropdownMultiFilter border :items="locationTypes" :label="$t('attributes.location_type')" slug="location_type" />
                        <DropdownMultiFilter border :items="branches" :label="$t('attributes.branch')" slug="branch" />
                        <ResetFilterButton
                            :href="route('shifts.availability.index', { workType: route().params.workType, week: route().params.week })"
                            :label="$t('buttons.clear')"
                        />
                    </FilterSection>

                    <div class="mt-2 flex flex-col-reverse items-start gap-2 md:flex-row">
                        <div
                            ref="container"
                            class="isolate flex flex-auto flex-col overflow-y-auto rounded-lg border-zinc-100 bg-white transition-width duration-100 ease-in-out scrollbar-hide sm:border"
                            :class="{ 'w-2/3': openAvailabilityForm || selectedEvent, 'w-full': !(openAvailabilityForm || selectedEvent) }"
                        >
                            <div class="flex w-[200%] flex-none flex-col lg:w-full">
                                <div class="sticky top-0 z-50 flex-none shadow-sm ring-1 ring-black ring-opacity-5">
                                    <div class="grid grid-cols-7 divide-x divide-zinc-100 border-b border-zinc-100 text-xs leading-4 text-zinc-500">
                                        <div class="sticky left-0 col-end-1 w-40 md:w-52" />
                                        <Weekdays :days="weekdays" :sortedDay="sortedDay" shorthand sortable @sort="sortUsers" />
                                    </div>
                                </div>
                                <div class="flex">
                                    <table class="min-w-full table-fixed border-t-0">
                                        <tbody class="divide-y divide-zinc-100 bg-white">
                                            <tr v-for="user in users" class="row-span-4 grid h-28 w-full grid-cols-7 flex-col justify-between py-1 text-sm">
                                                <td
                                                    class="sticky left-0 z-40 col-span-1 col-end-1 flex w-40 items-center whitespace-normal border-r border-zinc-100 bg-white px-4 md:w-52"
                                                >
                                                    <UserNameAndAvatar :user="user" />
                                                </td>
                                                <td v-for="day in weekdays" class="flex flex-col items-center">
                                                    <div
                                                        class="flex h-full max-h-[6.4rem] w-full cursor-pointer flex-col gap-1 overflow-hidden"
                                                        @click="handleClick(user, day)"
                                                    >
                                                        <ShiftAvailabilityEvent
                                                            v-for="availability in user.shift_availabilities.filter(
                                                                (availability: ShiftAvailability) =>
                                                                    new Date(availability.from).toDateString() === day.toDateString()
                                                            )"
                                                            :key="availability.id"
                                                            :availability="availability"
                                                            @click="selectEvent(availability)"
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Transition name="slide-fade" mode="out-in">
                            <AvailabilityCard v-if="selectedEvent" @close="selectedEvent = null" :availability="selectedEvent" />
                        </Transition>

                        <Transition name="slide-fade" mode="out-in">
                            <!-- <CreateShiftForm v-if="openShiftForm" :dismissable="true" @create-shift:close="openShiftForm = false" /> -->
                            <CreateAvailabilityForm
                                v-if="openAvailabilityForm"
                                @close="openAvailabilityForm = false"
                                :workTypes="workTypes"
                                :locationTypes="locationTypes"
                                :branches="branches"
                            />
                        </Transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
