import { router } from '@inertiajs/vue3'
import { ref } from 'vue'

export const requestIsInProgress = ref(false)

export function useInertiaRequestProgress() {
    function captureProgress() {
        const removeOnStartListener = router.on('start', () => {
            requestIsInProgress.value = true
        })

        const removeOnFinishListener = router.on('finish', () => {
            requestIsInProgress.value = false
        })

        return () => {
            removeOnStartListener()
            removeOnFinishListener()
        }
    }

    return {
        captureProgress,
    }
}
