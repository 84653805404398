<script setup lang="ts">
import DropdownFilter from '@app/components/ui/table/filters/DropdownFilter.vue'

defineProps({
    label: {
        type: String,
        required: true,
    },
    slug: {
        type: String,
        required: true,
    },
})

const dateFilter = [
    {
        name: 'filters.date.this_week',
        slug: 'this_week',
    },
    {
        name: 'filters.date.upcoming',
        slug: 'upcoming',
    },
]
</script>

<template>
    <DropdownFilter :label="label" :items="dateFilter" :translateDb="false" propertyName="name" by="slug" :slug="slug" />
</template>
