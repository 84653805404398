<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import FormTextArea from '@app/components/ui/form/FormTextArea.vue'
import Input from '@app/components/ui/form/Input.vue'
import MoneyInput from '@app/components/ui/form/MoneyInput.vue'
import { useModelSelect } from '@app/composables/useModelSelect'
import { useUserCan } from '@app/composables/useUserCan'
import type { ClientProject, Product, ProductType } from '@app/types/shared'
import { TrashIcon } from '@heroicons/vue/20/solid'
import { router, useForm, usePage } from '@inertiajs/vue3'
import { trans } from 'laravel-vue-i18n'
import { computed, toRefs } from 'vue'
import { route } from 'ziggy-js'

const emit = defineEmits(['submit'])
const props = defineProps<{ product: Product | null }>()

const { product } = toRefs(props)

const { can } = useUserCan()

type DropdownItem = {
    slug: string
    name: string
}

const productTypes = usePage().props.productTypes as ProductType[]
const clientProjects = usePage().props.clientProjects as ClientProject[]
const businessModels = usePage().props.businessModels as DropdownItem[]
const paymentFrequencies = usePage().props.paymentFrequencies as DropdownItem[]

const selectedProductType = useModelSelect(productTypes, product.value?.product_type_id)
const selectedClientProject = useModelSelect(clientProjects, product.value?.client_project_id)
const selectedBusinessModel = useModelSelect(businessModels, product.value?.business_model, 'slug')
const selectedPaymentFrequency = useModelSelect(paymentFrequencies, product.value?.payment_frequency, 'slug')

const form = useForm({
    name: product.value?.name,
    external_id: product.value?.external_id,
    description: product.value?.description,
    client_project_id: product.value?.client_project_id,
    product_type_id: product.value?.product_type_id,
    business_model: product.value?.business_model,
    payment_frequency: product.value?.payment_frequency,
    subscription_months: product.value?.subscription_months,
    price: product.value?.price?.amount,
})

function submit() {
    if (product.value) {
        form.put(route('settings.products.product.update', product.value.id), { onSuccess: () => emit('submit') })
    } else {
        form.post(route('settings.products.product.store'), { onSuccess: () => emit('submit') })
    }
}

const formTitle = computed(() => (product.value ? product.value.name : trans('actions.create')))

function deleteProduct(id: number) {
    router.delete(route('settings.products.product.destroy', id))
}
</script>

<template>
    <Form :form="form" class="w-full" @submit="submit">
        <FormSection :title="formTitle">
            <FormField prop="name" class="col-span-12" :label="$t('attributes.name')">
                <Input id="name" v-model="form.name" />
            </FormField>

            <FormField prop="description" class="col-span-12" :label="$t('attributes.description')">
                <FormTextArea v-model="form.description" rows="5" />
            </FormField>

            <FormField prop="product_type_id" class="col-span-12 sm:col-span-6" :label="$t('attributes.product_type')">
                <Dropdown
                    :label="$t('attributes.product_type')"
                    :items="productTypes"
                    propertyName="name"
                    v-model="selectedProductType"
                    v-model:form="form.product_type_id"
                />
            </FormField>

            <FormField prop="external_id" class="col-span-12 sm:col-span-6" :label="$t('attributes.external_id')">
                <Input id="external_id" v-model="form.external_id" />
            </FormField>

            <FormField prop="client_project_id" class="col-span-12 sm:col-span-6" :label="$t('attributes.client_project')">
                <Dropdown
                    :label="$t('attributes.client_project')"
                    :items="clientProjects"
                    propertyName="name"
                    v-model="selectedClientProject"
                    v-model:form="form.client_project_id"
                />
            </FormField>

            <FormField prop="business_model" class="col-span-12 sm:col-span-6" :label="$t('attributes.business_model')">
                <Dropdown
                    :label="$t('attributes.business_model')"
                    :items="businessModels"
                    propertyName="name"
                    v-model="selectedBusinessModel"
                    v-model:form="form.business_model"
                    by="slug"
                />
            </FormField>

            <FormField prop="price" class="col-span-12 sm:col-span-6" :label="$t('attributes.price')">
                <MoneyInput v-model="form.price" />
            </FormField>

            <FormField prop="payment_frequency" class="col-span-12 sm:col-span-6" :label="$t('attributes.payment_frequency')">
                <Dropdown
                    :label="$t('attributes.payment_frequency')"
                    :items="paymentFrequencies"
                    propertyName="name"
                    v-model="selectedPaymentFrequency"
                    v-model:form="form.payment_frequency"
                    by="slug"
                />
            </FormField>

            <FormField prop="subscription_months" class="col-span-12" :label="$t('attributes.subscription_months')">
                <Input id="subscription_months" type="number" v-model="form.subscription_months" />
            </FormField>
        </FormSection>

        <FormActions>
            <Button type="button" v-if="product && can('settings.products.product.destroy')" color="white" @click="deleteProduct(product.id)">
                <TrashIcon class="size-4"></TrashIcon> {{ $t('actions.delete') }}
            </Button>
            <SaveButton :mode="product ? 'save' : 'create'" />
        </FormActions>
    </Form>
</template>
