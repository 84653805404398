<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import Input from '@app/components/ui/form/Input.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import { useForm } from '@inertiajs/vue3'
import { route } from 'ziggy-js'
import { router } from '@inertiajs/vue3'
import FormActions from '@app/components/ui/form/FormActions.vue'
import type { Role } from '@app/types/shared'
import Settings from '@app/layouts/Settings.vue'

const props = defineProps<{
    role?: Role
}>()

const form = useForm({
    name: props.role?.name || '',
})

function submit() {
    if (props.role) {
        form.put(route('settings.role.update', props.role.id))
    } else {
        form.post(route('settings.role.store'))
    }
}
</script>

<template>
    <Settings group="general">
        <Form :form="form" @submit="submit" class="max-w-xl">
            <FormSection>
                <FormField prop="name" :label="$t('attributes.name')" class="col-span-12">
                    <Input id="name" v-model="form.name" />
                </FormField>
            </FormSection>

            <FormActions>
                <Button color="black" type="button" @click="router.get(route('settings.role.index'))">{{ $t('buttons.back') }}</Button>
                <Button :disabled="form.processing">{{ $t('buttons.save') }}</Button>
            </FormActions>
        </Form>
    </Settings>
</template>
