<script setup lang="ts">
import GroupedStatCard from '@app/components/dashboard/GroupedStatCard.vue'
import PrimaryStatCard from '@app/components/dashboard/PrimaryStatCard.vue'
import SecondaryStatCard from '@app/components/dashboard/SecondaryStatCard.vue'
import Button from '@app/components/ui/button/Button.vue'
import { useChannel } from '@app/composables/useEcho'
import { useUserCan } from '@app/composables/useUserCan'
import type { User } from '@app/types/shared'
import type { GroupedStatistics, Statistic } from '@app/types/statistics'
import { reloadPartial } from '@app/utils/inertia'
import { getLeadsLink } from '@app/utils/recruitment/lead'
import { CalendarIcon, DevicePhoneMobileIcon } from '@heroicons/vue/20/solid'
import { UserGroupIcon } from '@heroicons/vue/24/outline'
import { Link } from '@inertiajs/vue3'
import { trans } from 'laravel-vue-i18n'
import type { ComputedRef } from 'vue'
import { computed, onMounted } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps<{
    user: User
    salesAgents: Object
    promotors: Object
    contactCenterAgents: Object
    leadsReached: Object
    leadsApproved: Object
    leadsDeclined: Object
    salesAgentsLead: Object
    salesAgentsIntroduction: Object
    salesAgentsWorkshop: Object
    salesAgentsOnboarding: Object
    promotorLead: Object
    promotorIntroduction: Object
    promotorWorkshop: Object
    promotorOnboarding: Object
    contactCenterLead: Object
    contactCenterIntroduction: Object
    contactCenterWorkshop: Object
    contactCenterOnboarding: Object
}>()

onMounted(() => {
    subscribe('.recruitment.lead.created', () => reloadPartial(['salesAgents', 'promotors', 'contactCenterAgents']))
    subscribe('.recruitment.lead.status.updated', () => reloadPartial(['leadsApproved', 'leadsDeclined']))
    subscribe('.call.completed', () => reloadPartial(['leadsReached']))
})

const { can, isInternalUser } = useUserCan()
const { subscribe } = useChannel('recruitment')
const twoFactorEnabled = computed(() => props.user.two_factor_enabled)

const amountOfLeadsStatistic: ComputedRef<Statistic> = computed(() => {
    return {
        title: trans('home.main_stat.title'),
        number: props.salesAgents.all + props.promotors.all + props.contactCenterAgents.all,
    }
})

const secondarySalesAgentStatistic: ComputedRef<Statistic> = computed(() => {
    return {
        title: 'Field',
        number: props.salesAgents.all,
        link: getLeadsLink('sales-agent', 'lead'),
    }
})

const secondaryPromotorStatistic: ComputedRef<Statistic> = computed(() => {
    return {
        title: 'Promotions',
        number: props.promotors.all,
        link: getLeadsLink('promotor', 'lead'),
    }
})

const secondaryContactCenterStatistic: ComputedRef<Statistic> = computed(() => {
    return {
        title: 'Contact Center',
        number: props.contactCenterAgents.all,
        link: getLeadsLink('contact-center', 'lead'),
    }
})

const contactCenterTeamStatistics: ComputedRef<GroupedStatistics> = computed(() => {
    return {
        title: trans('home.team_stats'),
        statistics: [
            {
                title: trans('home.leads_reached'),
                number: props.leadsReached.team.week,
            },
            {
                title: trans('home.leads_approved'),
                number: props.leadsApproved.team.week,
            },
            {
                title: trans('home.leads_rejected'),
                number: props.leadsDeclined.team.week,
            },
        ],
    }
})

const contactCenterPersonalStatistics: ComputedRef<GroupedStatistics> = computed(() => {
    return {
        title: trans('home.personal_stats'),
        statistics: [
            {
                title: trans('home.leads_reached'),
                number: props.leadsReached.personal.week,
            },
            {
                title: trans('home.leads_approved'),
                number: props.leadsApproved.personal.week,
            },
            {
                title: trans('home.leads_rejected'),
                number: props.leadsDeclined.personal.week,
            },
        ],
    }
})

const salesAgentStatistics: ComputedRef<GroupedStatistics> = computed(() => {
    return {
        title: 'Field',
        statistics: [
            {
                title: trans('home.lead'),
                number: props.salesAgentsLead.week,
            },
            {
                title: trans('home.introduction'),
                number: props.salesAgentsIntroduction.week,
            },
            {
                title: trans('home.workshop'),
                number: props.salesAgentsWorkshop.week,
            },
            {
                title: trans('home.onboarding'),
                number: props.salesAgentsOnboarding.week,
            },
        ],
    }
})

const promotorStatistics: ComputedRef<GroupedStatistics> = computed(() => {
    return {
        title: 'Promotions',
        statistics: [
            {
                title: trans('home.lead'),
                number: props.promotorLead.week,
            },
            {
                title: trans('home.introduction'),
                number: props.promotorIntroduction.week,
            },
            {
                title: trans('home.workshop'),
                number: props.promotorWorkshop.week,
            },
            {
                title: trans('home.onboarding'),
                number: props.promotorOnboarding.week,
            },
        ],
    }
})

const contactCenterStatistics: ComputedRef<GroupedStatistics> = computed(() => {
    return {
        title: 'Contact Center',
        statistics: [
            {
                title: trans('home.lead'),
                number: props.contactCenterLead.week,
            },
            {
                title: trans('home.introduction'),
                number: props.contactCenterIntroduction.week,
            },
            {
                title: trans('home.workshop'),
                number: props.contactCenterWorkshop.week,
            },
            {
                title: trans('home.onboarding'),
                number: props.contactCenterOnboarding.week,
            },
        ],
    }
})
</script>

<template>
    <div class="flex flex-col gap-6">
        <div class="flex w-full flex-col justify-between gap-6 lg:flex-row lg:items-center">
            <h2 class="mt-2 text-xl font-medium text-zinc-900">{{ $t('home.welcome') + (user.first_name || user.full_name) }}!</h2>
            <div class="flex flex-wrap items-start gap-2 md:items-center md:gap-4">
                <Button v-if="!twoFactorEnabled" :as="Link" :href="route('user.settings.show')" color="white">
                    <DevicePhoneMobileIcon class="h-6 w-6 text-primary-500" />
                    {{ $t('two_factor_form.button_enable') + ' ' + $t('two_factor_form.title') }}
                </Button>

                <Button v-if="can('recruitment.availability.show')" :as="Link" :href="route('recruitment.availability.show')" class="">
                    <CalendarIcon class="h-6 w-6" />
                    {{ $t('home.availability') }}
                </Button>
            </div>
        </div>

        <div class="flex flex-col gap-6">
            <div v-if="isInternalUser" class="flex w-full flex-col justify-between gap-4 sm:flex-row">
                <PrimaryStatCard :statistic="amountOfLeadsStatistic" class="w-full sm:w-1/3">
                    <template v-slot:icon>
                        <UserGroupIcon class="h-6 w-6 text-primary-500" aria-hidden="true" />
                    </template>
                </PrimaryStatCard>
                <div class="flex w-full flex-col justify-between sm:flex-row">
                    <SecondaryStatCard :statistic="secondarySalesAgentStatistic" />
                    <SecondaryStatCard :statistic="secondaryPromotorStatistic" />
                    <SecondaryStatCard :statistic="secondaryContactCenterStatistic" />
                </div>
            </div>
            <div v-if="can('recruitment.contact-center-agent.dashboard')" class="flex flex-col gap-4">
                <h3 class="text-lg font-medium leading-6 text-zinc-900">
                    {{ $t('home.this_week') }}
                </h3>
                <div class="flex w-full flex-col justify-between gap-3 sm:flex-row">
                    <GroupedStatCard :groupedStatistics="contactCenterTeamStatistics" />
                    <GroupedStatCard :groupedStatistics="contactCenterPersonalStatistics" />
                </div>
            </div>
            <div v-if="can('recruitment.host.dashboard')" class="flex flex-col gap-4">
                <h3 class="text-lg font-medium leading-6 text-zinc-900">
                    {{ $t('home.this_week') }}
                </h3>
                <div class="flex w-full flex-col justify-between gap-3 sm:flex-row">
                    <GroupedStatCard :groupedStatistics="salesAgentStatistics" />
                    <GroupedStatCard :groupedStatistics="promotorStatistics" />
                    <GroupedStatCard :groupedStatistics="contactCenterStatistics" />
                </div>
            </div>
        </div>
    </div>
</template>
