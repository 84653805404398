<script setup lang="ts">
import { computed } from 'vue'
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import LeadOutcomeForm from '@app/components/recruitment/leads/forms/LeadOutcomeForm.vue'
import { type ShallowRef, inject, type Component } from 'vue'
import { ForwardIcon } from '@heroicons/vue/24/solid'
import type { Lead } from '@app/types/recruitment'
import { isNotEmptyArray } from '@app/utils/array'
import { useUserCan } from '@app/composables/useUserCan'
import { join } from 'lodash'

const props = defineProps<{
    lead: Lead
}>()

const { can } = useUserCan()

const show = computed(() => {
    let permissionName = join(['recruitment', 'lead', props.lead.funnel.slug, props.lead.funnel_block.slug, 'outcome'], '.')
    return !props.lead.is_status_final && props.lead.is_contactable && isNotEmptyArray(props.lead.status_outcomes) && can(permissionName)
})

const activeButton = inject<ShallowRef<Component>>('activeButton')
</script>

<template>
    <RoundButton v-if="show" :label="$t('recruitment.leads.card.actions.outcome')" @click="activeButton = LeadOutcomeForm">
        <ForwardIcon class="h-4 w-4" aria-hidden="true" />
    </RoundButton>
</template>
