<script setup lang="ts">
import Weekday from '@app/components/ui/calendar/Weekday.vue'
import { useLocale } from '@app/composables/useLocale'
import { ucfirst } from '@app/utils/transform'
import { format, isToday } from 'date-fns'
import { type PropType } from 'vue'

defineProps({
    days: {
        type: Array as PropType<Date[]>,
        required: true,
    },
    shorthand: {
        type: Boolean,
        default: false,
    },
    sortable: {
        type: Boolean,
        default: false,
    },
    sortedDay: {
        type: Date,
    },
})

const emit = defineEmits(['sort'])
const { getLocale } = useLocale()

const handleSort = (day: Date) => {
    emit('sort', day)
}
</script>

<template>
    <Weekday
        v-for="day in days"
        :key="day.toString()"
        :active="isToday(day)"
        :weekday="shorthand ? ucfirst(format(day, 'EEEEEE', { locale: getLocale() })) : ucfirst(format(day, 'EEEE', { locale: getLocale() }))"
        :day="day"
        :sortable="sortable"
        :sortedDay="sortedDay"
        @sort="handleSort(day)"
    />
</template>
