<script setup>
import Content from '@app/components/layout/Content.vue'
import { useInertiaRequestProgress } from '@app/composables/useInertiaRequestProgress'
import { onMounted } from 'vue'

const { captureProgress } = useInertiaRequestProgress()

onMounted(() => captureProgress())
</script>

<template>
    <div class="flex h-dvh">
        <div class="flex h-full flex-1 flex-col overflow-hidden">
            <header class="w-full">
                <div class="relative flex h-16 flex-shrink-0 justify-between shadow-sm">
                    <div class="flex flex-1 justify-between gap-4 bg-white px-2 py-3 md:px-6">
                        <img class="h-8 w-auto self-center" src="@assets/img/full-logo.svg" alt="Fonky logo" />
                    </div>
                </div>
            </header>
            <div class="relative flex h-full flex-1 flex-row overflow-hidden sm:mx-auto">
                <Content>
                    <slot />
                </Content>
            </div>
        </div>
    </div>
</template>
