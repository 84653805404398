<script setup lang="ts">
import Form from '@app/components/settings/finance/shiftRankBonuses/Form.vue'
import Card from '@app/components/ui/card/Card.vue'
import DataTable from '@app/components/ui/table/DataTable.vue'
import { useDetailCard } from '@app/composables/useDetailCard'
import Settings from '@app/layouts/Settings.vue'
import { type ShiftRankBonus } from '@app/types/finance'
import type { ResourceCollection } from '@app/types/shared'
import { applyFilter } from '@app/utils/filter'
import { type PropType, toRefs } from 'vue'

const props = defineProps({
    shiftRankBonuses: {
        type: Object as PropType<ResourceCollection<ShiftRankBonus>>,
        required: true,
    },
    selectedShiftRankBonus: {
        type: [Object, null] as PropType<ShiftRankBonus | null>,
        required: true,
    },
})

const { selectedShiftRankBonus } = toRefs(props)
const { toggleCard } = useDetailCard(selectedShiftRankBonus, 'selectedShiftRankBonus')

const columns = [
    {
        type: 'multi-line',
        header: 'settings.work-type-ranks',
        properties: {
            name: 'channel_category',
            primaryName: 'work_type_rank',
            secondaryName: 'work_type',
        },
        sort: 'id',
    },
    {
        type: 'amount',
        header: 'attributes.amount',
        properties: { name: 'amount' },
        sort: 'amount',
    },
    {
        type: 'date',
        header: 'attributes.amount_start_date',
        properties: { name: 'amount_start_date' },
        sort: 'amount_start_date',
    },
]

function closeForm() {
    applyFilter({
        selected: null,
    })
}
</script>

<template>
    <Settings group="finance">
        <div class="md:items-top mb-6 md:flex md:justify-between">
            <DataTable
                class="flex w-full flex-col rounded-lg border border-gray-100 transition-all duration-500"
                :items="shiftRankBonuses"
                :columns="columns"
                only="shiftRankBonuses"
                :clickable="true"
                :activeId="selectedShiftRankBonus?.id"
                @row:clicked="(item) => toggleCard(item.id)"
            />
            <Transition name="slide-fade" mode="out-in">
                <Card v-if="selectedShiftRankBonus" class="md:min-w-[29rem] lg:w-2/3" @close="closeForm" :dismissable="true">
                    <Form :shiftRankBonus="selectedShiftRankBonus" :key="selectedShiftRankBonus?.id" @submit="closeForm" />
                </Card>
            </Transition>
        </div>
    </Settings>
</template>
