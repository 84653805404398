/**
 *
 * Add to object if it does not yet exist, remove otherwise.
 */
export function addOrRemoveValueFromObject(object: Object, key: any, value: any): Object {
    if (object[key]) {
        delete object[key]
    } else {
        object[key] = value
    }

    return object
}
