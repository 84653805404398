<script setup lang="ts">
import OpenLead from '@app/components/recruitment/leads/actions/OpenLead.vue'
import { useUserCan } from '@app/composables/useUserCan'
import type { User } from '@app/types/shared'
import { computed } from 'vue'

const props = defineProps<{ user: User }>()

const { can } = useUserCan()

const show = computed(() => can('recruitment.lead.recruitment-selection.lead.index') && props.user.lead?.funnel.slug === 'recruitment-selection')
</script>

<template>
    <OpenLead v-if="show && user.lead" label="user.recruitment-selection.show" :lead="user.lead" />
</template>
