<script setup>
import { nextTick, ref } from 'vue'
import { Head, useForm } from '@inertiajs/vue3'
import Auth from '@app/layouts/Auth.vue'
import { route } from 'ziggy-js'
import AuthErrorBag from '@app/components/auth/AuthErrorBag.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import Input from '@app/components/ui/form/Input.vue'
import Button from '@app/components/ui/button/Button.vue'

defineOptions({
    layout: Auth,
})

const recovery = ref(false)

const form = useForm({
    code: '',
    recovery_code: '',
})

const recoveryCodeInput = ref(null)
const codeInput = ref(null)

const toggleRecovery = async () => {
    recovery.value ^= true

    await nextTick()

    if (recovery.value) {
        recoveryCodeInput.value.focus()
        form.code = ''
    } else {
        codeInput.value.focus()
        form.recovery_code = ''
    }
}

const submit = () => {
    form.post(route('two-factor.login'))
}
</script>

<template>
    <Head title="Two-factor Confirmation" />

    <div class="mb-4 text-sm text-gray-600">
        <template v-if="!recovery">
            {{ $t('two_factor_challenge.content_code') }}
        </template>

        <template v-else>
            {{ $t('two_factor_challenge.content_recovery') }}
        </template>
    </div>

    <AuthErrorBag class="mb-4" />

    <Form :form="form" @submit="submit" class="space-y-4">
        <FormField v-if="!recovery" prop="code" :label="$t('attributes.code')" :show-error="false">
            <Input id="code" ref="codeInput" v-model="form.code" inputmode="numeric" autofocus autocomplete="one-time-code" />
        </FormField>

        <FormField v-else prop="recovery_code" :label="$t('attributes.recovery_code')" :show-error="false">
            <Input id="recovery_code" ref="recoveryCodeInput" v-model="form.recovery_code" />
        </FormField>

        <div class="flex items-center justify-end">
            <button type="button" class="cursor-pointer text-sm text-gray-600 underline hover:text-gray-900" @click.prevent="toggleRecovery">
                <template v-if="!recovery">
                    {{ $t('two_factor_challenge.link_recovery_code') }}
                </template>

                <template v-else>
                    {{ $t('two_factor_challenge.link_code') }}
                </template>
            </button>

            <Button color="black" class="ml-4" :disabled="form.processing">
                {{ $t('two_factor_challenge.button_login') }}
            </Button>
        </div>
    </Form>
</template>
