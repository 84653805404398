<script setup>
import { computed, ref } from 'vue'
import { useForm } from '@inertiajs/vue3'
import { validateStringLength } from '@app/utils/validation'
import Button from '../ui/button/Button.vue'
import { useLocale } from '@app/composables/useLocale'
import { extractFileExtension } from '@app/utils/file'
import FieldError from '../ui/form/FieldError.vue'
import CancelButton from '../ui/button/CancelButton.vue'
import SaveButton from '../ui/button/SaveButton.vue'
import MediaFile from './MediaFile.vue'
import { fonkyFlyerTemplate } from '@app/utils/whatsapp/mediaTemplates'
import { ExclamationCircleIcon } from '@heroicons/vue/16/solid'

const props = defineProps({
    model: {
        type: Object,
        required: true,
    },
})

const emit = defineEmits(['content:closed'])

const { dbTranslate } = useLocale()
const form = useForm({
    lead_id: null,
    phone: null,
    message: null,
    media_url: null,
    whatsapp_template_id: null,
    whatsapp_template_variables: {},
})

const textarea = ref()
const messageTooLong = computed(() => validateStringLength(1600, form.message))
const showCustomMessageError = ref(false)
const isTemplate = ref(false)
const selectedMediaTemplate = ref(null)
const selectedTemplate = ref(null)

const submit = () => {
    form.lead_id = props.model.id
    form.phone = props.model.phone_number
    if (textarea.value && (!form.message || messageTooLong.value)) {
        textarea.value.focus()
        return
    }

    if (!isTemplate.value && !props.model.can_receive_custom_whatsapp) {
        showCustomMessageError.value = true
        textarea.value?.focus()
        form.reset()
        return
    }

    if (selectedTemplate.value) {
        let template = replaceVariables(selectedTemplate.value.content, 'first_name')
        form.whatsapp_template_id = template === form.message ? selectedTemplate.value.id : null
        form.whatsapp_template_variables['first_name'] = props.model.first_name
    }

    form.post(route('recruitment.whatsapp.send'), {
        preserveScroll: true,
        onSuccess() {
            close()
        },
        onCancel() {
            close()
        },
    })
}

const useTemplate = (template, ...variables) => {
    selectedTemplate.value = template
    selectedMediaTemplate.value = null
    form.media_url = null
    isTemplate.value = true
    form.message = replaceVariables(template.content, variables)
}

const replaceVariables = (text, ...variables) => {
    variables.forEach((element) => {
        text = text.replaceAll('${' + element + '}', props.model[element])
    })

    return text
}

function selectMediaFileTemplate(mediaTemplate) {
    selectedMediaTemplate.value = mediaTemplate

    form.message = ''
    form.media_url = mediaTemplate.url
}

function close() {
    selectedMediaTemplate.value = null
    form.reset()
    emit('content:closed')
}

const canSendMessage = computed(() => props.model.can_receive_custom_whatsapp || props.model.whatsapp_templates.length > 0)
</script>

<template>
    <div v-if="canSendMessage" class="py-4">
        <div class="flex flex-col">
            <div class="block text-xs font-normal text-gray-500">{{ $t('whatsapp.template_header') }}</div>
            <div class="my-2 flex flex-wrap gap-2">
                <Button v-for="template in model.whatsapp_templates" color="white" size="sm" @click="useTemplate(template, 'first_name')" :id="template.slug">
                    {{ dbTranslate(template.name) }}
                </Button>
                <Button v-if="model.can_receive_custom_whatsapp" color="white" size="sm" @click="selectMediaFileTemplate(fonkyFlyerTemplate)">
                    {{ $t(fonkyFlyerTemplate.name) }}
                    <span class="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold uppercase text-blue-800">
                        {{ extractFileExtension(fonkyFlyerTemplate.url) }}
                    </span>
                </Button>
            </div>
        </div>
        <form @submit.prevent="submit" class="mt-2" id="whatsapp_form">
            <div v-if="!selectedMediaTemplate">
                <label for="message" class="block text-sm font-medium text-gray-700"> {{ $t('whatsapp.description') }}</label>
                <div class="mt-1">
                    <textarea
                        v-model="form.message"
                        id="message"
                        name="message"
                        rows="4"
                        class="block w-full rounded-md border-gray-300 shadow-sm scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400 focus:border-primary-600 focus:ring-primary-600 sm:text-sm"
                        ref="textarea"
                        :readonly="!model.can_receive_custom_whatsapp"
                    />
                </div>
            </div>

            <div v-else>
                <label for="message" class="block text-sm font-medium text-gray-700"> {{ $t('whatsapp.attachment') }}</label>
                <MediaFile :url="selectedMediaTemplate.url" class="mt-2" />
            </div>

            <FieldError v-if="messageTooLong" :message="messageTooLong" class="mt-2" />
            <FieldError v-if="showCustomMessageError" :message="$t('whatsapp.custom_message_error')" class="mt-2" />
            <FieldError :message="form.errors.message" class="mt-2" />
            <FieldError :message="form.errors.phone" class="mt-2" />

            <div class="mt-4 flex place-content-end space-x-2">
                <CancelButton color="white" @click="close" />
                <SaveButton :disabled="form.processing" id="whatsapp_send_button">{{ $t('buttons.send') }}</SaveButton>
            </div>
        </form>
    </div>

    <div v-else class="flex items-start justify-center gap-2 bg-gray-300 py-3 text-center text-sm text-gray-600">
        <ExclamationCircleIcon class="h-5 w-5" />

        {{ $t('whatsapp.readonly_mode') }}
    </div>
</template>
