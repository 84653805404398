<script setup lang="ts">
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import type { Funnel, FunnelBlock, Lead } from '@app/types/recruitment'
import { useForm } from '@inertiajs/vue3'
import { ref, toRefs } from 'vue'
import { route } from 'ziggy-js'
import RecruitmentAvailabilitySelector from './RecruitmentAvailabilitySelector.vue'

const props = defineProps<{
    lead: Lead
    funnel: Funnel
    funnelBlock: FunnelBlock
    action: string
}>()

const emit = defineEmits(['content:closed'])

const { lead, funnel, funnelBlock } = toRefs(props)

const loaded = ref(false)

const form = useForm({
    lead_id: lead.value.id,
    recruitment_availability_id: null,
})

function storeAppointment() {
    form.post(route(props.action), {
        onSuccess: () => {
            emit('content:closed')
        },
        onCancel: () => {
            emit('content:closed')
        },
    })
}
</script>

<template>
    <div class="mx-auto flex flex-col items-center">
        <RecruitmentAvailabilitySelector
            :lead="lead"
            :funnel="funnel"
            :funnelBlock="funnelBlock"
            v-model="form.recruitment_availability_id"
            @loaded="loaded = true"
        />

        <SaveButton v-if="loaded" size="base" class="mt-2 w-full" @click="storeAppointment" :disabled="!form.recruitment_availability_id || form.processing" />
    </div>
</template>
