<script setup>
import { ref, onMounted, watch } from 'vue'

const props = defineProps({
    initialData: Object,
    routeName: {
        type: String,
        required: true,
    },
    only: {
        type: String,
        required: true,
    },
})

const rootElement = ref(null)
const targetElement = ref(null)

const items = ref(props.initialData.data)
const nextPage = ref(getNextPageFromPaginatedData(props.initialData))
const isLoading = ref(false)

// reset pagination on reload
watch(
    () => props.initialData,
    () => {
        items.value = props.initialData.data
        nextPage.value = getNextPageFromPaginatedData(props.initialData)
    }
)

function getNextPageFromPaginatedData(data) {
    if (data.current_page < data.last_page) {
        return data.current_page + 1
    }

    return null
}

async function fetchNextPageData() {
    isLoading.value = true

    const { data } = await axios.get(
        route(props.routeName, {
            ...route().params,
            page: nextPage.value,
        })
    )

    nextPage.value = getNextPageFromPaginatedData(data)

    items.value = [...items.value, ...data.data]

    isLoading.value = false
}

function observeElementScrolling(entries) {
    entries.forEach(async (entry) => {
        if (entry.isIntersecting && nextPage.value && !isLoading.value) {
            fetchNextPageData()
        }
    })
}

onMounted(() => {
    const observer = new IntersectionObserver(observeElementScrolling, {
        root: rootElement.value,
        rootMargin: '0px 0px 30% 0px',
    })

    observer.observe(targetElement.value)

    return () => {
        observer.disconnect()
    }
})
</script>

<template>
    <ul role="list" ref="rootElement">
        <slot :items="items"></slot>
        <div ref="targetElement"></div>
    </ul>
</template>
