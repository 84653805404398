<script setup>
import Button from '@app/components/ui/button/Button.vue'
import { route } from 'ziggy-js'
import Input from '@app/components/ui/form/Input.vue'
import { FunnelIcon } from '@heroicons/vue/20/solid'
import { Link, router } from '@inertiajs/vue3'
import { ref, watch } from 'vue'
import debounce from 'lodash/debounce'

const props = defineProps({
    routeName: {
        type: String,
        default: 'defaultValue',
    },
})

let search = ref(route().params.search)

watch(
    search,
    debounce(function (value) {
        const searchQuery = value.length > 0 ? value : null

        router.get(route(props.routeName, { search: searchQuery }), {}, { preserveState: true, replace: true })
    }, 200)
)
</script>

<template>
    <div class="mb-6 mt-4 flex items-end gap-6">
        <div class="flex flex-col">
            <Input id="search" v-model="search" type="text" :placeholder="$t('filters.input.search')" autocomplete="off" />
        </div>

        <Button color="white" :as="Link" :href="route(props.routeName)">
            <FunnelIcon class="h-5 w-5" />
            {{ $t('buttons.clear') }}</Button
        >
    </div>
</template>
