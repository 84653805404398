<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import TranslatableInput from '@app/components/ui/form/TranslatableInput.vue'
import { useLocale } from '@app/composables/useLocale'
import Settings from '@app/layouts/Settings.vue'
import { useForm } from '@inertiajs/vue3'
import { route } from 'ziggy-js'

const props = defineProps({
    region: Object,
})

const { initializeEmptyTranslatablePropertyModel } = useLocale()

const form = useForm({
    name: props.region?.name || initializeEmptyTranslatablePropertyModel(),
})

function submit() {
    if (props.region) {
        form.put(route('settings.region.update', props.region.id))
    } else {
        form.post(route('settings.region.store'))
    }
}
</script>

<template>
    <Settings group="general">
        <Form :form="form" @submit="submit" class="max-w-xl">
            <FormField prop="name" class="col-span-12">
                <TranslatableInput prop="name" :label="$t('attributes.name')" v-model="form.name" />
            </FormField>
            <FormActions>
                <Button :disabled="form.processing">{{ $t('buttons.save') }}</Button>
            </FormActions>
        </Form>
    </Settings>
</template>
