<script setup lang="ts">
import DeleteShift from '@app/components/shifts/shifts/actions/DeleteShift.vue'
import SetShiftMembers from '@app/components/shifts/shifts/actions/SetShiftMembers.vue'
import DetailCard from '@app/components/ui/card/DetailCard.vue'
import DetailCardLabel from '@app/components/ui/card/DetailCardLabel.vue'
import DetailCardTab from '@app/components/ui/card/DetailCardTab.vue'
import DetailCardTitle from '@app/components/ui/card/DetailCardTitle.vue'
import { useLocale } from '@app/composables/useLocale'
import type { Shift } from '@app/types/shifts'
import { getDateFromDateTime, getTimeFromDateTime } from '@app/utils/date'
import ShiftMembers from './ShiftMembers.vue'

defineProps<{
    shift: Shift
}>()

const { dbTranslate } = useLocale()
const emit = defineEmits(['close', 'reload'])
</script>

<template>
    <DetailCard :model="shift" :dismissable="true" @close="emit('close')" class="max-w-[26rem]">
        <div class="flex w-full flex-col items-center justify-between gap-4">
            <DetailCardTitle> Shift ID: {{ shift.id }}</DetailCardTitle>
            <div class="flex flex-col items-center gap-4">
                <DetailCardLabel :label="$t('attributes.work_type')"> {{ dbTranslate(shift.clientProject.work_type.name) }} </DetailCardLabel>
                <DetailCardLabel :label="$t('attributes.client_project')"> {{ shift.clientProject.name }} </DetailCardLabel>
                <DetailCardLabel :label="$t('attributes.location_type')"> {{ shift.locationType.name }} </DetailCardLabel>
                <DetailCardLabel :label="$t('attributes.date')"> {{ getDateFromDateTime(shift.start) }} </DetailCardLabel>
                <DetailCardLabel :label="$t('attributes.start')"> {{ getTimeFromDateTime(shift.start) }} </DetailCardLabel>
                <DetailCardLabel :label="$t('attributes.end')"> {{ getTimeFromDateTime(shift.end) }} </DetailCardLabel>
                <DetailCardLabel :label="$t('shifts.members')"> {{ shift.shiftMembers.length }} / {{ shift.slots }} </DetailCardLabel>
            </div>
        </div>
        <template #actions>
            <SetShiftMembers :shift="shift" />
            <DeleteShift :shift="shift" />
        </template>
        <template #tabs>
            <DetailCardTab tab="shift_members" :label="$t('shifts.members')">
                <ShiftMembers :shiftMembers="shift.shiftMembers" />
            </DetailCardTab>
            <DetailCardTab tab="activity" label="Activity" />
            <DetailCardTab tab="details" label="Details" />
        </template>
    </DetailCard>
</template>
