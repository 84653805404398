<script setup lang="ts">
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid'
import { addWeeks, subWeeks } from 'date-fns'

const model = defineModel<Date>({ required: true })
</script>

<template>
    <div class="relative flex flex-grow items-center rounded-md bg-white">
        <span class="isolate inline-flex flex-grow rounded-md">
            <button
                @click="model = subWeeks(model, 1)"
                type="button"
                class="relative inline-flex items-center rounded-l-md border border-zinc-300 bg-white px-2 py-2 text-sm font-medium text-zinc-700 hover:bg-zinc-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
            >
                <span class="sr-only">{{ $t('calendar.previous_week') }}</span>
                <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
            </button>
            <button
                @click="model = new Date()"
                type="button"
                class="relative -ml-px flex-grow border border-zinc-300 bg-white px-4 py-2 text-center text-sm font-medium text-zinc-700 hover:bg-zinc-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
            >
                {{ $t('calendar.today') }}
            </button>
            <button
                @click="model = addWeeks(model, 1)"
                type="button"
                class="relative -ml-px inline-flex items-center rounded-r-md border border-zinc-300 bg-white px-2 py-2 text-sm font-medium text-zinc-700 hover:bg-zinc-50 focus:z-10 focus:border-primary-500 focus:outline-none focus:ring-1 focus:ring-primary-500"
            >
                <span class="sr-only">{{ $t('calendar.next_week') }}</span>
                <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
            </button>
        </span>
    </div>
</template>
