<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import { computed, type PropType } from 'vue'

const props = defineProps({
    mode: {
        type: String as PropType<'create' | 'save'>,
        default: 'save',
    },
})

const label = computed(() => (props.mode === 'save' ? 'buttons.save' : 'actions.create'))
</script>

<template>
    <Button type="submit">
        <slot>{{ $t(label) }}</slot>
    </Button>
</template>
