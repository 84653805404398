import type { User } from '@app/types/shared'
import { router } from '@inertiajs/vue3'
import { onUnmounted } from 'vue'
import { route } from 'ziggy-js'

export function useLogout(user: User) {
    const channelName = `user.${user.private_channel_name}`
    const { Echo } = window

    function logout() {
        Echo.private(channelName).whisper('logout', { loggedOut: true })
        router.post(route('logout'))
    }

    function onLogout(callback: () => void) {
        Echo.private(channelName).listenForWhisper('logout', callback)
    }

    onUnmounted(() => {
        Echo.leave(channelName)
    })

    return {
        logout,
        onLogout,
    }
}
