<script setup>
import { useHumanReadableDate } from '@app/composables/date/useHumanReadableDate'
import { useLocale } from '@app/composables/useLocale'
import { format } from 'date-fns'
import { computed } from 'vue'

const { getHumanReadableDate } = useHumanReadableDate()
const { getLocale } = useLocale()

const props = defineProps({
    name: String,
    header: {
        type: String,
        required: true,
    },
    created_at: {
        type: String,
        required: true,
    },
})

const getHeaderName = computed(() => {
    if (props.name === '') {
        return null
    }

    return props.name ?? 'Robot'
})
</script>

<template>
    <p class="text-sm text-zinc-900">
        <span class="font-medium">{{ getHeaderName }}</span>
        {{ ' ' + header }}
        <span v-tooltip="format(created_at, 'dd MMMM yyyy HH:mm', { locale: getLocale() })" class="cursor-default whitespace-nowrap text-xs text-zinc-600">{{
            getHumanReadableDate(created_at)
        }}</span>
    </p>
</template>
