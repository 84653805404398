<script setup lang="ts">
import type { Lead } from '@app/types/recruitment'
import { inject } from 'vue'
import LeadClientInformationForm from './LeadClientInformationForm.vue'

defineProps<{ model: Lead }>()

const activeButton = inject('activeButton')
</script>

<template>
    <LeadClientInformationForm :lead="model" @close="activeButton = null" :title="$t('recruitment.leads.edit.description')" />
</template>
