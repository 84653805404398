<script setup lang="ts">
import { onMounted, toRefs, watch } from 'vue'
import { getMfsOnboardingFormUrl } from '@app/utils/mfs'
import type { Lead } from '@app/types/recruitment'
import Button from '@app/components/ui/button/Button.vue'
import { useForm } from '@inertiajs/vue3'
import { route } from 'ziggy-js'

const props = defineProps<{ lead: Lead }>()
const emit = defineEmits(['content:closed'])

const { lead } = toRefs(props)

// close the outcome when the funnel block has been changed
watch(lead, (oldLead, newLead) => {
    if (oldLead.funnel_block.slug !== newLead.funnel_block.slug) {
        emit('content:closed')
    }
})

const form = useForm({
    status: 'new',
    funnelBlock: 'onboarding',
})

onMounted(() => {
    // onboard lead immediately if the user already exists
    if (lead.value.user) {
        form.post(route('recruitment.lead.update-funnel-block-status', lead.value.id), {
            onSuccess() {
                emit('content:closed')
            },
        })
    }
})
</script>

<template>
    <div v-if="!lead.user" class="mx-auto max-w-sm">
        <div class="flex w-full flex-col items-center px-4 py-3">
            <Button as="a" :href="getMfsOnboardingFormUrl(lead.id)" target="_blank" class="my-2 w-80">
                {{ $t('recruitment.leads.outcomes.approve.link_to_mfs_create_user') }}
            </Button>
        </div>
    </div>
</template>
