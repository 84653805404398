<script setup lang="ts">
import FileDownload from '@app/components/ui/button/FileDownload.vue'
import DetailItem from '@app/components/ui/details/DetailItem.vue'
import { useLocale } from '@app/composables/useLocale'
import type { Contract } from '@app/types/contracts'
import { formatContractDuration, formatContractHours } from '@app/utils/contracts/contract'
import { getZonedDate } from '@app/utils/date'
import { PaperClipIcon } from '@heroicons/vue/20/solid'
import { computed } from 'vue'
import ContractTemplatePreview from '../../templates/ContractTemplatePreview.vue'

const props = defineProps<{
    contract: Contract
}>()

const { getLocale, translate } = useLocale()

const duration = computed(() => formatContractDuration(props.contract.duration_months, getLocale()))
const showFiles = computed(() => props.contract.status.slug === 'signed' || props.contract.contract_files.some((file) => !!file.generated_contract_path))
</script>

<template>
    <div class="mt-4 grid grid-cols-2 gap-4">
        <DetailItem :label="$t('attributes.job_title_id')">
            {{ translate(contract.job_title.name) }}
        </DetailItem>

        <DetailItem :label="$t('attributes.branch_id')">
            {{ contract.branch.name }}
        </DetailItem>

        <DetailItem :label="$t('attributes.contract_hour_type_id')">
            {{ translate(contract.contract_hour_type.name) }} ({{ formatContractHours(contract.min_hours, contract.max_hours) }})
        </DetailItem>

        <DetailItem :label="$t('attributes.duration_months')">
            {{ $t(duration) }}
        </DetailItem>

        <DetailItem :label="$t('attributes.start_date')">
            {{ getZonedDate(contract.start_date) }}
        </DetailItem>

        <DetailItem v-if="contract.end_date" :label="$t('attributes.end_date')">
            {{ getZonedDate(contract.end_date) }}
        </DetailItem>

        <DetailItem v-if="contract.phone_allowance_amount" :label="$t('attributes.phone_allowance_amount')">
            {{ contract.phone_allowance_amount.formatted }}
        </DetailItem>

        <DetailItem :label="$t('attributes.trial_period')">
            {{ contract.trial_period ? $t('form.labels.yes') : $t('form.labels.no') }}
        </DetailItem>

        <DetailItem :label="$t('attributes.retirement_provision')">
            {{ $t('contract.retirement_provisions.' + contract.retirement_provision) }}
        </DetailItem>

        <DetailItem :label="$t('attributes.business_entity')">
            {{ contract.business_entity }}
        </DetailItem>

        <DetailItem :label="$t('attributes.involves_visiting_other_locations')">
            {{ contract.involves_visiting_other_locations ? $t('form.labels.yes') : $t('form.labels.no') }}
        </DetailItem>

        <DetailItem :label="$t('attributes.gross_monthly_salary')">
            {{ contract.gross_monthly_salary.formatted }}
        </DetailItem>

        <DetailItem v-if="showFiles" :label="$t('attributes.files')">
            <div v-for="file in contract.contract_files" class="mt-1">
                <FileDownload v-if="file.generated_contract_path" :name="translate(file.name)" :path="file.generated_contract_path" />

                <ContractTemplatePreview v-else :id="file.id" v-slot="{ showPreview }">
                    <button class="group flex cursor-pointer gap-1" @click="showPreview">
                        <PaperClipIcon class="size-5 text-primary-600 group-hover:text-primary-800" />
                        <span class="text-sm font-normal leading-5 text-gray-500 group-hover:text-gray-700">{{ file.name }}</span>
                    </button>
                </ContractTemplatePreview>
            </div>
        </DetailItem>
    </div>
</template>
