<script lang="ts" setup>
import { StarIcon } from '@heroicons/vue/20/solid'
import { StarIcon as StarIconOutline } from '@heroicons/vue/24/outline'
import { ref, computed } from 'vue'

const props = defineProps<{
    editable: boolean
    modelValue: boolean
}>()

const emit = defineEmits<{
    (e: 'update:modelValue', value: boolean): void
}>()

const isHovered = ref(false)

function handleClick() {
    emit('update:modelValue', !props.modelValue)
}

const buttonHint = computed(() => {
    if (!props.editable) {
        return 'call.starred'
    }

    return props.modelValue ? 'call.actions.unstar' : 'call.actions.star'
})
</script>

<template>
    <button @mouseenter="isHovered = true" @mouseleave="isHovered = false" @click="handleClick" :disabled="!editable" :title="$t(buttonHint)">
        <StarIcon v-if="modelValue" class="h-5 w-5 text-yellow-400" />

        <StarIconOutline v-else-if="editable" class="h-5 w-5 transition-colors" :class="[isHovered ? 'text-gray-600' : 'text-gray-400']" />
    </button>
</template>
