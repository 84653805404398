<script setup lang="ts">
import ContentTabs from '@app/components/layout/ContentTabs.vue'
import { useNavigation } from '@app/composables/layout/useNavigation'
import { useSettingsTabs, type SettingsGroup } from '@app/composables/settings/useSettingsTabs'

const props = defineProps<{
    group: SettingsGroup
}>()

const { currentRoute } = useNavigation()
const { currentSettingsGroup } = useSettingsTabs(currentRoute, props.group)
</script>

<template>
    <ContentTabs :items="currentSettingsGroup" />
    <slot></slot>
</template>
