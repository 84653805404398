import { onUnmounted } from 'vue'

export function useChannel(channelName: string, isPrivate = false) {
    const { Echo } = window

    const channel = isPrivate ? Echo.private(channelName) : Echo.channel(channelName)
    const listeners: string[] = []

    function subscribe<T extends object>(event: string, callback: (payload: T) => void): void {
        listeners.push(event)

        channel.listen(event, callback)
    }

    onUnmounted(() => {
        listeners.forEach((listener) => {
            channel.stopListening(listener)
        })
    })

    return {
        subscribe,
    }
}
