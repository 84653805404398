<script setup lang="ts" generic="T = string | number">
import { Listbox, ListboxButton, ListboxOptions } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { computed, provide, ref } from 'vue'

const model = defineModel<T[]>()

const { disabled = false, border = true } = defineProps<{ disabled?: boolean; border?: boolean }>()

provide('dropdown:registry', ref(new Map()))
provide(
    'dropdown:selected',
    computed(() => model.value)
)
</script>

<template>
    <Listbox as="div" v-model="model" class="w-full" :disabled multiple>
        <div class="relative">
            <ListboxButton
                :class="[
                    border ? 'border border-zinc-300' : 'shadow-none',
                    'relative w-full cursor-default rounded-md py-2 pl-3 pr-10 text-left text-sm shadow-sm focus:border-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500',
                    disabled ? 'bg-gray-100' : 'bg-white',
                ]"
            >
                <slot name="trigger"></slot>
                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 sm:ml-3">
                    <ChevronDownIcon class="h-5 w-5 text-zinc-700" aria-hidden="true" />
                </span>
            </ListboxButton>

            <Transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                <ListboxOptions
                    class="absolute z-10 mt-1 max-h-56 w-full min-w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400 focus:outline-none sm:w-auto"
                    :unmount="false"
                >
                    <slot></slot>
                </ListboxOptions>
            </Transition>
        </div>
    </Listbox>
</template>
