<script setup lang="ts">
import { type ShallowRef, inject, type Component, computed } from 'vue'
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { ArrowUturnLeftIcon } from '@heroicons/vue/16/solid'
import type { Contract } from '@app/types/contracts'
import { useUserCan } from '@app/composables/useUserCan'
import ContractDeclineForm from '../ContractDeclineForm.vue'

const activeButton = inject<ShallowRef<Component>>('activeButton')

const props = defineProps<{
    contract: Contract
}>()

const { can } = useUserCan()

const show = computed(() => can('contract.decline') && props.contract.status.slug === 'signed')
</script>

<template>
    <RoundButton v-if="show" :label="$t('contract.actions.decline')" @click="activeButton = ContractDeclineForm">
        <ArrowUturnLeftIcon class="size-4" />
    </RoundButton>
</template>
