<script setup lang="ts" generic="T">
import { useForm } from '@inertiajs/vue3'
import type { Taskable } from '@app/types/task'

const props = defineProps<{
    location: string
    taskable: Taskable<T>
}>()

const form = useForm({
    type: props.taskable.type,
    taskable_id: props.taskable.taskable_id,
    taskable_type: props.taskable.taskable_type,
})

function completeTask() {
    form.post(props.location, {
        preserveScroll: true,
    })
}
</script>

<template>
    <button @click="completeTask" class="block px-4 py-2 text-sm text-gray-700">
        {{ $t('tasks.complete') }}
    </button>
</template>
