<script setup lang="ts">
import { trans } from 'laravel-vue-i18n'
import { computed, inject, type ComputedRef, type Ref } from 'vue'

const { placeholder } = defineProps<{ placeholder: string }>()

const selected = inject('dropdown:selected') as ComputedRef<(number | string)[]>
const registry = inject('dropdown:registry') as Ref<Map<number | string, string>>

const label = computed(() => {
    switch (selected.value.length) {
        case 0:
            return placeholder
        case 1:
            return registry.value.get(selected.value[0])
        default:
            return trans('form.dropdown.multiple', { count: selected.value.length })
    }
})
</script>

<template>
    <span class="block truncate">{{ label }}</span>
</template>
