<script setup lang="ts">
import { useModelSelect } from '@app/composables/useModelSelect'
import type { Branch, ClientProject, User } from '@app/types/shared'
import { translatedSelectPlaceholder } from '@app/utils/form'
import { useForm, usePage } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'
import CancelButton from '../ui/button/CancelButton.vue'
import SaveButton from '../ui/button/SaveButton.vue'
import Dropdown from '../ui/dropdown/Dropdown.vue'
import DropdownMulti from '../ui/dropdown/DropdownMulti.vue'
import DropdownOption from '../ui/dropdown/DropdownOption.vue'
import DropdownTrigger from '../ui/dropdown/DropdownTrigger.vue'
import Form from '../ui/form/Form.vue'
import FormActions from '../ui/form/FormActions.vue'
import FormField from '../ui/form/FormField.vue'
import FormSection from '../ui/form/FormSection.vue'

const { model } = defineProps<{ model: User }>()

const emit = defineEmits(['content:closed'])

const form = useForm({
    branch_id: model.branch_id,
    client_project_id: model.client_project_id,
    branches: model.branches.map((branch) => branch.id),
    client_projects: model.client_projects.map((project) => project.id),
})

const branches = computed(() => usePage().props.branches as Branch[])
const selectedMainBranch = useModelSelect(branches, model.branch_id)

const clientProjects = computed(() => usePage().props.clientProjects as ClientProject[])
const selectedMainClientProject = useModelSelect(clientProjects, model.client_project_id)

function submit() {
    form.post(route('user.working-range.store', model), {
        only: ['selectedUser'],
        onSuccess() {
            emit('content:closed')
        },
    })
}
</script>

<template>
    <Form :form="form" @submit="submit">
        <FormSection>
            <FormField prop="branch_id" :label="$t('user.attributes.branch_id')" class="col-span-6">
                <Dropdown
                    :label="translatedSelectPlaceholder('user.attributes.branch_id')"
                    :items="branches"
                    propertyName="name"
                    v-model="selectedMainBranch"
                    v-model:form="form.branch_id"
                />
            </FormField>

            <FormField prop="client_project_id" :label="$t('user.attributes.client_project_id')" class="col-span-6">
                <Dropdown
                    :label="translatedSelectPlaceholder('user.attributes.client_project_id')"
                    :items="clientProjects"
                    propertyName="name"
                    v-model="selectedMainClientProject"
                    v-model:form="form.client_project_id"
                />
            </FormField>

            <FormField prop="branches" :label="$t('user.attributes.branches')" class="col-span-6">
                <DropdownMulti v-model="form.branches">
                    <template #trigger>
                        <DropdownTrigger :placeholder="translatedSelectPlaceholder('user.attributes.branches')" />
                    </template>

                    <DropdownOption v-for="branch in branches" :value="branch.id" :label="branch.name" />
                </DropdownMulti>
            </FormField>

            <FormField prop="client_projects" :label="$t('user.attributes.client_projects')" class="col-span-6">
                <DropdownMulti v-model="form.client_projects">
                    <template #trigger>
                        <DropdownTrigger :placeholder="translatedSelectPlaceholder('user.attributes.client_projects')" />
                    </template>

                    <DropdownOption v-for="clientProject in clientProjects" :value="clientProject.id" :label="clientProject.name" />
                </DropdownMulti>
            </FormField>
        </FormSection>

        <FormActions>
            <CancelButton @click="$emit('content:closed')" />
            <SaveButton />
        </FormActions>
    </Form>
</template>
