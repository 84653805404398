<script setup lang="ts">
import Tag from '@app/components/ui/tag/Tag.vue'
import { useLocale } from '@app/composables/useLocale'
import type { ContractStatus } from '@app/types/contracts'

defineProps<{
    status: ContractStatus
}>()

const { dbTranslate } = useLocale()

function getColorByStatus(status: string) {
    switch (status) {
        case 'unsigned':
            return 'blue'
        case 'signed':
            return 'yellow'
        case 'active':
            return 'green'
        case 'annulled':
        case 'expired':
            return 'orange'
        case 'terminated':
            return 'red'
        case 'approved':
            return 'fuchsia'
        case 'renewed':
        case 'changed':
            return 'cyan'
        case 'cancelled':
            return 'red'
        default:
            return 'gray'
    }
}
</script>

<template>
    <Tag :color="getColorByStatus(status.slug)">{{ dbTranslate(status.name) }}</Tag>
</template>
