<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import UpdateLeadClientInformationForm from '@app/components/recruitment/leads/forms/UpdateLeadClientInformationForm.vue'
import { type ShallowRef, inject, type Component, computed } from 'vue'
import { PencilSquareIcon } from '@heroicons/vue/24/solid'
import { useUserCan } from '@app/composables/useUserCan'
import type { Lead } from '@app/types/recruitment'

const props = defineProps<{ lead: Lead }>()

const activeButton = inject<ShallowRef<Component>>('activeButton')
const { can } = useUserCan()

const show = computed(
    () =>
        can('recruitment.lead.update-client-information') && props.lead.funnel.slug === 'recruitment-selection' && props.lead.funnel_block.slug === 'interested'
)
</script>

<template>
    <RoundButton v-if="show" :label="$t('recruitment.leads.card.actions.edit')" @click="activeButton = UpdateLeadClientInformationForm">
        <PencilSquareIcon class="h-4 w-4" aria-hidden="true" />
    </RoundButton>
</template>
