import type { VisitOptions } from '@inertiajs/core'
import { router } from '@inertiajs/vue3'
import { route } from 'ziggy-js'

export function applyFilter(params = {}, options?: VisitOptions) {
    const currentRoute = route().current() ?? ''
    const routeParams = {
        ...route().params,
        ...params,
        page: null, // reset pagination when applying filters
    }

    router.get(
        route(currentRoute, routeParams),
        {},
        {
            preserveState: true,
            ...(options ?? {}),
        }
    )
}
