<script setup lang="ts">
import { useForm } from '@inertiajs/vue3'
import { inject } from 'vue'
import { route } from 'ziggy-js'
import Form from '../ui/form/Form.vue'
import FormField from '../ui/form/FormField.vue'
import Dropdown from '../ui/dropdown/Dropdown.vue'
import SaveButton from '../ui/button/SaveButton.vue'
import CancelButton from '../ui/button/CancelButton.vue'
import { useModelSelect } from '@app/composables/useModelSelect'
import type { Contract, ContractCancellationReason } from '@app/types/contracts'

const props = defineProps<{
    model: Contract
}>()

const cancellationReasons = inject('contract:cancellationReasons') as ContractCancellationReason[]

const selectedReason = useModelSelect(cancellationReasons)

const emit = defineEmits(['content:closed'])

const form = useForm({
    reason: null,
})

const handleClose = () => {
    form.reset()
    emit('content:closed')
}

const submitTerminationForm = () => {
    form.post(route('contract.cancel', props.model.id), {
        onSuccess: () => {
            handleClose()
        },
    })
}
</script>

<template>
    <Form :form="form" @submit="submitTerminationForm">
        <FormField prop="reason" class="col-span-12 mt-3" :label="$t('contract.cancellation.reason')">
            <Dropdown
                :items="cancellationReasons"
                propertyName="name"
                :label="$t('contract.cancellation.select_reason')"
                :item="selectedReason"
                :translateDb="true"
                v-model="selectedReason"
                v-model:form="form.reason"
            />
        </FormField>
        <div class="px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <SaveButton class="ml-2" />
            <CancelButton @click="handleClose()" />
        </div>
    </Form>
</template>
