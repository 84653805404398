<script setup lang="ts">
import ActivityFeedTemplate from '@app/components/ui/activityFeed/ActivityFeedTemplate.vue'
import ActivityFeedTemplateHeader from '@app/components/ui/activityFeed/ActivityFeedTemplateHeader.vue'
import ReadLessOrMoreButton from '@app/components/ui/button/ReadLessOrMoreButton.vue'
import type { ActivityFeed } from '@app/types/activity-feed'
import { EnvelopeIcon } from '@heroicons/vue/20/solid'
import { ref } from 'vue'

defineProps<{
    activity: ActivityFeed
}>()

const extend = ref(false)
</script>

<template>
    <ActivityFeedTemplate>
        <template #icon>
            <EnvelopeIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
        </template>
        <template #header>
            <ActivityFeedTemplateHeader :name="activity.causer_full_name" :header="$t('activities.email_message_sent')" :created_at="activity.created_at" />
        </template>
        <template #content>
            <div :class="extend ? 'text-xs' : 'line-clamp-4 text-xs'" class="whitespace-pre-wrap break-words">
                {{ activity.content }}
            </div>
            <ReadLessOrMoreButton :show="activity.content.trim().includes('\n')" @button:clicked="extend = !extend" />
        </template>
    </ActivityFeedTemplate>
</template>
