<script setup lang="ts">
import ContractForm from '@app/components/contracts/forms/ContractForm.vue'
import type { Contract, ContractModel, GeneratedFiles } from '@app/types/contracts'
import { useForm } from '@inertiajs/vue3'
import { addDays } from 'date-fns'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const emit = defineEmits(['content:closed'])
const props = defineProps<{ model: Contract & GeneratedFiles }>()

const form = useForm<ContractModel>({
    branch_id: props.model.branch_id,
    contract_hour_type_id: props.model.contract_hour_type_id,
    duration_months: props.model.max_renewal_duration === null ? null : Math.min(props.model.duration_months as number, props.model.max_renewal_duration),
    email: props.model.email,
    job_title_id: props.model.job_title.id,
    gross_monthly_salary: props.model.gross_monthly_salary.amount,
    min_hours: props.model.min_hours,
    max_hours: props.model.max_hours,
    sign_by: props.model.sign_by,
    start_date: addDays(new Date(props.model.end_date as string), 1),
    trial_period: props.model.trial_period,
    include_phone_allowance: !!props.model.phone_allowance_amount,
    phone_allowance_amount: props.model.phone_allowance_amount?.amount || null,
    retirement_provision: props.model.retirement_provision || 'appendix',
    involves_visiting_other_locations: props.model.involves_visiting_other_locations,
    business_entity: props.model.business_entity || '',
})

const readonly = computed(() => {
    let fields = ['start_date', 'email']

    if (props.model.duration_months === null) {
        fields.push('duration_months')
    }

    return fields
})
</script>

<template>
    <ContractForm
        :form
        :action="route('contract.renew', props.model.id)"
        method="post"
        @content:closed="$emit('content:closed')"
        :readonly="readonly"
        button-label="contract.renew"
    />
</template>
