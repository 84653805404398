<script setup lang="ts">
import LeadRecruiterAvailabilityDropdown from '@app/components/recruitment/leads/LeadRecruiterAvailabilityDropdown.vue'
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'
import FieldError from '@app/components/ui/form/FieldError.vue'
import { useLeadRecruitmentAppointment } from '@app/composables/recruitment/useLeadRecruitmentAppointment'
import type { Funnel, FunnelBlock, Lead, RecruitmentAvailability } from '@app/types/recruitment'
import { isNotEmptyArray } from '@app/utils/array'
import axios from 'axios'
import { ref, toRefs, watchEffect } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps<{
    lead: Lead
    funnel: Funnel
    funnelBlock: FunnelBlock
}>()

const model = defineModel()

const emit = defineEmits(['loaded'])

const { funnel, funnelBlock } = toRefs(props)

const loaded = ref(false)
const appointmentHosts = ref([])

async function loadRecruitmentAppointmentHosts(funnelSlug: string, funnelBlockSlug: string) {
    const { data } = await axios.get(
        route('api.v1.recruitment.appointment-host.index', {
            funnel: funnelSlug,
            funnel_block: funnelBlockSlug,
        })
    )

    appointmentHosts.value = data
    loaded.value = true

    emit('loaded')
}

watchEffect(() => {
    loadRecruitmentAppointmentHosts(funnel.value.slug, funnelBlock.value.slug)
})

const {
    isSalesAgentFunnel,
    isWorkshopFunnelBlock,
    missingLeadDetails,
    selectableBranches,
    projects,
    locations,
    selectedLocation,
    selectedBranch,
    selectedProject,
    selectableHosts,
    selectedHost,
    recruiterAvailabilities,
    selectedRecruiterAvailability,
} = useLeadRecruitmentAppointment(props.lead, funnel, funnelBlock, appointmentHosts)

const setSelectedRecruiterAvailability = (availability: RecruitmentAvailability) => {
    selectedRecruiterAvailability.value = availability
}

watchEffect(() => {
    model.value = selectedRecruiterAvailability.value?.id || null
})
</script>

<template>
    <div v-if="loaded" class="w-full">
        <FieldError v-if="!lead.email" :message="$t('errors.recruitment.lead.approve.missing_email')" class="my-2" />
        <FieldError v-if="!lead.phone_number" :message="$t('errors.recruitment.lead.approve.missing_phone')" class="my-2" />

        <template v-if="!missingLeadDetails">
            <Dropdown class="w-full" :items="selectableBranches" propertyName="name" v-model="selectedBranch" :translateDb="false" />

            <Dropdown v-if="isWorkshopFunnelBlock" class="mt-2 w-full" :items="projects" propertyName="name" v-model="selectedProject" :translateDb="false" />

            <Dropdown
                v-if="isWorkshopFunnelBlock && isSalesAgentFunnel"
                class="mt-2 w-full"
                :items="locations"
                propertyName="name"
                v-model="selectedLocation"
                :translateDb="false"
            />

            <Dropdown class="mt-2 w-full" :items="selectableHosts" propertyName="full_name" v-model="selectedHost" :translateDb="false" />

            <FieldError v-if="!isNotEmptyArray(selectableHosts)" :message="$t('errors.recruitment.lead.approve.no_host_available')" class="my-2" />

            <LeadRecruiterAvailabilityDropdown
                class="mt-2 w-full"
                v-if="isNotEmptyArray(recruiterAvailabilities)"
                :items="recruiterAvailabilities"
                :item="selectedRecruiterAvailability"
                @item:set="setSelectedRecruiterAvailability"
                :key="selectedRecruiterAvailability?.id"
            />
            <FieldError
                v-if="isNotEmptyArray(selectableHosts) && !isNotEmptyArray(recruiterAvailabilities)"
                :message="$t('errors.recruitment.lead.approve.no_availability')"
                class="my-2"
            />
        </template>
    </div>
</template>
