import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import parseISO from 'date-fns/parseISO'
import { useLocale } from '../useLocale'

export function useHumanReadableDate() {
    const { getLocale } = useLocale()

    function getHumanReadableDate(date: string) {
        return formatDistanceToNow(parseISO(date), {
            addSuffix: true,
            locale: getLocale(),
        })
    }

    return { getHumanReadableDate }
}
