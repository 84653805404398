<script setup lang="ts">
import { computed } from 'vue'
import { trans } from 'laravel-vue-i18n'
import { route } from 'ziggy-js'
import type { ResourceCollection } from '@app/types/shared'
import type { EmailTemplate } from '@app/types/email'
import DataTable from '@app/components/ui/table/DataTable.vue'
import Settings from '@app/layouts/Settings.vue'

defineProps<{
    emailTemplates: ResourceCollection<EmailTemplate>
}>()

const columns = computed(() => [
    {
        type: 'string',
        header: 'attributes.name',
        properties: { name: 'name' },
        sort: 'name',
    },
    {
        type: 'string',
        header: 'attributes.funnel_block',
        properties: { name: 'funnel_block' },
        sort: 'funnel_block',
    },
    {
        type: 'string',
        header: 'attributes.subject',
        properties: { name: 'subject' },
        sort: 'subject',
    },
    {
        type: 'string',
        header: 'attributes.template',
        properties: { name: 'content' },
    },
    {
        type: 'multi-line-date',
        header: 'attributes.created_at',
        properties: { name: 'created_at' },
        sort: 'created_at',
    },
    {
        type: 'link',
        header: 'actions.header',
        properties: {
            resolveLinkProps: (emailTemplate: EmailTemplate) => ({
                label: trans('actions.delete'),
                href: route('settings.template.email.destroy', emailTemplate.id),
                method: 'delete',
                data: { id: emailTemplate.id },
            }),
        },
    },
])
</script>

<template>
    <Settings group="templates">
        <div class="flex items-start gap-4 scrollbar-hide">
            <DataTable :items="emailTemplates" :columns="columns" />
        </div>
    </Settings>
</template>
