import { onMounted, ref, watch, type ComputedRef } from 'vue'

export function useGooglePlacesAutocomplete(inputSelector: string, countryCode: ComputedRef<string>, autocompleteOptions = {}) {
    const defaultAutocompleteOptions = {
        componentRestrictions: { country: countryCode.value.toLowerCase() },
        fields: ['name', 'address_components'],
        types: ['address'],
    }
    const placeResult = ref({
        city: '',
        postalCode: '',
        street: '',
        streetNumber: '',
        rawResult: null,
    })

    let autocomplete: any

    watch(countryCode, () => {
        if (autocomplete) {
            autocomplete.setComponentRestrictions({
                country: countryCode.value.toLowerCase(),
            })
        }
    })

    onMounted(async () => {
        const { Autocomplete } = await google.maps.importLibrary('places')

        autocomplete = new Autocomplete(document.querySelector(inputSelector), {
            ...defaultAutocompleteOptions,
            ...autocompleteOptions,
        })

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace()

            const newResult: typeof placeResult.value = {
                city: '',
                postalCode: '',
                street: '',
                streetNumber: '',
                rawResult: place,
            }

            place.address_components.forEach((item: any) => {
                const itemType = item.types[0]

                switch (itemType) {
                    case 'route':
                        newResult.street = item.long_name
                        break
                    case 'street_number':
                        newResult.streetNumber = item.long_name
                        break
                    case 'locality':
                        newResult.city = item.long_name
                        break
                    case 'postal_code_prefix':
                    case 'postal_code':
                        newResult.postalCode = item.long_name
                        break
                }
            })

            placeResult.value = newResult
        })
    })

    return {
        placeResult,
    }
}
