<script setup lang="ts">
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid'
import { ref } from 'vue'

defineProps({
    collapsible: {
        type: Boolean,
        default: true,
    },
})

const collapsed = ref(false)
</script>

<template>
    <div
        :class="!collapsed ? 'w-11/12 max-w-[25rem] md:w-[25rem] ' : 'min-w-52'"
        class="absolute bottom-6 right-0 z-10 transform overflow-visible rounded-bl-lg rounded-tl-lg bg-neutral-700 bg-opacity-90 px-4 py-4 text-left shadow-lg duration-150 md:bottom-auto md:top-2 md:px-6"
    >
        <div class="flex w-full">
            <button
                v-if="collapsible"
                @click="collapsed = !collapsed"
                class="absolute -left-2 top-4 z-10 mx-auto flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-full bg-neutral-700 bg-opacity-100"
            >
                <ChevronRightIcon v-show="!collapsed" class="h-4 w-4 text-zinc-50" aria-hidden="true" />
                <ChevronLeftIcon v-show="collapsed" class="h-4 w-4 text-zinc-50" aria-hidden="true" />
            </button>
            <slot :collapsed="collapsed" />
        </div>
    </div>
</template>
