<script lang="ts" setup>
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useNavigation } from '@app/composables/layout/useNavigation'
import { ArrowsPointingOutIcon } from '@heroicons/vue/20/solid'

const { redirectToLead } = useNavigation()

defineProps({
    lead: {
        type: Object,
        required: true,
    },
    label: {
        type: String,
        default: 'Open',
    },
})
</script>

<template>
    <RoundButton :label="$t(label)" @click="redirectToLead(lead)">
        <ArrowsPointingOutIcon class="h-4 w-4" aria-hidden="true" />
    </RoundButton>
</template>
