<script setup lang="ts">
import { useTwoFactorRecoveryCodes } from '@app/composables/fortify/useTwoFactorRecoveryCodes'
import type { Authenticatable } from '@app/types/shared'
import { onMounted } from 'vue'
import Button from '../ui/button/Button.vue'
import CancelButton from '../ui/button/CancelButton.vue'
import FormActions from '../ui/form/FormActions.vue'

const props = defineProps<{ model: Authenticatable }>()
defineEmits(['content:closed'])

const { recoveryCodes, processing, showRecoveryCodes, regenerateRecoveryCodes } = useTwoFactorRecoveryCodes(props.model)

onMounted(() => {
    showRecoveryCodes()
})
</script>

<template>
    <div v-if="recoveryCodes.length > 0">
        <div class="mt-4 grid gap-1 rounded-lg bg-zinc-100 px-4 py-4 font-mono text-sm">
            <div v-for="code in recoveryCodes" :key="code">
                {{ code }}
            </div>
        </div>

        <FormActions>
            <CancelButton @click="$emit('content:closed')" />
            <Button type="button" @click="regenerateRecoveryCodes" :disabled="processing">{{ $t('user.actions.regenerate') }}</Button>
        </FormActions>
    </div>
</template>
