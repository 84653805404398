<script setup lang="ts">
import Form from '@app/components/settings/products/Form.vue'
import Button from '@app/components/ui/button/Button.vue'
import Card from '@app/components/ui/card/Card.vue'
import DataTable from '@app/components/ui/table/DataTable.vue'
import DropdownMultiFilter from '@app/components/ui/table/filters/DropdownMultiFilter.vue'
import ResetFilterButton from '@app/components/ui/table/filters/ResetFilterButton.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'
import { useDetailCard } from '@app/composables/useDetailCard'
import { useLocale } from '@app/composables/useLocale'
import { useUserCan } from '@app/composables/useUserCan'
import type { ClientProject, Product, ProductType, ResourceCollection } from '@app/types/shared'
import { applyFilter } from '@app/utils/filter'
import { formatProductPrice } from '@app/utils/orders/product'
import { formatDuration } from 'date-fns'
import { computed, toRefs, type PropType } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps({
    products: {
        type: Object as PropType<ResourceCollection<Product>>,
        required: true,
    },
    productTypes: {
        type: Array as PropType<ProductType[]>,
        required: true,
    },
    clientProjects: {
        type: Array as PropType<ClientProject[]>,
        required: true,
    },
    selectedProduct: {
        type: [Object, null] as PropType<Product | null>,
        required: true,
    },
    businessModels: {
        type: Array as PropType<{ id: string; name: string }[]>,
        required: true,
    },
    paymentFrequencies: {
        type: Array as PropType<{ id: string; name: string }[]>,
        required: true,
    },
    create: {
        type: Boolean,
        required: true,
    },
})

const { selectedProduct, create } = toRefs(props)

const { dbTranslate } = useLocale()
const { can } = useUserCan()
const { toggleCard } = useDetailCard(selectedProduct, 'selectedProduct')

const params = route().params

const columns = computed(() => [
    {
        name: 'name',
        header: 'attributes.name',
    },
    {
        name: 'product_type',
        header: 'attributes.product_type',
    },
    {
        name: 'client_project',
        header: 'attributes.client_project',
    },
    {
        name: 'business_model',
        header: 'attributes.business_model',
    },
    {
        name: 'price',
        header: 'attributes.price',
    },
])

function showCreateForm() {
    applyFilter({
        selected: null,
        create: true,
    })
}

function closeForm() {
    applyFilter({
        selected: null,
        create: null,
    })
}
</script>

<template>
    <div v-if="can('products.product.store')" class="mb-6 flex justify-end">
        <Button @click="showCreateForm">
            {{ $t('actions.create') }}
        </Button>
    </div>
    <div class="flex items-start gap-4 scrollbar-hide">
        <DataTable
            class="flex w-full flex-col rounded-lg border border-gray-100 transition-all duration-500"
            :class="[selectedProduct || create ? 'hidden lg:inline-block' : 'inline-block']"
            :items="products"
            :columns="columns"
            only="products"
            :clickable="true"
            :activeId="selectedProduct?.id"
            @row:clicked="(item) => toggleCard(item.id)"
        >
            <template #filter>
                <FilterSection :enable-search="true" :text-search-param="params.name">
                    <DropdownMultiFilter :label="$t('attributes.product_type')" :items="productTypes" slug="product_type" />

                    <DropdownMultiFilter :label="$t('attributes.client_project')" :items="clientProjects" slug="client_project" />

                    <ResetFilterButton :href="route('settings.products.product.index')" />
                </FilterSection>
            </template>

            <template #column.name="{ item }">
                <p class="max-w-40 whitespace-normal">
                    {{ item.name }}
                </p>
            </template>

            <template #column.product_type="{ item }">
                {{ dbTranslate(item.product_type.name) }}
            </template>

            <template #column.client_project="{ item }">
                {{ item.client_project.name }}
            </template>

            <template #column.business_model="{ item }">
                {{ $t('orders.business_models.' + item.business_model) }}
            </template>

            <template #column.price="{ item }">
                {{ formatProductPrice(item) }}

                <p class="mt-1 text-sm text-gray-500">
                    {{ $t('orders.payment_frequencies.' + item.payment_frequency) }}

                    <span v-if="item.subscription_months">/ {{ formatDuration({ months: item.subscription_months }) }}</span>
                </p>
            </template>
        </DataTable>

        <Transition name="slide-fade" mode="out-in">
            <Card v-if="selectedProduct || create" class="md:min-w-[29rem] lg:w-2/3" @close="closeForm" :dismissable="true">
                <Form :product="selectedProduct" :key="selectedProduct?.id" @submit="closeForm" />
            </Card>
        </Transition>
    </div>
</template>
