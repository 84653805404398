<script setup lang="ts">
import { useNavigation } from '@app/composables/layout/useNavigation'
import { computed } from 'vue'
import { route } from 'ziggy-js'
import ContentTabs from '../layout/ContentTabs.vue'

const { currentRoute } = useNavigation()

const tabs = computed(() => [
    {
        name: 'user.tabs.active',
        href: route('user.index'),
        active: currentRoute.value === 'user.index' && !route().params['scope'],
        permission: 'user.index',
    },
    {
        name: 'user.tabs.inactive',
        href: route('user.index', { scope: 'inactive' }),
        active: currentRoute.value === 'user.index' && route().params['scope'] === 'inactive',
        permission: 'user.index.inactive',
    },
])
</script>

<template>
    <ContentTabs :items="tabs" />
</template>
