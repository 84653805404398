import type { PageProps } from '@app/types/inertia'
import { router, usePage } from '@inertiajs/vue3'
import { loadLanguageAsync } from 'laravel-vue-i18n'
import { computed, onMounted } from 'vue'
import { route } from 'ziggy-js'

export function useLanguageSwitcher() {
    const page = usePage<PageProps>()

    const user = computed(() => page.props.user)
    const { userLanguage, languages } = page.props

    onMounted(() => {
        if (userLanguage?.slug) {
            loadLanguageAsync(userLanguage.slug)
        }
    })

    function switchLanguage() {
        let currentKey = user.value.language_id - 1
        let maxKey = languages.length - 1
        let newKey = currentKey + 1 > maxKey ? 0 : currentKey + 1
        let newLanguage = languages[newKey]

        router.put(
            route('user.language.store'),
            {
                language_id: newLanguage.id,
            },
            {
                onSuccess: () => {
                    loadLanguageAsync(newLanguage.slug)
                },
            }
        )
    }

    return { switchLanguage }
}
