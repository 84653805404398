<script setup>
import Button from '@app/components/ui/button/Button.vue'
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import { useModelSelect } from '@app/composables/useModelSelect'
import { translatedSelectPlaceholder } from '@app/utils/form'
import { useForm } from '@inertiajs/vue3'
import { computed, ref } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps({
    funnels: Object,
    funnel_blocks: Object,
    client_projects: Object,
    location_types: Object,
    users: Object,
})

const selectedFunnel = ref()
const selectedFunnelBlock = ref()
const selectedClientProject = ref()
const selectedLocationType = ref()
const selectedUser = ref()

const selectableFunnels = computed(() => props.funnels.filter((funnel) => funnel.branches.length > 0))

const branches = computed(() => selectedFunnel.value?.branches || [])
const selectedBranch = useModelSelect(branches, null)

const form = useForm({
    branch_id: null,
    funnel_id: null,
    funnel_block_id: null,
    client_project_id: null,
    location_type_id: null,
    user_id: null,
})

const handleSubmit = () => {
    form.post(route('settings.recruitment.appointment-host.store'))
}
</script>

<template>
    <Form :form="form" @submit="handleSubmit" class="max-w-xl">
        <FormSection>
            <FormField class="col-span-12" :required="true" prop="funnel_id" :label="$t('attributes.funnel')">
                <Dropdown
                    :items="selectableFunnels"
                    propertyName="name"
                    :label="translatedSelectPlaceholder('attributes.funnel')"
                    v-model="selectedFunnel"
                    v-model:form="form.funnel_id"
                />
            </FormField>

            <FormField v-if="selectedFunnel" class="col-span-12" :required="true" prop="branch_id" :label="$t('attributes.branch_id')">
                <Dropdown
                    :items="branches"
                    propertyName="name"
                    :translateDb="false"
                    :label="translatedSelectPlaceholder('attributes.branch_id')"
                    v-model="selectedBranch"
                    v-model:form="form.branch_id"
                />
            </FormField>

            <FormField class="col-span-12" :required="true" prop="funnel_block_id" :label="$t('attributes.funnel_block')">
                <Dropdown
                    :items="funnel_blocks"
                    propertyName="name"
                    :label="translatedSelectPlaceholder('attributes.funnel_block')"
                    v-model="selectedFunnelBlock"
                    v-model:form="form.funnel_block_id"
                />
            </FormField>

            <FormField
                v-if="selectedFunnelBlock?.slug === 'workshop'"
                class="col-span-12"
                :required="true"
                prop="client_project_id"
                :label="$t('attributes.client_project')"
            >
                <Dropdown
                    :items="client_projects"
                    propertyName="name"
                    :label="translatedSelectPlaceholder('attributes.client_project')"
                    v-model="selectedClientProject"
                    v-model:form="form.client_project_id"
                    :translateDb="false"
                />
            </FormField>

            <FormField
                v-if="selectedFunnelBlock?.slug === 'workshop' && selectedFunnel?.slug === 'sales-agent'"
                class="col-span-12"
                :required="true"
                prop="location_type_id"
                :label="$t('attributes.location_type')"
            >
                <Dropdown
                    :items="location_types"
                    propertyName="name"
                    :label="translatedSelectPlaceholder('attributes.location_type')"
                    v-model="selectedLocationType"
                    v-model:form="form.location_type_id"
                    :translateDb="false"
                />
            </FormField>
            <FormField class="col-span-12" :required="true" prop="user_id" :label="$t('attributes.user')">
                <Dropdown
                    :items="users"
                    propertyName="full_name"
                    :translateDb="false"
                    :label="translatedSelectPlaceholder('attributes.user')"
                    v-model="selectedUser"
                    v-model:form="form.user_id"
                />
            </FormField>
        </FormSection>
        <FormActions>
            <Button :disabled="form.processing">{{ $t('buttons.save') }}</Button>
        </FormActions>
    </Form>
</template>
