<script setup lang="ts">
import { useUserCan } from '@app/composables/useUserCan'
import Settings from '@app/layouts/Settings.vue'
import type { Channel } from '@app/types/recruitment'
import type { ResourceCollection } from '@app/types/shared'
import { route } from 'ziggy-js'
import { Link } from '@inertiajs/vue3'
import DataTable from '@app/components/ui/table/DataTable.vue'
import { trans } from 'laravel-vue-i18n'
import { usePage } from '@inertiajs/vue3'
import Search from '@app/components/ui/table/Search.vue'
import Button from '@app/components/ui/button/Button.vue'
import type { PageProps } from '@app/types/inertia'

const { can } = useUserCan()
const user = usePage<PageProps>().props.user
defineProps<{ channels: ResourceCollection<Channel> }>()

const columns = [
    {
        type: 'string',
        header: 'ID',
        properties: { name: 'id' },
        show: user.is_super_admin,
    },
    {
        type: 'string',
        header: 'attributes.name',
        properties: { name: 'name', translate: true },
        sort: 'name',
    },
    {
        type: 'string',
        header: 'attributes.type',
        properties: { name: 'name', relation: 'type', translate: true },
        sort: 'type',
    },
    {
        type: 'string',
        header: 'attributes.call_attempts',
        properties: { name: 'call_attempts' },
    },
    {
        type: 'link',
        header: 'actions.header',
        show: can('recruitment.channel.store'),
        properties: {
            resolveLinkProps: (channel: Channel) => ({
                label: trans('actions.update'),
                href: route('settings.recruitment.channel.edit', {
                    slug: channel.slug,
                }),
            }),
        },
    },
]
</script>

<template>
    <Settings group="recruitment">
        <div class="mb-6 md:flex md:items-center md:justify-between">
            <div class="-ml-2 -mt-2 flex flex-wrap items-baseline">
                <Search routeName="settings.recruitment.channel.index" />
            </div>
            <div class="my-3 flex place-items-center">
                <Button v-if="can('auth.channel.store')" :as="Link" :href="route('settings.recruitment.channel.create')" color="primary" size="lg">
                    {{ $t('actions.create') }}
                </Button>
            </div>
        </div>
        <div class="mb-6 md:flex md:items-center md:justify-between">
            <DataTable class="flex w-full flex-col rounded-lg border border-gray-100 transition-all duration-500" :items="channels" :columns="columns" />
        </div>
    </Settings>
</template>
