<script setup lang="ts">
import type { Contract } from '@app/types/contracts'
import { useForm } from '@inertiajs/vue3'
import { route } from 'ziggy-js'
import CancelButton from '../ui/button/CancelButton.vue'
import SaveButton from '../ui/button/SaveButton.vue'
import Form from '../ui/form/Form.vue'
import FormField from '../ui/form/FormField.vue'
import FormTextArea from '../ui/form/FormTextArea.vue'

const props = defineProps<{
    model: Contract
}>()

const emit = defineEmits(['content:closed'])

const form = useForm({
    note: '',
})

const handleClose = () => {
    form.reset()
    emit('content:closed')
}

const declineSignedContract = () => {
    form.post(route('contract.decline', props.model.id), {
        onSuccess: () => {
            handleClose()
        },
    })
}
</script>

<template>
    <Form :form="form" @submit="declineSignedContract">
        <FormField prop="note" class="col-span-12 mt-3" :label="$t('note.description')">
            <FormTextArea id="note" v-model="form.note" :rows="6" />
        </FormField>

        <div class="sm:flex sm:flex-row-reverse">
            <SaveButton class="ml-2" />
            <CancelButton @click="handleClose()" />
        </div>
    </Form>
</template>
