<script setup lang="ts">
import Auth from '@app/layouts/Auth.vue'
import AuthErrorBag from '@app/components/auth/AuthErrorBag.vue'
import Input from '@app/components/ui/form/Input.vue'
import { Link, useForm } from '@inertiajs/vue3'
import FormField from '@app/components/ui/form/FormField.vue'
import Form from '@app/components/ui/form/Form.vue'
import Button from '@app/components/ui/button/Button.vue'
import { route } from 'ziggy-js'

defineOptions({
    layout: Auth,
})

const loginForm = useForm({
    email: '',
    password: '',
})

function login() {
    loginForm.post(route('login'), {
        onFinish() {
            loginForm.reset('password')
        },
    })
}
</script>

<template>
    <Form :form="loginForm" @submit="login" class="space-y-4">
        <FormField prop="email" :label="$t('attributes.email')" :show-error="false">
            <Input id="email" v-model="loginForm.email" autocomplete="email" />
        </FormField>

        <FormField prop="password" :label="$t('attributes.password')" :show-error="false">
            <Input id="password" v-model="loginForm.password" type="password" autocomplete="current-password" />
        </FormField>
        <AuthErrorBag class="mb-4" />
        <div class="flex items-center justify-end">
            <Link :href="route('password.request')" class="text-xs text-zinc-600 underline hover:text-zinc-900">
                {{ $t('login.link_forgot') }}
            </Link>

            <Button class="ml-4 uppercase tracking-wider" color="primary" :disabled="loginForm.processing">{{ $t('login.button_login') }}</Button>
        </div>
    </Form>
</template>
