import type { PageProps } from '@app/types/inertia'
import { usePage } from '@inertiajs/vue3'
import { isEmpty } from 'lodash'
import { computed } from 'vue'

export function useStoredFilters() {
    const storedFilters = computed(() => usePage<PageProps>().props.userStoredFilters)

    function getStoredFilters(name: string, initialFilters = {}) {
        const filter = storedFilters.value?.[name] ?? {}

        return !isEmpty(filter) ? filter : initialFilters
    }

    return {
        getStoredFilters,
    }
}
