<script setup lang="ts">
import { ref } from 'vue'
import { useCurrencyInput, ValueScaling } from 'vue-currency-input'
const { currency = 'EUR' } = defineProps<{ currency?: string }>()

defineModel<number | string | null>()

defineEmits(['change'])

const { inputRef } = useCurrencyInput({
    valueScaling: ValueScaling.precision,
    currency: currency,
})

const isHidden = ref(true)
</script>

<template>
    <div class="">
        <button
            :class="{ hidden: !isHidden }"
            @click="isHidden = !isHidden"
            class="w-full rounded-lg border border-slate-200 bg-white py-2 text-lg font-semibold text-black hover:bg-teal-500 hover:text-white"
        >
            Overig
        </button>
        <div
            :class="{ hidden: isHidden }"
            class="w-full rounded-lg border border-slate-200 bg-white py-2 text-center text-black hover:bg-teal-500 hover:text-white"
        >
            <input ref="inputRef" class="input-euro h-7 w-24 rounded-lg border-none border-transparent bg-transparent font-semibold focus:ring-0 lg:text-lg" />
        </div>
    </div>
</template>
