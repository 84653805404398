<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useUserCan } from '@app/composables/useUserCan'
import type { ShiftAvailability } from '@app/types/shifts'
import { TrashIcon } from '@heroicons/vue/24/solid'
import { computed } from 'vue'

defineProps<{ availability: ShiftAvailability }>()

const { can } = useUserCan()

const show = computed(() => can('shifts.shift.destroy'))
</script>

<template>
    <RoundButton v-if="show" :label="$t('actions.delete')">
        <TrashIcon class="size-5" aria-hidden="true" />
    </RoundButton>
</template>
