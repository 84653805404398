<script setup lang="ts">
import CancelButton from '@app/components/ui/button/CancelButton.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'
import Checkbox from '@app/components/ui/form/Checkbox.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import { useModelSelect } from '@app/composables/useModelSelect'
import type { Funnel, Lead } from '@app/types/recruitment'
import { useForm, usePage } from '@inertiajs/vue3'
import { computed, ref, watch } from 'vue'
import { route } from 'ziggy-js'
import RecruitmentAvailabilitySelector from '../forms/RecruitmentAvailabilitySelector.vue'

const props = defineProps<{ lead: Lead }>()

const emit = defineEmits(['content:closed'])

const funnels = usePage().props.funnels as Funnel[]

const currentLeadFunnel = ref(props.lead.funnel)

const isInWorkshopFunnelBlock = props.lead.funnel_block.slug === 'workshop'

const selectableFunnels = computed(() => {
    if (isInWorkshopFunnelBlock) {
        return funnels
    }

    return funnels.filter((funnel) => funnel.slug !== currentLeadFunnel.value.slug)
})

const selectedFunnel = useModelSelect(selectableFunnels)
const selectedReason = useModelSelect(props.lead.decline_reasons)

const selectedFunnelBlock = computed(() => {
    if (isInWorkshopFunnelBlock && selectedFunnel.value?.id === currentLeadFunnel.value.id) {
        return props.lead.funnel_block
    }

    // if we change the funnel, we should move the lead to the introduction phase again
    const introductionFunnelBlock = selectedFunnel.value?.blocks.find((funnelBlock) => funnelBlock.slug === 'introduction')

    return introductionFunnelBlock || selectedFunnel.value?.blocks[0]
})

const form = useForm({
    decline_reason_id: selectedReason.value?.id,
    funnel_id: null,
    suitable_for_other_work: false,
    recruitment_availability_id: null,
})

watch(
    () => form.suitable_for_other_work,
    (suitable) => {
        if (!suitable) {
            form.funnel_id = null
            form.recruitment_availability_id = null
        }
    }
)

function declineLead() {
    form.post(route('recruitment.lead.decline', props.lead.id), {
        preserveScroll: true,
        onSuccess: () => emit('content:closed'),
    })
}
</script>

<template>
    <div class="mx-auto max-w-sm">
        <Form :form="form" class="flex w-full flex-col items-center space-y-2 py-2 sm:px-4" @submit="declineLead" id="decline_form">
            <FormField prop="decline_reason_id" class="w-full">
                <Dropdown
                    :items="lead.decline_reasons"
                    v-model="selectedReason"
                    propertyName="name"
                    :label="$t('recruitment.leads.outcomes.decline.select_reason')"
                    v-model:form="form.decline_reason_id"
                    class="w-full"
                    id="decline_reason"
                />
            </FormField>

            <FormField v-if="lead.funnel_block.slug !== 'external'" prop="qualified_for_other_work" class="w-full">
                <Checkbox v-model="form.suitable_for_other_work">
                    {{ $t('recruitment.leads.outcomes.decline.qualified_label') }}
                </Checkbox>
            </FormField>

            <template v-if="form.suitable_for_other_work">
                <FormField prop="funnel_id" class="w-full">
                    <Dropdown
                        class="w-full"
                        :items="selectableFunnels"
                        propertyName="name"
                        :label="$t('recruitment.leads.outcomes.decline.select_funnel')"
                        v-model="selectedFunnel"
                        v-model:form="form.funnel_id"
                    />
                </FormField>

                <RecruitmentAvailabilitySelector
                    v-if="selectedFunnel && selectedFunnelBlock?.has_appointments"
                    class="w-full"
                    :lead="lead"
                    :funnel="selectedFunnel"
                    :funnelBlock="selectedFunnelBlock"
                    v-model="form.recruitment_availability_id"
                />
            </template>

            <FormActions class="w-full">
                <CancelButton @click="$emit('content:closed')" />
                <SaveButton :disabled="form.processing" id="decline_save_button" />
            </FormActions>
        </Form>
    </div>
</template>
