<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import Input from '@app/components/ui/form/Input.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import { useForm } from '@inertiajs/vue3'
import Radio from '@app/components/ui/form/Radio.vue'
import { route } from 'ziggy-js'
import { router } from '@inertiajs/vue3'
import type { LocationType } from '@app/types/shared'
import FormActions from '@app/components/ui/form/FormActions.vue'

const props = defineProps<{
    location_type?: LocationType
}>()

const form = useForm({
    name: props.location_type?.name || null,
    slug: props.location_type?.slug || null,
    use_valuation: props.location_type?.use_valuation ?? null,
})

function submit() {
    if (props.location_type) {
        form.put(route('settings.location-type.update', props.location_type.id))
    } else {
        form.post(route('settings.location-type.store'))
    }
}
</script>

<template>
    <Form :form="form" @submit="submit" class="max-w-xl">
        <FormSection>
            <FormField prop="name" :label="$t('attributes.name')" class="col-span-12">
                <Input id="name" v-model="form.name" />
            </FormField>

            <FormField prop="slug" label="Slug" class="col-span-12">
                <Input id="slug" v-model="form.slug" />
            </FormField>

            <FormField prop="use_valuation" :label="$t('attributes.use_valuation')" class="col-span-12 flex items-center gap-2" :inline="true">
                <Radio v-model="form.use_valuation" :value="true">{{ $t('form.labels.yes') }} </Radio>
                <Radio v-model="form.use_valuation" :value="false">{{ $t('form.labels.no') }} </Radio>
            </FormField>
        </FormSection>

        <FormActions>
            <Button color="black" type="button" @click="router.get(route('settings.location-type.index'))">{{ $t('buttons.back') }}</Button>
            <Button :disabled="form.processing">{{ $t('buttons.save') }}</Button>
        </FormActions>
    </Form>
</template>
