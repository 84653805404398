<script setup lang="ts">
import { computed } from 'vue'
import type { Step } from '@app/types/step'
import type { TranslatableProperty } from '@app/types/shared'
import { useLocale } from '@app/composables/useLocale'

const props = defineProps<{
    steps: Array<Step>
    current_step: Step
}>()

const { dbTranslate } = useLocale()
const currentStepKey = computed(() =>
    props.steps.findIndex(function (step: Step) {
        return step.id === props.current_step.id
    })
)

const getStepColor = (step: Step) => (step.id <= props.current_step.id ? 'text-primary-600' : 'text-zinc-400')

const getStepName = (name: string | TranslatableProperty) => {
    return typeof name === 'object' ? dbTranslate(name) : name
}

const style = computed(() => {
    let amountOfSteps = props.steps.length
    let percentage = (currentStepKey.value / amountOfSteps) * 100

    return 'width:' + (percentage === 0 ? 3 : percentage) + '%'
})
</script>

<template>
    <div class="w-full sm:w-auto" aria-hidden="true">
        <div class="overflow-hidden rounded-full bg-zinc-100">
            <div class="h-2 rounded-full bg-primary-600 transition-width duration-500" :style="style" />
        </div>
        <ol role="list" class="mt-4 hidden w-full justify-between gap-4 text-xs font-medium sm:flex 2xl:gap-12">
            <li v-for="(step, key) in steps" :key="step.id" :class="[key === 0 ? 'text-left' : 'text-center', getStepColor(step)]">
                {{ getStepName(step.name) }}
            </li>
            <li class="text-right text-zinc-400">
                {{ $t('steps.finished') }}
            </li>
        </ol>
        <div class="relative mx-auto w-full pt-4 text-center text-sm font-medium text-primary-600 sm:hidden">
            {{ getStepName(current_step.name) }}
        </div>
    </div>
</template>
