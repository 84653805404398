/**
 * Default filter params.
 */

export const callFilters = {
    call_status: ['completed', 'missed', 'rejected', 'unable-to-connect'],
}

export const callAppointmentFilters = {
    date: 'this_week',
}

export const recruitmentAppointmentFilters = {
    date: 'this_week',
}
