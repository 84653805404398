import type { Sluggable } from '@app/types/shared'
import { ref, toValue, watch, type MaybeRef } from 'vue'

interface Model {
    id: number
}

function isSluggable(model: Model | Sluggable): model is Sluggable {
    return typeof (model as Sluggable).slug !== 'undefined'
}

function isModel(model: Model | Sluggable): model is Model {
    return typeof (model as Model).id !== 'undefined'
}

export function useModelSelect<T extends Model | Sluggable>(models: MaybeRef<T[]>, initialValue?: number | string | null, by: 'id' | 'slug' = 'id') {
    const selected = ref<T>()

    function findByIdOrSlug(model: T) {
        if (by === 'slug' && isSluggable(model)) {
            return model.slug === initialValue
        }

        if (by === 'id' && isModel(model)) {
            return model.id === initialValue
        }

        return false
    }

    if (initialValue) {
        const normalizedModels = toValue(models)

        selected.value = normalizedModels.find(findByIdOrSlug)
    }

    watch(models, () => {
        const normalizedModels = toValue(models)

        if (!selected.value || !normalizedModels.find((model) => model[by] === selected.value[by])) {
            if (initialValue) {
                selected.value = normalizedModels.find(findByIdOrSlug)
            } else if (initialValue !== null) {
                selected.value = normalizedModels[0]
            }
        }
    })

    return selected
}
