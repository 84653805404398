<script setup lang="ts">
import { ref } from 'vue'

const isHidden = ref(true)
</script>

<template>
    <div>
        <label @click="isHidden = !isHidden" style="cursor: pointer">
            <div class="my-2 mr-2 inline-block rounded-full bg-rose-600 px-2.5 font-bold text-white">i</div>
            <div class="inline-block text-rose-600">Meer informatie</div>
        </label>

        <div :class="{ hidden: isHidden }">
            <div class="rounded-lg border border-slate-300 bg-white p-4 leading-tight">
                <slot />
            </div>
        </div>
    </div>
</template>
