<script setup lang="ts">
import Badge from '@app/components/ui/badge/Badge.vue'
import Button from '@app/components/ui/button/Button.vue'
import DetailCard from '@app/components/ui/card/DetailCard.vue'
import DetailCardTab from '@app/components/ui/card/DetailCardTab.vue'
import DataTable from '@app/components/ui/table/DataTable.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'
import InputFilter from '@app/components/ui/table/filters/InputFilter.vue'
import ResetFilterButton from '@app/components/ui/table/filters/ResetFilterButton.vue'
import ClientUserDetails from '@app/components/users/ClientUserDetails.vue'
import UserRoleModal from '@app/components/users/UserRoleModal.vue'
import EditRoles from '@app/components/users/actions/EditRoles.vue'
import LoginAsUser from '@app/components/users/actions/LoginAsUser.vue'
import RecoveryCodes from '@app/components/users/actions/RecoveryCodes.vue'
import { useDetailCard } from '@app/composables/useDetailCard'
import { useUserCan } from '@app/composables/useUserCan'
import type { ClientUser, ResourceCollection, Role } from '@app/types/shared'
import { UserIcon } from '@heroicons/vue/24/solid'
import { router } from '@inertiajs/vue3'
import { ref, toRefs, type PropType } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps({
    users: {
        type: Object as PropType<ResourceCollection<ClientUser>>,
        required: true,
    },
    roles: {
        type: Array as PropType<Role[]>,
        required: true,
    },
    selectedUser: Object as PropType<ClientUser>,
})

const { can, loggedInUser } = useUserCan()

const { selectedUser } = toRefs(props)
const isRoleUpdateOpen = ref(false)

const { toggleCard } = useDetailCard(selectedUser, 'selectedUser')

const params = route().params

const columns = [
    {
        type: 'string',
        header: 'ID',
        properties: { name: 'id' },
        show: loggedInUser.is_super_admin,
    },
    {
        type: 'multi-line-and-avatar',
        header: 'attributes.name',
        properties: {
            avatarSrc: 'profile_photo_url',
            avatarName: 'full_name',
            primaryName: 'full_name',
            secondaryName: 'email',
        },
    },
    {
        type: 'string',
        header: 'attributes.client',
        properties: { relation: 'client', name: 'name' },
    },
]

function navigateToCreateForm() {
    router.get(route('user.client.create'))
}
</script>

<template>
    <div class="mb-4 md:flex md:items-center md:justify-between">
        <div class="flex place-items-center">
            <Button v-if="can('user.client.store')" @click="navigateToCreateForm"> {{ $t('actions.create') }} </Button>
        </div>
    </div>
    <div class="scrollbar-hiden flex items-start gap-4">
        <DataTable
            class="flex w-full flex-col rounded-lg border border-gray-100 transition-all duration-500"
            :class="[!selectedUser ? 'inline-block' : 'hidden lg:inline-block']"
            :items="users"
            :columns="columns"
            @row:clicked="(user) => toggleCard(user.id)"
            :activeId="selectedUser?.id"
            only="users"
            :clickable="true"
        >
            <template #filter>
                <FilterSection>
                    <InputFilter :item="params.name" only="users" class="w-1/2" />
                    <ResetFilterButton :href="route('user.client.index')" />
                </FilterSection>
            </template>
        </DataTable>

        <Transition name="slide-fade" mode="out-in">
            <DetailCard v-if="selectedUser" :model="selectedUser" class="md:min-w-[29rem] lg:w-2/3" :dismissable="true" @close="toggleCard(selectedUser.id)">
                <div class="flex flex-col items-center gap-4">
                    <span class="w-full text-center text-lg font-medium leading-6 text-gray-900">{{ selectedUser.full_name }}</span>
                    <Badge color="blue" size="lg" :round="false" :label="selectedUser.client.name" />
                    <Badge :label="$tChoice('user.roles', selectedUser.roles?.length || 0)" />
                </div>

                <template #actions>
                    <EditRoles :user="selectedUser" @click="isRoleUpdateOpen = true" />
                    <RecoveryCodes :user="selectedUser" />
                    <LoginAsUser :user="selectedUser" />
                </template>

                <template #tabs>
                    <DetailCardTab tab="details" label="Details">
                        <template #icon>
                            <UserIcon />
                        </template>
                        <ClientUserDetails :user="selectedUser" />
                    </DetailCardTab>
                </template>
            </DetailCard>
        </Transition>
    </div>
    <UserRoleModal
        v-if="selectedUser && isRoleUpdateOpen"
        :user="selectedUser"
        :roles="roles"
        :show="isRoleUpdateOpen"
        @roles:close="isRoleUpdateOpen = false"
    />
</template>
