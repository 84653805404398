<script setup lang="ts" generic="T extends { id: number }">
import type { ResourceCollection } from '@app/types/shared'
import { computed, provide, toRef, type PropType } from 'vue'
import NoResults from './NoResults.vue'
import Pagination from './Pagination.vue'
import TableHeader from './TableHeader.vue'
import Column from './column/Column.vue'

type ColumnType = {
    name?: string
    type?: string
    header: string
    show?: boolean
    properties?: Record<string, unknown>
    sort?: string
}

let props = defineProps({
    items: {
        type: Object as PropType<ResourceCollection<T>>,
        required: true,
    },
    columns: {
        type: Array as PropType<ColumnType[]>,
        required: true,
    },
    activeId: Number,
    only: String,
    clickable: Boolean,
})

const rows = toRef(props, 'items')

const visibleColumns = computed(() => props.columns.filter((column) => column.show !== false))

function hasColumn(columnName: string) {
    return visibleColumns.value.some((column) => {
        const maybeName = column.name || column.properties?.name

        return maybeName === columnName
    })
}

provide('datatable:items', rows)
provide('datatable:only', props.only)

const emit = defineEmits(['row:clicked', 'action:clicked'])
const rowClick = (item: T) => {
    if (!props.clickable) return

    emit('row:clicked', item)
}
</script>

<template>
    <div class="flex w-full flex-col rounded-lg border border-gray-100 pt-2 transition-all duration-500">
        <slot name="filter" :hasColumn="hasColumn"></slot>

        <div class="flex w-full flex-col">
            <div class="inline-block min-w-full overflow-x-auto align-middle scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400">
                <table v-if="items.data.length" class="min-w-full divide-y divide-gray-300">
                    <thead class="bg-gray-50">
                        <tr>
                            <TableHeader
                                v-for="(column, index) in visibleColumns"
                                :text="$t(column.header)"
                                :key="column.name || index"
                                :arrayNumber="index"
                                :sort="column.sort"
                                :only="only"
                            />
                        </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white">
                        <tr
                            v-for="(item, index) in items.data"
                            :class="{
                                'bg-secondary-100 hover:bg-secondary-100': item.id === activeId,
                                'hover:cursor-pointer': clickable,
                            }"
                            class="transition-color duration-50 ease-in-out hover:bg-gray-100"
                            :key="index"
                            @click="rowClick(item)"
                            :id="`row-${item.id}`"
                        >
                            <td
                                v-for="(column, index) in visibleColumns"
                                :key="column.name || index"
                                :class="['whitespace-nowrap text-sm', index === 0 ? 'py-4 pl-4 pr-4 sm:pl-6' : 'px-3 py-4 pr-6']"
                            >
                                <Column v-if="column.type" :column="column" :item="item" @action:clicked="actionClick(item)" />

                                <slot v-else-if="column.name" :name="`column.${column.name}`" :item="item"></slot>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <NoResults v-else />
                <Pagination :links="items.links" :only="[only]" />
            </div>
        </div>
    </div>
</template>
