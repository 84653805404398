<script setup>
import Tag from '@app/components/ui/tag/Tag.vue'
import { useColor } from '@app/composables/useColor'

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    name: {
        type: String,
        required: true,
    },
    colorPicker: {
        type: String,
        required: true,
    },
    colorProperty: {
        type: String,
        required: true,
    },
    translate: {
        type: Boolean,
        default: false,
    },
    colorRelation: {
        type: String,
    },
})

const { getFunnelBlockStatusColor } = useColor()

const labelColor = () => {
    switch (props.colorPicker) {
        case 'getFunnelBlockStatusColor':
            return getFunnelBlockStatusColor(props.colorRelation ? props.item[props.colorRelation][props.colorProperty] : props.item[props.colorProperty])
    }
}
</script>

<template>
    <Tag :color="labelColor()">{{ translate ? item[name] : item[name] }}</Tag>
</template>
