import { computed, type Ref } from 'vue'
import { useNavigation } from '../layout/useNavigation'

export function useLeadAppointmentTabs(currentRoute: Ref<string>) {
    const { getLink } = useNavigation()
    return computed(() => [
        {
            name: 'navigation.recruitment.appointment.calls',
            href: getLink('recruitment-appointment-call'),
            active: currentRoute.value === 'recruitment.appointment.call.index',
            permission: 'recruitment.call-appointment.index',
        },
        {
            name: 'navigation.recruitment.appointment.recruitment',
            href: getLink('recruitment-appointment-recruitment'),
            active: currentRoute.value === 'recruitment.appointment.recruitment.index',
            permission: 'recruitment.recruitment-appointment.index',
        },
    ])
}
