<script lang="ts" setup>
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { CalendarIcon } from '@heroicons/vue/20/solid'
import { useUserCan } from '@app/composables/useUserCan'
import { type ShallowRef, inject, type Component, computed } from 'vue'
import RescheduleRecruitmentAppointment from '../forms/RescheduleRecruitmentAppointment.vue'

const activeButton = inject<ShallowRef<Component>>('activeButton')
const { can } = useUserCan()

const props = defineProps({
    lead: {
        type: Object,
        required: true,
    },
})

const showManageRecruitmentAppointment = computed(
    () => can('recruitment.recruitment-appointment.store') && props.lead.is_contactable && props.lead.funnel_block.has_appointments
)
</script>

<template>
    <RoundButton
        v-if="showManageRecruitmentAppointment"
        :label="$t('recruitment.ongoing_appointment')"
        @click="activeButton = RescheduleRecruitmentAppointment"
    >
        <CalendarIcon class="h-4 w-4" aria-hidden="true" />
    </RoundButton>
</template>
