<script setup lang="ts">
import { Head, useForm } from '@inertiajs/vue3'
import Auth from '@app/layouts/Auth.vue'
import Form from '@app/components/ui/form/Form.vue'
import { route } from 'ziggy-js'
import FormField from '@app/components/ui/form/FormField.vue'
import Input from '@app/components/ui/form/Input.vue'
import Button from '@app/components/ui/button/Button.vue'

defineOptions({
    layout: Auth,
})

defineProps<{ status?: string }>()

const form = useForm({
    email: '',
})

function requestResetPasswordLink() {
    form.post(route('password.email'))
}
</script>

<template>
    <Head title="Forgot Password" />

    <Form :form="form" @submit="requestResetPasswordLink">
        <div class="mb-4 text-sm text-gray-600">
            {{ $t('auth.forgot_password.content') }}
        </div>

        <div v-if="status" class="mb-4 text-sm font-medium text-green-600">
            {{ status }}
        </div>

        <FormField prop="email" :label="$t('attributes.email')">
            <Input id="email" v-model="form.email" autocomplete="true" autofocus />
        </FormField>

        <div class="mt-4 flex items-center justify-end">
            <Button type="black" :disabled="form.processing">
                {{ $t('auth.forgot_password.button') }}
            </Button>
        </div>
    </Form>
</template>
