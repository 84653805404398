<script setup>
import { useNavigation } from '@app/composables/layout/useNavigation'
import { useLanguageSwitcher } from '@app/composables/useLanguageSwitcher'
import { useLocale } from '@app/composables/useLocale'
import { useLogout } from '@app/composables/useLogout'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { Link, usePage } from '@inertiajs/vue3'
import { computed, inject } from 'vue'
import { route } from 'ziggy-js'
import SideBarNavLink from './SideBarNavLink.vue'

const page = usePage()
const { dbTranslate } = useLocale()
const { switchLanguage } = useLanguageSwitcher()

const props = defineProps({
    user: Object,
})

const { logout } = useLogout(props.user)

const { sideBarNavigation } = useNavigation()
const mobileMenuOpen = inject('mobileMenuOpen')
const sideBarOpen = inject('sideBarOpen')

const closeSidebar = () => {
    mobileMenuOpen.value = false
    sideBarOpen.value = false
}

const userLanguage = computed(() => page.props.userLanguage)
</script>

<template>
    <div :class="[sideBarOpen ? 'w-60 px-4' : 'px-2', 'hidden h-full flex-col overflow-y-auto bg-fonky-black shadow scrollbar-hide md:flex']">
        <div class="relative hidden h-16 flex-none items-center justify-between transition-all md:flex">
            <Transition name="fastfade" mode="out-in">
                <div v-if="!sideBarOpen" class="mx-auto flex">
                    <img class="h-8 w-auto" src="@assets/img/flame-logo-white.svg" alt="Fonky" />
                </div>
                <div v-else class="flex">
                    <img class="h-8 w-auto" src="@assets/img/full-logo-white.svg" alt="Fonky" />
                </div>
            </Transition>
        </div>
        <div class="flex h-full flex-col py-6">
            <div class="h-full w-full space-y-3 transition-all duration-1000 ease-in-out" key="sidebarnavigation">
                <SideBarNavLink v-for="navLink in sideBarNavigation" :item="navLink" :showName="sideBarOpen" />
            </div>
        </div>
    </div>

    <!-- Mobile menu -->
    <TransitionRoot as="template" :show="mobileMenuOpen">
        <Dialog as="div" class="relative z-20 md:hidden" @close="mobileMenuOpen = false">
            <TransitionChild
                as="template"
                enter="transition-opacity ease-linear duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </TransitionChild>

            <div class="fixed inset-0 z-40 flex">
                <TransitionChild
                    as="template"
                    enter="transition-all ease-in-out duration-300 transform"
                    enter-from="-translate-x-full"
                    enter-to="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leave-from="translate-x-0"
                    leave-to="-translate-x-full"
                >
                    <DialogPanel class="relative flex w-full max-w-xs flex-1 flex-col bg-fonky-black pb-4 pt-5">
                        <TransitionChild
                            as="template"
                            enter="ease-in-out duration-300"
                            enter-from="opacity-0"
                            enter-to="opacity-100"
                            leave="ease-in-out duration-300"
                            leave-from="opacity-100"
                            leave-to="opacity-0"
                        >
                            <div class="absolute right-0 top-1 -mr-14 p-1">
                                <button
                                    type="button"
                                    class="flex h-12 w-12 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-white"
                                    @click="mobileMenuOpen = false"
                                >
                                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                                    <span class="sr-only">Close sidebar</span>
                                </button>
                            </div>
                        </TransitionChild>
                        <div class="flex flex-shrink-0 items-center pl-4 md:hidden">
                            <img class="h-8 w-auto" src="@assets/img/full-logo-white.svg" alt="Fonky" />
                        </div>
                        <div class="mt-5 h-0 flex-1 overflow-y-auto">
                            <nav class="flex flex-col">
                                <div class="space-y-1 px-2 py-4">
                                    <SideBarNavLink v-for="navLink in sideBarNavigation" :item="navLink" :showName="true" />
                                </div>
                            </nav>
                            <div class="w-full border-t border-zinc-700">
                                <span class="sr-only">Divider</span>
                            </div>
                            <div class="space-y-1 px-2 py-4">
                                <div class="flex items-center justify-between px-4 py-2">
                                    <Link :href="route('user.profile.show')" @click="closeSidebar()" class="flex items-center">
                                        <div class="rounded-full focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                                            <img class="inline-block h-9 w-9 rounded-full" :src="user.profile_photo_url" :alt="user.full_name" />
                                        </div>
                                        <div class="ml-3 text-left">
                                            <p class="text-sm font-medium leading-5 text-gray-200">
                                                {{ user.full_name }}
                                            </p>
                                            <p v-if="user.job_title" class="text-xs font-normal leading-4 text-gray-300">
                                                {{ dbTranslate(user.job_title.name) }}
                                            </p>
                                        </div>
                                    </Link>
                                </div>

                                <Link
                                    class="block w-full rounded-md px-4 py-2 text-left text-sm text-gray-50 hover:bg-neutral-900 hover:text-white"
                                    :href="route('user.tasks.index', { status: 'todo' })"
                                >
                                    {{ $t('tasks.name') }}
                                </Link>

                                <form @submit.prevent="switchLanguage">
                                    <button class="block w-full rounded-md px-4 py-2 text-left text-sm text-gray-50 hover:bg-neutral-900 hover:text-white">
                                        {{ $t('navigation.account_management.language') }}:
                                        {{ dbTranslate(userLanguage?.name) }}
                                    </button>
                                </form>

                                <form @submit.prevent="logout">
                                    <button class="block w-full rounded-md px-4 py-2 text-left text-sm text-gray-50 hover:bg-neutral-900 hover:text-white">
                                        {{ $t('navigation.account_management.logout') }}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </DialogPanel>
                </TransitionChild>
                <div class="w-14 flex-shrink-0" aria-hidden="true">
                    <!-- Dummy element to force sidebar to shrink to fit close icon -->
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
