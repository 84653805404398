<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useActiveButton } from '@app/composables/useDetailCard'
import { useUserCan } from '@app/composables/useUserCan'
import type { Contract } from '@app/types/contracts'
import { ArrowPathIcon } from '@heroicons/vue/24/solid'
import { computed } from 'vue'
import ContractRenewForm from '../forms/ContractRenewForm.vue'

const props = defineProps<{ contract: Contract }>()

const { can } = useUserCan()
const { toggle } = useActiveButton()

const show = computed(() => can('contract.store') && props.contract.can_be_renewed)
</script>

<template>
    <RoundButton v-if="show" :label="$t('contract.actions.renew')" @click="toggle(ContractRenewForm)">
        <ArrowPathIcon class="size-4" aria-hidden="true" />
    </RoundButton>
</template>
