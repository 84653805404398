<script setup lang="ts">
import { useCurrencyInput, ValueScaling } from 'vue-currency-input'
import Input from './Input.vue'

const { currency = 'EUR' } = defineProps<{ currency?: string }>()

defineModel<number | string | null>()

defineEmits(['change'])

const { inputRef } = useCurrencyInput({
    valueScaling: ValueScaling.precision,
    currency: currency,
    locale: 'nl',
})
</script>

<template>
    <Input ref="inputRef" />
</template>
