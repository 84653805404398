<script setup>
import DropdownMultiFilter from './DropdownMultiFilter.vue'

let props = defineProps({
    label: String,
})

const items = ['today', 'yesterday', 'day_before_yesterday', 'this_week', 'last_week', 'this_month', 'last_month']

const filters = items.map((slug) => ({
    name: `filters.date.${slug}`,
    slug,
}))
</script>

<template>
    <DropdownMultiFilter :items="filters" :label="label" :show-label="true" filterAll="filters.date.all" />
</template>
