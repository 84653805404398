<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { computed, inject, type Component, type ShallowRef } from 'vue'
import { ChatBubbleLeftRightIcon } from '@heroicons/vue/24/solid'
import { useUserCan } from '@app/composables/useUserCan'
import WhatsappMessageSendForm from '@app/components/whatsapp/WhatsappMessageSendForm.vue'
import type { Lead } from '@app/types/recruitment'
import { isNotEmptyArray } from '@app/utils/array'

const activeButton = inject<ShallowRef<Component>>('activeButton')
const { can } = useUserCan()

const props = defineProps<{
    lead: Lead
}>()

const isShown = computed(() => {
    return (
        can('recruitment.whatsapp.send') &&
        !props.lead.is_status_final &&
        (isNotEmptyArray(props.lead.whatsapp_templates) || props.lead.can_receive_custom_whatsapp)
    )
})

const isDisabled = computed(() => !props.lead.phone_number)
</script>

<template>
    <RoundButton v-if="isShown" :disabled="isDisabled" :label="$t('recruitment.leads.card.actions.whatsapp')" @click="activeButton = WhatsappMessageSendForm">
        <ChatBubbleLeftRightIcon class="h-4 w-4" aria-hidden="true" />
    </RoundButton>
</template>
