<script setup lang="ts">
import type { Contract } from '@app/types/contracts'
import { getZonedDate } from '@app/utils/date'
import { trans } from 'laravel-vue-i18n'
import ContractStatusLabel from './ContractStatusLabel.vue'

const props = defineProps<{
    contract: Contract
}>()

function terminatedAtLabel(terminatedAt: string) {
    const date = new Date(terminatedAt)

    if (props.contract.termination_reason_id) {
        return trans('contract.terminated_at', { date: getZonedDate(date) })
    }

    return trans('contract.changed_at', { date: getZonedDate(date) })
}
</script>

<template>
    <div>
        <ContractStatusLabel :status="contract.status" />
        <div v-if="contract.terminated_at" class="mt-1 text-xs leading-6 text-gray-500">{{ terminatedAtLabel(contract.terminated_at) }}</div>
    </div>
</template>
