<script setup lang="ts">
import { computed } from 'vue'
import { trans } from 'laravel-vue-i18n'
import DataTable from '@app/components/ui/table/DataTable.vue'
import { route } from 'ziggy-js'
import type { ResourceCollection } from '@app/types/shared'
import type { WhatsappTemplate } from '@app/types/whatsapp'
import Settings from '@app/layouts/Settings.vue'

defineProps<{
    whatsappTemplates: ResourceCollection<WhatsappTemplate>
}>()

const columns = computed(() => [
    {
        type: 'string',
        header: 'attributes.name',
        properties: { name: 'name' },
        sort: 'name',
    },
    {
        type: 'string',
        header: 'attributes.funnel',
        properties: { name: 'funnel' },
    },
    {
        type: 'string',
        header: 'attributes.funnel_block',
        properties: { name: 'funnel_block' },
    },
    {
        type: 'string',
        header: 'whatsapp.twilio_template_sid',
        properties: { name: 'twilio_template_sid' },
        sort: 'twilio_template_sid',
    },
    {
        type: 'multi-line-date',
        header: 'attributes.created_at',
        properties: { name: 'created_at' },
        sort: 'created_at',
    },
    {
        type: 'link',
        header: 'actions.header',
        properties: {
            resolveLinkProps: (whatsappTemplate: WhatsappTemplate) => ({
                label: trans('actions.delete'),
                href: route('settings.template.whatsapp.destroy', whatsappTemplate.id),
                method: 'delete',
                data: { id: whatsappTemplate.id },
            }),
        },
    },
])
</script>

<template>
    <Settings group="templates">
        <div class="flex items-start gap-4 scrollbar-hide">
            <DataTable :items="whatsappTemplates" :columns="columns" />
        </div>
    </Settings>
</template>
