<script lang="ts" setup generic="T extends object">
import { InformationCircleIcon } from '@heroicons/vue/20/solid'
import type { InertiaForm } from '@inertiajs/vue3'
import { inject } from 'vue'
import FieldError from './FieldError.vue'
import Label from './Label.vue'

withDefaults(
    defineProps<{
        label?: string
        prop: string
        inline?: boolean
        required?: boolean
        showError?: boolean
        hint?: string
    }>(),
    {
        required: false,
        showError: true,
        inline: false,
    }
)

const form = inject<InertiaForm<T>>('form')

if (!form) {
    throw new Error('FormField should be within the Form component.')
}
</script>

<template>
    <div>
        <Label v-if="label" :for="prop" class="flex gap-1" :class="{ 'mb-2': !inline }">
            {{ label }} {{ required ? '*' : '' }}
            <InformationCircleIcon v-if="hint" class="size-5" v-tooltip="hint" />
        </Label>
        <slot></slot>
        <FieldError v-if="showError" :message="form.errors[prop]" class="mt-2" />
    </div>
</template>
