export async function convertPathToDataURL(path: string | null) {
    if (path === null || path.length === 0) {
        return null
    }

    if (!path.startsWith('/') && !path.startsWith('http')) {
        path = '/' + path
    }

    const blob = await (await fetch(path)).blob()

    return new Promise<string>((resolve) => {
        const reader = new FileReader()

        reader.readAsDataURL(blob)

        reader.onload = () => {
            resolve(reader.result as string)
        }
    })
}

export async function dataURLToFile(dataUrl: string | null, filename: string) {
    if (!dataUrl?.startsWith('data:')) {
        return dataUrl
    }

    const blob = await (await fetch(dataUrl)).blob()

    return new File([blob], filename)
}
