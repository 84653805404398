<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import Input from '@app/components/ui/form/Input.vue'
import Settings from '@app/layouts/Settings.vue'
import type { Branch, Country, Region } from '@app/types/shared'
import { ucfirst } from '@app/utils/transform'
import { useForm } from '@inertiajs/vue3'
import { trans } from 'laravel-vue-i18n'
import { computed, ref } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps<{
    branch?: Branch
    regions: Region[]
    countries: Country[]
}>()

const selectedRegion = ref(props.branch?.region)
const selectedCountry = ref(props.branch?.country)

const form = useForm({
    name: props.branch?.name || null,
    external_name: props.branch?.external_name || null,
    region_id: props.branch?.region_id || null,
    email_address: props.branch?.email_address || null,
    phone_number: props.branch?.phone_number || null,
    address: props.branch?.address || null,
    postal_code: props.branch?.postal_code || null,
    city: props.branch?.city || null,
    country_id: props.branch?.country_id || null,
})

function submit() {
    if (props.branch) {
        form.put(route('settings.branch.update', props.branch.id))
    } else {
        form.post(route('settings.branch.store'))
    }
}

const formTitle = computed(() =>
    props.branch ? trans('attributes.branch_id') + ' ' + props.branch.name : trans('actions.create') + ' ' + ucfirst(trans('attributes.branch_id'))
)
</script>

<template>
    <Settings group="general">
        <Form :form="form" @submit="submit" class="max-w-4xl">
            <FormSection :title="formTitle">
                <FormField prop="name" :label="$t('attributes.name')" class="col-span-12 sm:col-span-6">
                    <Input id="name" v-model="form.name" autocomplete="off" />
                </FormField>
                <FormField prop="external_name" :label="$t('attributes.external_name')" class="col-span-12 sm:col-span-6">
                    <Input id="external_name" v-model="form.external_name" autocomplete="off" />
                </FormField>
                <FormField prop="region_id" :label="$t('attributes.region')" class="col-span-12 sm:col-span-6">
                    <Dropdown :items="regions" label="Select region" propertyName="name" v-model="selectedRegion" v-model:form="form.region_id" />
                </FormField>

                <FormField prop="email_address" :label="$t('attributes.email')" class="col-span-12 sm:col-span-6">
                    <Input id="email_address" v-model="form.email_address" autocomplete="off" />
                </FormField>

                <FormField prop="phone_number" :label="$t('attributes.phone_number')" class="col-span-12 sm:col-span-6">
                    <Input id="phone_number" v-model="form.phone_number" autocomplete="off" />
                </FormField>

                <FormField prop="postal_code" :label="$t('attributes.postal_code')" class="col-span-12 sm:col-span-4">
                    <Input id="postal_code" v-model="form.postal_code" autocomplete="off" />
                </FormField>

                <FormField prop="address" :label="$t('attributes.address')" class="col-span-12 sm:col-span-8">
                    <Input id="address" v-model="form.address" autocomplete="off" />
                </FormField>

                <FormField prop="city" :label="$t('attributes.place_of_residence')" class="col-span-12 sm:col-span-6">
                    <Input id="city" v-model="form.city" autocomplete="off" />
                </FormField>

                <FormField prop="country_id" :label="$t('attributes.country')" class="col-span-12 sm:col-span-6">
                    <Dropdown :items="countries" propertyName="name" label="Select country" v-model="selectedCountry" v-model:form="form.country_id" />
                </FormField>
            </FormSection>

            <FormActions>
                <Button :disabled="form.processing">{{ $t('buttons.save') }}</Button>
            </FormActions>
        </Form>
    </Settings>
</template>
