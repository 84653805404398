<script setup>
import { inject } from 'vue'
import OpenSidebarIcon from '@app/components/icons/OpenSidebarIcon.vue'
import CollapseSidebarIcon from '@app/components/icons/CollapseSidebarIcon.vue'

const sideBarOpen = inject('sideBarOpen')
</script>

<template>
    <div class="hidden cursor-pointer items-center focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 md:flex" title="Toggle sidebar">
        <span class="sr-only">Open sidebar</span>
        <OpenSidebarIcon
            v-if="!sideBarOpen"
            @click="sideBarOpen = !sideBarOpen"
            key="arrowRight"
            class="text-gray-400 transition-all duration-150 ease-in-out hover:text-gray-500"
            aria-hidden="true"
        />
        <CollapseSidebarIcon
            v-else
            @click="sideBarOpen = !sideBarOpen"
            key="arrowLeft"
            class="text-gray-400 transition-all duration-150 ease-in-out hover:text-gray-500"
            aria-hidden="true"
        />
    </div>
</template>
