<script setup>
import Filters from '@app/components/recruitment/appointmentHosts/Filters.vue'
import Button from '@app/components/ui/button/Button.vue'
import DataTable from '@app/components/ui/table/DataTable.vue'
import Tag from '@app/components/ui/tag/Tag.vue'
import { useColor } from '@app/composables/useColor'
import { useLocale } from '@app/composables/useLocale'
import { useUserCan } from '@app/composables/useUserCan'
import Settings from '@app/layouts/Settings.vue'
import { Link } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps({
    branches: Object,
    funnels: Object,
    funnel_blocks: Object,
    client_projects: Object,
    location_types: Object,
    recruitment_appointment_hosts: {
        type: Object,
        required: true,
    },
    user: Object,
})

const { can } = useUserCan()
const { getFunnelColor, getFunnelBlockColor } = useColor()
const { dbTranslate } = useLocale()

const columns = computed(() => [
    {
        name: 'branch',
        header: 'attributes.branch_id',
    },
    {
        name: 'funnel',
        header: 'attributes.funnel',
    },
    {
        name: 'funnel_block',
        header: 'attributes.funnel_block',
    },
    {
        name: 'client_project',
        header: 'attributes.client_project',
    },
    {
        name: 'location_type',
        header: 'attributes.location_type',
    },
    {
        name: 'user',
        header: 'attributes.full_name',
    },
    {
        type: 'multi-line-date',
        header: 'attributes.created_at',
        properties: { name: 'created_at' },
        sort: 'created_at',
    },
    {
        name: 'actions',
        header: 'actions.header',
        show: can('recruitment.appointment-host.destroy'),
    },
])
</script>

<template>
    <Settings group="recruitment">
        <div class="mb-4 md:flex md:items-center md:justify-between">
            <div class="flex place-items-center">
                <Button v-if="can('recruitment.appointment-host.store')" :as="Link" :href="route('settings.recruitment.appointment-host.create')">
                    {{ $t('actions.create') }}
                </Button>
            </div>
        </div>
        <div class="flex items-start gap-4 scrollbar-hide">
            <DataTable :items="recruitment_appointment_hosts" :columns="columns">
                <template #filter>
                    <Filters
                        class="transition-all duration-500"
                        :branches="branches"
                        :funnels="funnels"
                        :funnel_blocks="funnel_blocks"
                        :client_projects="client_projects"
                        :location_types="location_types"
                    />
                </template>

                <template v-slot:column.branch="{ item }">
                    {{ item.branch.name }}
                </template>

                <template v-slot:column.funnel="{ item }">
                    <Tag :color="getFunnelColor(item.funnel.slug)">{{ dbTranslate(item.funnel.name) }}</Tag>
                </template>

                <template v-slot:column.funnel_block="{ item }">
                    <Tag :color="getFunnelBlockColor(item.funnel_block.slug)">{{ dbTranslate(item.funnel_block.name) }} </Tag>
                </template>

                <template v-slot:column.client_project="{ item }">
                    {{ item.client_project?.name }}
                </template>

                <template v-slot:column.location_type="{ item }">
                    {{ item.location_type?.name }}
                </template>

                <template v-slot:column.user="{ item }">
                    {{ item.user.full_name }}
                </template>

                <template v-slot:column.actions="{ item }">
                    <Link
                        :href="route('settings.recruitment.appointment-host.destroy', { id: item.id })"
                        class="text-primary-600 hover:text-primary-500"
                        method="DELETE"
                        as="button"
                    >
                        {{ $t('actions.delete') }}
                    </Link>
                </template>
            </DataTable>
        </div>
    </Settings>
</template>
