<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import Input from '@app/components/ui/form/Input.vue'
import Settings from '@app/layouts/Settings.vue'
import type { AnonymizeSetting } from '@app/types/anonymize'
import { useForm } from '@inertiajs/vue3'
import { route } from 'ziggy-js'

const props = defineProps<{ anonymizeSetting: AnonymizeSetting }>()

const form = useForm({
    subject_type: props.anonymizeSetting.subject_type,
    anonymize_after_amount_of_days: props.anonymizeSetting.anonymize_after_amount_of_days,
})

function update() {
    form.put(route('settings.anonymize.update', props.anonymizeSetting.id))
}
</script>

<template>
    <Settings group="general">
        <Form :form="form" @submit="update" class="max-w-xl">
            <FormField prop="subject_type" :label="$t('attributes.subject_type')">
                <Input id="subject_type" v-model="form.subject_type" autocomplete="off" />
            </FormField>

            <FormField prop="anonymize_after_amount_of_days" :label="$t('attributes.anonymize_after_amount_of_days')">
                <Input type="number" id="subject_type" v-model.number="form.anonymize_after_amount_of_days" autocomplete="off" min="0" />
            </FormField>

            <FormActions>
                <Button :disabled="form.processing">{{ $t('buttons.save') }}</Button>
            </FormActions>
        </Form>
    </Settings>
</template>
