<script setup>
import { computed, toRefs } from 'vue'
import { useLocale } from '@app/composables/useLocale'
import DropdownMultiFilter from '@app/components/ui/table/filters/DropdownMultiFilter.vue'

const props = defineProps({
    statuses: {
        type: Array,
        required: true,
    },
    callAttemptOptions: {
        type: Array,
        default: () => [],
    },
    only: {
        type: String,
        default: 'leads',
    },
})

const { dbTranslate } = useLocale()

const { statuses, callAttemptOptions } = toRefs(props)

function createTranslatedStatus({ name, slug }) {
    return {
        name: dbTranslate(name),
        slug,
    }
}

function mergeCallAttemptStatusWithAttempts({ name, slug }, amountOfAttempts) {
    return {
        name: `${name} (${amountOfAttempts})`,
        slug: `${slug}_${amountOfAttempts}`,
    }
}

const statusFilters = computed(() => {
    const translatedStatuses = statuses.value.map(createTranslatedStatus)

    const callAttemptSlug = 'call-attempt'
    const callAttemptStatus = translatedStatuses.find(({ slug }) => slug === callAttemptSlug)

    if (!callAttemptStatus) {
        return translatedStatuses
    }

    const callAttemptStatuses = callAttemptOptions.value.map((numberOfAttempts) => mergeCallAttemptStatusWithAttempts(callAttemptStatus, numberOfAttempts))

    const callAttemptStatusIndex = translatedStatuses.findIndex(({ slug }) => slug === callAttemptSlug)

    translatedStatuses.splice(callAttemptStatusIndex, 1, ...callAttemptStatuses)

    return translatedStatuses
})
</script>

<template>
    <DropdownMultiFilter :items="statusFilters" :label="$t('recruitment.leads.index.status')" :only="only" by="slug" slug="status" />
</template>
