<script lang="ts" setup>
import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/20/solid'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { ref, watch } from 'vue'
import type { RecruitmentAvailability } from '@app/types/recruitment'
import { getFromToWithSlotsTaken } from '@app/utils/recruitment/recruitment-availability'

const props = defineProps<{
    items: RecruitmentAvailability[]
    item: RecruitmentAvailability
}>()

const emit = defineEmits(['item:set'])
const selectedItem = ref<RecruitmentAvailability>(props.item)

watch(selectedItem, (newValue) => {
    emit('item:set', newValue)
})
</script>

<template>
    <div>
        <Listbox as="div" v-model="selectedItem" class="w-full" by="id">
            <div class="relative">
                <ListboxButton
                    :class="[
                        'relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left text-sm shadow-sm focus:border-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500',
                    ]"
                >
                    <span class="block truncate">{{ getFromToWithSlotsTaken(selectedItem) }}</span>
                    <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 sm:ml-3">
                        <ChevronDownIcon class="h-5 w-5 text-gray-700" aria-hidden="true" />
                    </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions
                        class="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400 focus:outline-none"
                    >
                        <ListboxOption as="template" v-for="item in items" :value="item" v-slot="{ active, selected }">
                            <li :class="[active ? 'bg-primary-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                <div class="flex items-center">
                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate sm:ml-3']">{{
                                        getFromToWithSlotsTaken(item)
                                    }}</span>
                                </div>
                                <span
                                    v-if="selected"
                                    :class="[active ? 'text-white' : 'text-primary-600', 'absolute inset-y-0 right-0 flex items-center pr-4']"
                                >
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                </span>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
    </div>
</template>
