<script setup lang="ts">
import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'

const errors = computed(() => usePage().props.errors)
const hasErrors = computed(() => Object.keys(errors.value).length > 0)
</script>

<template>
    <ul v-if="hasErrors" class="mt-3 list-inside list-disc text-sm text-red-600">
        <li v-for="(error, key) in errors" :key="key">
            {{ error }}
        </li>
    </ul>
</template>
