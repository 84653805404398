import { router } from '@inertiajs/vue3'
import { inject, type Component, type Ref, type ShallowRef } from 'vue'
import { route } from 'ziggy-js'

export function useDetailCard(selectedModel: Ref<{ id: number } | null | undefined> | undefined, only: string | string[]) {
    function toggleCard(id: number, without: string[] = []) {
        const selected = selectedModel?.value?.id === id ? null : id
        const currentRoute = route().current() ?? ''
        const currentParams = route().params

        without.forEach((key) => {
            delete currentParams[key]
        })

        const routeParams = {
            ...currentParams,
            selected,
        }

        return new Promise((resolve) => {
            router.get(
                route(currentRoute, routeParams),
                {},
                {
                    preserveState: true,
                    only: Array.isArray(only) ? only : [only],
                    onSuccess() {
                        resolve(true)
                    },
                }
            )
        })
    }

    return {
        toggleCard,
    }
}

export function useActiveButton() {
    const activeButton = inject('activeButton') as ShallowRef<Component | null>
    const activeButtonProps = inject('activeButtonProps') as Ref<Record<string, unknown>>

    function show(component: Component, props?: Record<string, unknown>) {
        activeButton.value = component

        if (props) {
            activeButtonProps.value = props
        }
    }

    function toggle<T>(component: Component<T>, props?: T) {
        activeButton.value = activeButton.value?.__name !== component.__name ? component : null

        if (props) {
            activeButtonProps.value = props
        }
    }

    function hide() {
        activeButton.value = null
    }

    return {
        show,
        toggle,
        hide,
    }
}
