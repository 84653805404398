<script setup lang="ts">
import ContractSignForm from '@app/components/contracts/sign/ContractSignForm.vue'
import PayrollTaxCreditAlert from '@app/components/contracts/sign/PayrollTaxCreditAlert.vue'
import Button from '@app/components/ui/button/Button.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import Input from '@app/components/ui/form/Input.vue'
import Label from '@app/components/ui/form/Label.vue'
import Radio from '@app/components/ui/form/Radio.vue'
import SignaturePad from '@app/components/ui/form/SignaturePad.vue'
import type { FormStep } from '@app/components/ui/multiStepForm/types'
import { useFormWizard } from '@app/composables/form/useFormWizard'
import { useSignatureField } from '@app/composables/form/useSignatureField'
import Guest from '@app/layouts/Guest.vue'
import { useForm } from '@inertiajs/vue3'

defineOptions({
    layout: Guest,
})

type FinancialInformationModel = {
    account_holder_name: string | null
    iban: string | null
    bsn: string | null
    payroll_tax_credit: number | null
    payroll_tax_credit_signature: string | null
}

const props = defineProps<{
    steps: FormStep[]
    model: FinancialInformationModel
}>()

const { previousStep, navigateToPreviousStep } = useFormWizard(props.steps)

const form = useForm({
    account_holder_name: props.model.account_holder_name,
    iban: props.model.iban,
    payroll_tax_credit: props.model.payroll_tax_credit,
    payroll_tax_credit_signature: props.model.payroll_tax_credit_signature,
    bsn: props.model.bsn,
})

const { signatureModel } = useSignatureField(form, 'payroll_tax_credit_signature', props.model.payroll_tax_credit_signature)
</script>

<template>
    <ContractSignForm :steps="steps">
        <Form :form="form" @submit="form.post('')">
            <FormSection>
                <FormField class="col-span-12 sm:col-span-6" :required="true" prop="account_holder_name" :label="$t('attributes.account_holder_name')">
                    <Input id="account_holder_name" v-model="form.account_holder_name" type="text" />
                </FormField>

                <FormField class="col-span-12 sm:col-span-6" :required="true" prop="iban" :label="$t('attributes.iban')">
                    <Input id="iban" v-model="form.iban" type="text" placeholder="NL00 0000 0000 0000 00" />
                </FormField>

                <FormField class="col-span-12 sm:col-span-6" :required="true" prop="bsn" :label="$t('attributes.bsn')">
                    <Input id="bsn" v-model="form.bsn" type="text" placeholder="123456789" />
                </FormField>

                <div class="col-span-12">
                    <div class="mt-1 flex flex-col gap-3">
                        <Label>{{ $t('attributes.payroll_tax_credit') }}? *</Label>
                        <PayrollTaxCreditAlert />
                        <Radio v-model="form.payroll_tax_credit" :value="false">{{ $t('contract.payroll_tax_credit.no') }}</Radio>
                        <Radio v-model="form.payroll_tax_credit" :value="true">{{ $t('contract.payroll_tax_credit.yes') }}</Radio>
                    </div>

                    <FormField v-if="form.payroll_tax_credit" prop="payroll_tax_credit_signature" class="mt-3 max-w-xs">
                        <SignaturePad v-model="signatureModel" :label="$t('form.labels.signature')" />
                    </FormField>
                </div>
            </FormSection>

            <FormActions>
                <Button v-if="previousStep" color="white" type="button" @click="navigateToPreviousStep">{{ $t('buttons.back') }}</Button>
                <Button :disabled="form.processing">{{ $t('buttons.save') }}</Button>
            </FormActions>
        </Form>
    </ContractSignForm>
</template>
