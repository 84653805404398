<script lang="ts" setup>
import CreateAvailabilityForm from '@app/components/recruitment/availabilities/CreateAvailabilityForm.vue'
import EventForm from '@app/components/recruitment/availabilities/EventForm.vue'
import Button from '@app/components/ui/button/Button.vue'
import Event from '@app/components/ui/calendar/Event.vue'
import Divider from '@app/components/ui/calendar/header/Divider.vue'
import Header from '@app/components/ui/calendar/header/Header.vue'
import WeekSwitcher from '@app/components/ui/calendar/header/WeekSwitcher.vue'
import Weekdays from '@app/components/ui/calendar/Weekdays.vue'
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'
import ModalLayout from '@app/components/ui/modal/ModalLayout.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'
import { useUserCan } from '@app/composables/useUserCan'
import type { RecruitmentAppointmentHost, RecruitmentAvailability } from '@app/types/recruitment'
import type { User } from '@app/types/shared'
import { applyFilter } from '@app/utils/filter'
import { isExcluded } from '@app/utils/openingHours'
import {
    getAppointmentColors,
    getDayClass,
    getEventTitle,
    getSlotsTakenStyled,
    getThisWeeksAvailabilities,
    getTimeStyle,
} from '@app/utils/recruitment/recruitment-availability'
import { eachDayOfInterval, eachHourOfInterval, endOfWeek, format, isPast, isWeekend, setHours, startOfToday, startOfWeek } from 'date-fns'
import { computed, provide, ref, toRefs, watch } from 'vue'

const props = defineProps<{
    users: Array<User>
    availabilities: Array<RecruitmentAvailability>
    appointmentHosts: Array<RecruitmentAppointmentHost[]>
    selectedUser: User
    excludedDates: string[]
}>()

const { appointmentHosts, excludedDates } = toRefs(props)

provide('availability:excluded', excludedDates)
provide('availability:hosts', appointmentHosts)

const selectedUser = ref(props.selectedUser)

const { can } = useUserCan()

const selectedDay = ref(startOfToday())
const days = computed(() =>
    eachDayOfInterval({
        start: startOfWeek(selectedDay.value, { weekStartsOn: 1 }),
        end: endOfWeek(selectedDay.value, { weekStartsOn: 1 }),
    })
)
const weekdays = computed(() => days.value.filter((day) => !isWeekend(day)))

const STARTING_HOUR = 9

const getHours = (day: Date) => {
    return eachHourOfInterval({
        start: setHours(day, STARTING_HOUR),
        end: setHours(day, 22),
    })
}

const availabilitiesThisWeek = computed(() => props.availabilities.filter((availability) => getThisWeeksAvailabilities(availability, selectedDay.value)))
const isPastWeek = computed(() => isPast(endOfWeek(selectedDay.value, { weekStartsOn: 1 })))

const openedAvailability = ref<RecruitmentAvailability | null>()
const openEventModal = (availability: RecruitmentAvailability) => {
    openedAvailability.value = availability
}

const showCreateAvaibilityForm = ref(false)

const closeEventModal = () => (openedAvailability.value = null)

watch(selectedUser, () => {
    applyFilter({ user_id: selectedUser.value?.id })
})
</script>

<template>
    <div class="flex h-full flex-col">
        <Header :date="selectedDay">
            <WeekSwitcher v-model="selectedDay" />
            <Divider />
            <Button :disabled="isPastWeek" color="primary" @click="showCreateAvaibilityForm = true">
                {{ $t('calendar.availability.add') }}
            </Button>
        </Header>

        <FilterSection :showPerPageOptions="false" class="-ml-4">
            <Dropdown v-if="can('recruitment.availability.manage')" :items="users" v-model="selectedUser" propertyName="full_name" :translateDb="false" />
        </FilterSection>

        <div
            ref="container"
            class="isolate flex flex-auto flex-col overflow-y-auto bg-white scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400 lg:overflow-x-hidden"
        >
            <div class="flex w-[200%] max-w-none flex-none flex-col md:w-[165%] lg:max-w-full">
                <!-- Weekdays -->
                <div class="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5">
                    <div class="-mr-px grid grid-cols-5 divide-x divide-gray-100 border-r border-gray-100 text-xs leading-4 text-gray-500">
                        <div class="col-end-1 w-14" />
                        <Weekdays :days="weekdays" />
                    </div>
                </div>
                <!-- Hours and events -->
                <div class="flex">
                    <div class="sticky left-0 z-10 w-14 flex-none bg-white ring-1 ring-gray-100" />
                    <div class="grid flex-auto grid-cols-1 grid-rows-1">
                        <!-- Horizontal lines -->
                        <div
                            class="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                            style="grid-template-rows: repeat(calc(14 * 4), minmax(1rem, 1fr))"
                        >
                            <div class="row-end-1 h-7" />
                            <div v-for="hour in getHours(selectedDay)" class="row-span-4">
                                <div class="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                                    {{ format(hour, 'HH:mm') }}
                                </div>
                            </div>
                        </div>

                        <!-- Vertical lines -->
                        <div class="col-start-1 col-end-2 row-start-1 grid grid-cols-5 grid-rows-1 divide-x divide-gray-100">
                            <div v-for="day in weekdays" :class="{ 'bg-gray-100': isExcluded(day, excludedDates) }"></div>
                        </div>

                        <!-- Events -->
                        <div
                            class="col-start-1 col-end-2 row-start-1 grid w-full grid-cols-5"
                            style="
                                grid-template-rows:
                                    1.75rem repeat(calc(14 * 4), minmax(0, 1fr))
                                    auto;
                            "
                        >
                            <div
                                v-for="availability in availabilitiesThisWeek"
                                :key="availability.id"
                                :class="getDayClass(availability.from)"
                                :style="getTimeStyle(availability, STARTING_HOUR)"
                            >
                                <Event
                                    :title="getEventTitle(availability)"
                                    :from="availability.from"
                                    :to="availability.to"
                                    :content="getSlotsTakenStyled(availability)"
                                    :colors="getAppointmentColors(availability)"
                                    @click="openEventModal(availability)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalLayout :show="!!openedAvailability" @close="closeEventModal">
            <EventForm v-if="openedAvailability" :availability="openedAvailability" @close="closeEventModal" :key="openedAvailability.id" />
        </ModalLayout>

        <ModalLayout :show="showCreateAvaibilityForm" @close="showCreateAvaibilityForm = false">
            <CreateAvailabilityForm :user="selectedUser" :days="weekdays" :date="selectedDay" @close="showCreateAvaibilityForm = false" />
        </ModalLayout>
    </div>
</template>
