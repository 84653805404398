<script setup>
import { useLocale } from '@app/composables/useLocale'
import { applyFilter } from '@app/utils/filter'
import { ucfirst } from '@app/utils/transform'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { trans, transChoice } from 'laravel-vue-i18n'
import { computed, inject, ref, watch } from 'vue'
import FilterLabel from './FilterLabel.vue'

const props = defineProps({
    items: Array,
    label: String,
    allLabel: {
        type: String,
        default: 'filters.checkbox.all',
    },
    propertyName: {
        type: String,
        default: 'name',
    },
    by: {
        type: String,
        default: 'slug',
    },
    slug: {
        type: String,
        required: true,
    },
    border: {
        type: Boolean,
        default: true,
    },
})

const { dbTranslate } = useLocale()

const only = inject('datatable:only', [])

const params = route().params

const selectAll = ref(!params[props.slug])

const selected = ref(
    props.items.filter((item) => {
        const initialFilter = params[props.slug]

        if (Array.isArray(initialFilter)) {
            if (typeof item === 'object') {
                return initialFilter.includes(item[props.by]?.toString())
            }

            return initialFilter.includes(item.toString())
        }

        return true
    })
)

function getLabel(item) {
    if (typeof item[props.propertyName] === 'string') {
        return trans(item[props.propertyName])
    }

    return dbTranslate(item[props.propertyName])
}

const selectBoxLabel = computed(() => {
    if (selectAll.value || selected.value.length === 0) {
        return trans(props.allLabel)
    }

    if (selected.value.length === 1) {
        return getLabel(selected.value[0])
    }

    return transChoice('filters.checkbox.partial', selected.value.length)
})

function handleToggleAll() {
    selectAll.value = !selectAll.value

    selected.value = selectAll.value ? [...props.items] : []
}

watch(selected, () => {
    selectAll.value = selected.value.length === props.items.length

    if (selectAll.value) {
        applyFilter({ [props.slug]: null }, { only: [only] })
    } else {
        const filterValues = selected.value.map((item) => {
            if (typeof item === 'string') {
                return item
            }

            return item[props.by]
        })

        applyFilter({ [props.slug]: filterValues }, { only: [only] })
    }
})
</script>

<template>
    <div v-if="items.length > 1" class="w-full min-w-[100px] md:w-auto">
        <FilterLabel v-if="label">{{ ucfirst(label) }}</FilterLabel>
        <Listbox as="div" v-model="selected" multiple :by="by">
            <div class="relative">
                <ListboxButton
                    :class="[
                        border ? 'border border-zinc-300 shadow-none' : 'shadow-sm',
                        'relative w-full cursor-pointer rounded-md py-2 pl-3 pr-10 text-left text-sm hover:bg-zinc-50 focus:border-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500',
                    ]"
                >
                    <span class="ml-1 block truncate">{{ selectBoxLabel }}</span>
                    <span class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                        <ChevronDownIcon class="h-6 w-6 text-primary-500" aria-hidden="true" />
                    </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                    <ListboxOptions
                        class="absolute z-10 mt-1 max-h-56 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-400 focus:outline-none sm:text-sm"
                    >
                        <li class="relative flex select-none items-center border-b border-zinc-100 py-2 pl-3 pr-9 hover:bg-zinc-50" @click="handleToggleAll">
                            <input type="checkbox" class="rounded-[4px] text-primary-600 focus:outline-0 focus:ring-0" :checked="selectAll" />
                            <span class="ml-3 truncate">{{ $t('filters.checkbox.all') }}</span>
                        </li>

                        <ListboxOption as="template" v-for="item in items" :value="item" v-slot="{ active, selected }">
                            <li :class="[active ? 'bg-zinc-50' : 'text-zinc-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                <div class="flex items-center">
                                    <input type="checkbox" class="rounded-[4px] focus:outline-0 focus:ring-0" :checked="selected" />
                                    <span class="ml-3 block truncate">
                                        {{ getLabel(item) }}
                                    </span>
                                </div>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
    </div>
</template>
