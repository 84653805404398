import { computed, ref } from 'vue'

export function useTimer() {
    let timerId: number | undefined

    const seconds = ref(0)

    const stopwatch = computed(() => {
        const date = new Date(1000 * seconds.value)

        return date.toISOString().substring(14, 19)
    })

    function startTimer() {
        timerId = setInterval(() => {
            seconds.value++
        }, 1000)
    }

    function stopTimer() {
        clearInterval(timerId)
    }

    function resetTimer() {
        stopTimer()
        seconds.value = 0
    }

    return {
        seconds,
        stopwatch,
        startTimer,
        stopTimer,
        resetTimer,
    }
}
