const COLOR_BLUE = 'blue'
const COLOR_STONE = 'stone'
const COLOR_GRAY = 'gray'
const COLOR_GREEN = 'green'
const COLOR_RED = 'red'
const COLOR_YELLOW = 'yellow'
const COLOR_PINK = 'pink'
const COLOR_LIGHT_BLUE = 'lightBlue'
const COLOR_FONKY_ORANGE = 'fonkyOrange'

export function useColor() {
    function getStatusBackgroundColor(status: string) {
        switch (status) {
            case 'active':
                return '#1AAB8B'
            case 'away':
                return '#FFC73C'
            case 'inactive':
                return '#EC454F'
        }
    }

    const FUNNEL_BLOCK_STATUS_NEW = 'new'
    const FUNNEL_BLOCK_STATUS_CALL_ATTEMPT = 'call-attempt'
    const FUNNEL_BLOCK_STATUS_CALLBACK_APPOINTMENT = 'callback-appointment'
    const FUNNEL_BLOCK_STATUS_APPROVED = 'approved'
    const FUNNEL_BLOCK_STATUS_DECLINED = 'declined'
    const FUNNEL_BLOCK_STATUS_UNREACHABLE = 'unreachable'
    const FUNNEL_BLOCK_STATUS_NO_SHOW = 'no-show'
    const FUNNEL_BLOCK_STATUS_APPOINTMENT_SCHEDULED = 'appointment-scheduled'
    const FUNNEL_BLOCK_STATUS_APPOINTMENT_CANCELLED = 'appointment-cancelled'
    const FUNNEL_BLOCK_STATUS_IN_PROGRESS = 'in-progress'
    const FUNNEL_BLOCK_STATUS_STARTED = 'started'
    const FUNNEL_BLOCK_STATUS_COMPLETED = 'completed'
    const FUNNEL_BLOCK_STATUS_WAITING_FOR_CONFIRMATION = 'waiting-for-confirmation'

    const getFunnelBlockStatusColor = (status: string) => {
        switch (status) {
            case FUNNEL_BLOCK_STATUS_NEW:
                return COLOR_BLUE
            case FUNNEL_BLOCK_STATUS_CALL_ATTEMPT:
            case FUNNEL_BLOCK_STATUS_STARTED:
                return COLOR_YELLOW
            case FUNNEL_BLOCK_STATUS_CALLBACK_APPOINTMENT:
            case FUNNEL_BLOCK_STATUS_WAITING_FOR_CONFIRMATION:
            case FUNNEL_BLOCK_STATUS_NO_SHOW:
                return COLOR_PINK
            case FUNNEL_BLOCK_STATUS_UNREACHABLE:
                return COLOR_STONE
            case FUNNEL_BLOCK_STATUS_APPOINTMENT_CANCELLED:
            case FUNNEL_BLOCK_STATUS_DECLINED:
                return COLOR_RED
            case FUNNEL_BLOCK_STATUS_APPROVED:
            case FUNNEL_BLOCK_STATUS_COMPLETED:
                return COLOR_GREEN
            case FUNNEL_BLOCK_STATUS_APPOINTMENT_SCHEDULED:
            case FUNNEL_BLOCK_STATUS_IN_PROGRESS:
                return COLOR_FONKY_ORANGE
        }
    }

    const getFunnelBlockColor = (block: string) => {
        switch (block) {
            case 'lead':
                return COLOR_LIGHT_BLUE
            case 'introduction':
            case 'interested':
                return COLOR_YELLOW
            case 'workshop':
                return COLOR_FONKY_ORANGE
            case 'onboarding':
            case 'external':
                return COLOR_GREEN

            default:
                return COLOR_LIGHT_BLUE
        }
    }

    const getFunnelColor = (funnel: string) => {
        switch (funnel) {
            case 'sales-agent':
                return COLOR_BLUE
            case 'promotor':
                return COLOR_PINK
            case 'contact-center':
                return COLOR_YELLOW
            default:
                return COLOR_GRAY
        }
    }

    const getOrderStatusColor = (status: string) => {
        switch (status) {
            case 'approved':
                return COLOR_GREEN
            case 'refused':
                return COLOR_RED
            case 'failed':
                return COLOR_YELLOW
            default:
                return COLOR_GRAY
        }
    }

    return {
        getFunnelColor,
        getFunnelBlockColor,
        getFunnelBlockStatusColor,
        getStatusBackgroundColor,
        getOrderStatusColor,
    }
}
