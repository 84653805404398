<script setup lang="ts" generic="T extends object">
import type { PageProps } from '@app/types/inertia'
import type { TranslatableProperty } from '@app/types/shared'
import { usePage, type InertiaForm } from '@inertiajs/vue3'
import { computed, inject } from 'vue'
import Label from './Label.vue'
import { ucfirst } from '@app/utils/transform'
import Input from './Input.vue'
import FieldError from './FieldError.vue'

const model = defineModel<TranslatableProperty>({ required: true })

const props = defineProps<{
    label: string
    prop?: string
}>()

const form = inject<InertiaForm<T>>('form')

const page = usePage<PageProps>()
const languages = computed(() => page.props.languages)

const translatableInputErrors = computed(() => {
    const keys = Object.entries<Record<string, string>>(form?.errors || {})

    return keys.reduce<Record<string, string>>((errors, [key, message]) => {
        if (key.startsWith(`${props.prop}.`)) {
            const [_, locale] = key.split('.')

            errors[locale] = message
        }

        return errors
    }, {})
})

function getInputId(slug: string) {
    return `label-${slug}`
}
</script>

<template>
    <div class="flex flex-col gap-4">
        <div v-for="{ slug } in languages">
            <Label :for="getInputId(slug)" class="mb-1">{{ ucfirst(label) }} ({{ slug }})</Label>
            <Input :id="getInputId(slug)" v-model="model[slug]" />

            <FieldError v-if="prop" :message="translatableInputErrors[slug]" class="mt-2" />
        </div>
    </div>
</template>
