<script setup lang="ts">
import { useLineChartData, useLineGraphSummary } from '@app/composables/chartjs/useLineChartData'
import { chartDefaults } from '@app/utils/chartSettings'
import { ArrowTrendingDownIcon, ArrowTrendingUpIcon } from '@heroicons/vue/24/solid'
import type { ChartData } from 'chart.js'
import { ref } from 'vue'
import { Line } from 'vue-chartjs'

chartDefaults()

interface GraphCardProps {
    graph: {
        name: string
        range: string
        summary: {
            trendType: 'positive' | 'negative'
        }
        data: ChartData<'line'>
    }
}

const { graph } = defineProps<GraphCardProps>()

const legendItems = ref(graph.data.datasets?.map((item) => item.label) ?? [])

const { chartData, options } = useLineChartData(legendItems, graph.data)
const { lastValue, change, positiveTrend, currentValueLabel, changeFromLabel } = useLineGraphSummary(legendItems, graph.data, graph.summary.trendType)
</script>

<template>
    <div class="bg-white-800 w-full rounded-lg border border-zinc-100 p-4">
        <div class="flex flex-col justify-between gap-2">
            <div class="flex flex-wrap items-start justify-between">
                <h3 class="w-1/2 text-base font-medium leading-6 text-zinc-600">{{ $t(graph.name) }}</h3>
                <div class="text-sm font-medium uppercase leading-5 text-zinc-400">{{ $t(graph.range) }}</div>
            </div>
            <div class="flex items-center gap-4">
                <p class="text-2xl font-semibold leading-8 text-gray-900" v-tooltip="currentValueLabel">{{ lastValue }}</p>
                <div
                    v-if="change !== 0"
                    :class="[positiveTrend ? 'text-green-500' : 'text-red-500', 'flex items-center gap-1']"
                    v-tooltip="$t('dashboard.change_period', { period: changeFromLabel })"
                >
                    <div :class="[positiveTrend ? 'bg-green-100' : 'bg-red-100', 'flex h-6 w-6 items-center justify-around rounded-full']">
                        <ArrowTrendingUpIcon v-if="change >= 0" class="size-5" :class="[positiveTrend ? 'text-green-500' : 'text-red-500']" />
                        <ArrowTrendingDownIcon v-else class="size-5" :class="[positiveTrend ? 'text-green-500' : 'text-red-500']" />
                    </div>
                    <span>{{ change }}</span>
                </div>
            </div>
        </div>
        <div>
            <Line :data="chartData" :options="options" />
        </div>
    </div>
</template>
