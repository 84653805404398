<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { computed, inject, type Component, type ShallowRef } from 'vue'
import { EnvelopeIcon } from '@heroicons/vue/24/solid'
import { useUserCan } from '@app/composables/useUserCan'
import LeadEmailForm from '@app/components/recruitment/leads/forms/LeadEmailForm.vue'
import type { Lead } from '@app/types/recruitment'
import { isNotEmptyArray } from '@app/utils/array'

const props = defineProps<{
    lead: Lead
}>()

const activeButton = inject<ShallowRef<Component>>('activeButton')
const { can } = useUserCan()
// remove the last check when we implement the user_can_select flag and link the templates to the funnels
const isShown = computed(
    () =>
        can('recruitment.email.send') &&
        !props.lead.is_status_final &&
        isNotEmptyArray(props.lead.email_templates) &&
        props.lead.funnel.slug !== 'recruitment-selection'
)
const isDisabled = computed(() => !props.lead.email)
</script>

<template>
    <RoundButton v-if="isShown" :disabled="isDisabled" :label="$t('recruitment.leads.card.actions.email')" @click="activeButton = LeadEmailForm">
        <EnvelopeIcon class="h-4 w-4" aria-hidden="true" />
    </RoundButton>
</template>
