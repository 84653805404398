<script setup lang="ts">
import GraphCard from '@app/components/dashboard/GraphCard.vue'
import DropdownMultiFilter from '@app/components/ui/table/filters/DropdownMultiFilter.vue'
import type { Branch, Language, Region } from '@app/types/shared'
import { chartDefaults } from '@app/utils/chartSettings'
import { router } from '@inertiajs/vue3'
import type { ChartData } from 'chart.js'
import { computed, onMounted, watch } from 'vue'

type BranchKpi = {
    name: string
    kpi: Record<
        string,
        {
            name: string
            range: string
            data: ChartData
        }
    >
}

const { regions, branches, kpi, graphs, selectedKpi, userLanguage } = defineProps<{
    regions: Region[]
    branches: Branch[]
    kpi: Array<{ slug: string; name: string }>
    graphs?: Array<BranchKpi>
    selectedKpi: string[]
    userLanguage: Language
}>()

onMounted(() => {
    router.reload({ only: ['graphs'] })
})

watch(
    () => userLanguage,
    () => {
        router.reload({ only: ['graphs'] })
    }
)

chartDefaults()

const locations = computed(() => [
    {
        slug: 'all',
        name: 'dashboard.filters.locations',
    },
    ...regions,
    ...branches,
])

const spans = ['col-span-1', 'col-span-2', 'col-span-3', 'col-span-4']

const graphRows = computed(() => {
    const selected = selectedKpi.length === 0 ? kpi.length : selectedKpi.length

    return spans[selected - 1]
})
</script>

<template>
    <!-- <ContentTabs :items="tabs" bottomBorder /> -->
    <div class="flex items-center justify-end gap-2 pb-4">
        <DropdownMultiFilter :items="locations" slug="branch" by="slug" class="!min-w-40" all-label="dashboard.filters.locations" />
        <DropdownMultiFilter :items="kpi" slug="kpi" by="slug" class="!min-w-40" all-label="dashboard.filters.kpi" />
    </div>
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-4">
        <div v-if="graphs" v-for="graph in graphs" :class="graphRows">
            <div class="mb-4 text-lg font-medium leading-6 text-zinc-800">{{ $t(graph.name) }}</div>
            <div class="flex flex-col gap-4 lg:flex-row">
                <GraphCard v-for="kpi in graph.kpi" :graph="kpi" :key="`${graph.name}-${kpi.name}`" />
            </div>
        </div>
    </div>
    <div class="flex flex-col flex-wrap gap-4">
        <!-- <div class="flex justify-between gap-4">
            <LargeGraph title="KPI card" range="Last month" :lineColor="tailwindColors.fonkyBlue" :dotColor="tailwindColors.fonkyBlue" />
        </div> -->
    </div>
</template>

<style lang="css">
/** @see https://github.com/chartjs/Chart.js/issues/11005 */
canvas {
    width: 100% !important;
    height: 100% !important;
}
</style>
