<script setup lang="ts">
import { PlayCircleIcon, PauseCircleIcon } from '@heroicons/vue/20/solid'
import { timeToDuration } from '@app/utils/date'
import { computed, onMounted, ref, watch } from 'vue'
import { useAudioContext } from '@app/composables/useAudioContext'

const props = defineProps<{
    src: string
    estimatedDuration: number
}>()

const { currentlyPlaying } = useAudioContext()

const audioPlayer = ref<HTMLAudioElement>()

const durationSeconds = ref(props.estimatedDuration)
const currentTimeSeconds = ref(0)
const isPlaying = ref(false)

const currentTimeFormatted = computed(() => timeToDuration(Math.round(currentTimeSeconds.value)))
const durationFormatted = computed(() => timeToDuration(Math.round(durationSeconds.value)))
const percentPlayed = computed(() => (currentTimeSeconds.value / durationSeconds.value) * 100)

function onLoadedMetadata() {
    durationSeconds.value = audioPlayer.value!.duration
}

function onTimeUpdate() {
    currentTimeSeconds.value = audioPlayer.value?.currentTime || 0
}

function jumpToTime(event: MouseEvent) {
    if (!isPlaying.value) return

    const progressElement = event.target as HTMLDivElement
    const clickPosition = (event.pageX - progressElement.getBoundingClientRect().left) / progressElement.offsetWidth
    const clickTime = clickPosition * props.estimatedDuration

    // Move the playhead to the correct position
    audioPlayer.value!.currentTime = clickTime
}

function onEnded() {
    isPlaying.value = false
}

function playAudio() {
    if (audioPlayer.value) {
        audioPlayer.value
            .play()
            .then(() => {
                currentlyPlaying.value = props.src
                isPlaying.value = true
            })
            .catch((reason) => {})
    }
}

async function pauseAudio() {
    if (audioPlayer.value) {
        audioPlayer.value.pause()
        isPlaying.value = false
    }
}

watch(currentlyPlaying, () => {
    if (currentlyPlaying.value !== props.src) {
        pauseAudio()
    }
})

onMounted(() => {
    audioPlayer.value!.addEventListener('loadedmetadata', onLoadedMetadata)
    audioPlayer.value!.addEventListener('timeupdate', onTimeUpdate)
    audioPlayer.value!.addEventListener('ended', onEnded)

    return () => {
        audioPlayer.value!.removeEventListener('loadedmetadata', onLoadedMetadata)
        audioPlayer.value!.removeEventListener('timeupdate', onTimeUpdate)
        audioPlayer.value!.removeEventListener('ended', onEnded)
    }
})
</script>

<template>
    <div id="audioplayer" name="audioplayer" class="inline-flex w-full min-w-[18rem] items-center rounded-md border border-gray-300">
        <audio controls class="hidden" ref="audioPlayer" preload="none">
            <source :src="src" type="audio/mpeg" />
        </audio>
        <div class="inline-flex items-center space-x-3 border-r-2 border-gray-100 p-2">
            <PlayCircleIcon v-if="!isPlaying" class="h-10 w-10 cursor-pointer" aria-hidden="true" @click="playAudio" />
            <PauseCircleIcon v-if="isPlaying" class="h-10 w-10 cursor-pointer" aria-hidden="true" @click="pauseAudio" />
        </div>
        <div class="inline-flex w-full items-center space-x-3 p-2">
            <div class="text-xs font-normal leading-none">{{ currentTimeFormatted }}</div>
            <div class="w-full" aria-hidden="true">
                <div class="overflow-hidden rounded-full bg-gray-200" :class="[isPlaying && 'cursor-pointer']" @click="jumpToTime">
                    <div class="h-2 rounded-full bg-secondary-600" :style="{ width: `${percentPlayed}%` }"></div>
                </div>
            </div>
            <div class="text-xs font-normal leading-none">{{ durationFormatted }}</div>
        </div>
    </div>
</template>
