<script setup lang="ts">
import CancelButton from '@app/components/ui/button/CancelButton.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import FieldError from '@app/components/ui/form/FieldError.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormTextArea from '@app/components/ui/form/FormTextArea.vue'
import Label from '@app/components/ui/form/Label.vue'
import type { Lead } from '@app/types/recruitment'
import { useForm } from '@inertiajs/vue3'
import { route } from 'ziggy-js'

const emit = defineEmits(['content:closed'])

const props = defineProps<{ lead: Lead }>()

const form = useForm({
    status: 'on-hold',
    note: '',
})

function putLeadOnHold() {
    form.put(route('recruitment.lead.update-funnel-block-status', props.lead.id), {
        preserveScroll: true,
        onSuccess() {
            emit('content:closed')
        },
        onCancel() {
            emit('content:closed')
        },
    })
}
</script>

<template>
    <div class="mx-auto mt-4 max-w-sm">
        <Form :form="form" @submit="putLeadOnHold" id="onhold_form">
            <Label for="comment">{{ $t('note.description') }}</Label>
            <div class="mt-1">
                <FormTextArea id="note" v-model="form.note" :rows="6" autocomplete="off" autofocus />
            </div>
            <FieldError :message="form.errors['note']" class="mt-2" />
            <div class="mt-4 flex place-content-end space-x-2">
                <CancelButton @click="$emit('content:closed')" />
                <SaveButton :disabled="form.processing" id="add_note_button">{{ $t('note.save') }}</SaveButton>
            </div>
        </Form>
    </div>
</template>
