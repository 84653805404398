<script setup lang="ts">
import { type ShallowRef, inject, type Component, computed } from 'vue'
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import TerminationForm from '@app/components/contracts/TerminationForm.vue'
import type { Contract } from '@app/types/contracts'
import { useUserCan } from '@app/composables/useUserCan'
import { XMarkIcon } from '@heroicons/vue/16/solid'

const activeButton = inject<ShallowRef<Component>>('activeButton')
const { can } = useUserCan()

const props = defineProps<{
    contract: Contract
}>()

const buttonShown = computed(() => can('contract.terminate') && props.contract.status.slug == 'active')
</script>

<template>
    <RoundButton v-if="buttonShown" :label="$t('contract.actions.terminate')" @click="activeButton = TerminationForm">
        <XMarkIcon class="size-5" />
    </RoundButton>
</template>
