<script setup lang="ts">
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import CalendarInput from '@app/components/ui/form/CalendarInput.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import FormSection from '@app/components/ui/form/FormSection.vue'
import MoneyInput from '@app/components/ui/form/MoneyInput.vue'
import { useLocale } from '@app/composables/useLocale'
import type { ShiftRankBonus } from '@app/types/finance'
import { getDateFromDateTime } from '@app/utils/date'
import { useForm } from '@inertiajs/vue3'
import { computed, toRefs, type PropType } from 'vue'
import { route } from 'ziggy-js'

const emit = defineEmits(['submit'])
const props = defineProps({
    shiftRankBonus: {
        type: Object as PropType<ShiftRankBonus>,
        required: true,
    },
})

const { shiftRankBonus } = toRefs(props)
const { dbTranslate } = useLocale()

const form = useForm({
    work_type_rank_id: shiftRankBonus.value.work_type_rank_id,
    amount: shiftRankBonus.value.amount.amount,
    amount_start_date: '',
})

function submit() {
    form.post(route('settings.finance.shift-rank-bonus.store'), {
        onSuccess: () => emit('submit'),
    })
}

const setSelectedDay = (day: string) => {
    form.amount_start_date = getDateFromDateTime(day, 'short')
}

const formTitle = computed(() => dbTranslate(shiftRankBonus.value.work_type_rank.work_type.name) + ' ' + dbTranslate(shiftRankBonus.value.work_type_rank.name))
</script>

<template>
    <Form :form="form" class="mt-8 w-4/5" @submit="submit">
        <FormSection :title="formTitle" class="mb-4">
            <FormField prop="amount" class="col-span-12 sm:col-span-6" :label="$t('attributes.amount')">
                <MoneyInput v-model="form.amount" />
            </FormField>
        </FormSection>
        <FormField prop="amount_start_date" class="w-2/3" :label="$t('attributes.amount_start_date')">
            <CalendarInput @selectedDay:set="setSelectedDay" />
        </FormField>
        <FormActions>
            <SaveButton />
        </FormActions>
    </Form>
</template>
