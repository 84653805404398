<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import DataTable from '@app/components/ui/table/DataTable.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'
import ResetFilterButton from '@app/components/ui/table/filters/ResetFilterButton.vue'
import { useUserCan } from '@app/composables/useUserCan'
import Settings from '@app/layouts/Settings.vue'
import { Link } from '@inertiajs/vue3'
import { route } from 'ziggy-js'
import type { JobTitleExternalApplication, ResourceCollection } from '@app/types/shared'
import { trans } from 'laravel-vue-i18n'

defineProps<{
    jobTitleExternalApplications: ResourceCollection<JobTitleExternalApplication>
}>()

const { can } = useUserCan()

const columns = [
    {
        type: 'string',
        header: 'ID',
        properties: { name: 'id' },
        sort: 'id',
    },
    {
        type: 'string',
        header: 'attributes.name',
        properties: { name: 'name' },
        sort: 'name',
    },
    {
        type: 'string',
        header: trans('settings.job-title.header'),
        properties: { name: 'name', relation: 'job_title', translate: true },
    },
    {
        type: 'string',
        header: trans('settings.application'),
        properties: { name: 'name', relation: 'application' },
    },
    {
        name: 'actions',
        header: 'actions.header',
        show: can('job-title-external-application.store'),
    },
]

const params = route().params
</script>

<template>
    <Settings group="general">
        <DataTable
            class="flex w-full flex-col rounded-lg border border-gray-100 transition-all duration-500"
            :items="jobTitleExternalApplications"
            :columns="columns"
        >
            <template #filter>
                <FilterSection :enable-search="true" :text-search-param="params.name">
                    <ResetFilterButton class="mt-2" :href="route('settings.job-title-external-application.index')" :label="$t('buttons.clear')" />
                </FilterSection>
            </template>
            <template v-slot:column.actions="{ item }">
                <Link
                    :v-if="can('job-title-external-application.store')"
                    class="mr-2 text-primary-600"
                    :href="route('settings.job-title-external-application.edit', { slug: item.slug })"
                >
                    {{ $t('actions.update') }}
                </Link>
            </template>
        </DataTable>
    </Settings>
</template>
