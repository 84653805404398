<script setup lang="ts">
import DataTable from '@app/components/ui/table/DataTable.vue'
import Settings from '@app/layouts/Settings.vue'
import type { Branch, ResourceCollection } from '@app/types/shared'
import { route } from 'ziggy-js'
import { trans } from 'laravel-vue-i18n'
import type { PageProps } from '@app/types/inertia'
import { usePage } from '@inertiajs/vue3'
import { useUserCan } from '@app/composables/useUserCan'

const { can } = useUserCan()

defineProps<{
    branches: ResourceCollection<Branch>
}>()

const user = usePage<PageProps>().props.user

const columns = [
    {
        type: 'string',
        header: 'ID',
        properties: { name: 'id' },
        show: user.is_super_admin,
    },
    {
        type: 'string',
        header: 'attributes.name',
        properties: { name: 'name' },
        sort: 'name',
    },
    {
        type: 'string',
        header: 'attributes.email',
        properties: { name: 'email_address' },
    },
    {
        type: 'string',
        header: 'attributes.phone_number',
        properties: { name: 'phone_number' },
    },
    {
        type: 'link',
        header: 'actions.header',
        properties: {
            resolveLinkProps: (branch: Branch) => ({
                label: trans('marketing_channel.index.table.edit'),
                href: route('settings.branch.edit', {
                    slug: branch.slug,
                }),
            }),
            show: can('branch.store'),
        },
    },
]
</script>

<template>
    <Settings group="general">
        <div class="mb-6 md:flex md:items-center md:justify-between">
            <DataTable class="flex w-full flex-col rounded-lg border border-gray-100 transition-all duration-500" :items="branches" :columns="columns" />
        </div>
    </Settings>
</template>
