import { ref } from 'vue'

export function useFilter() {
    const showFilters = ref(false)

    function toggleFilters() {
        showFilters.value = !showFilters.value
    }

    return { showFilters, toggleFilters }
}
