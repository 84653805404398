<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useUserCan } from '@app/composables/useUserCan'
import type { Lead } from '@app/types/recruitment'
import { PhoneIcon } from '@heroicons/vue/20/solid'
import { computed, inject } from 'vue'

const { can } = useUserCan()

const props = defineProps<{
    lead: Lead
}>()

const { startOutgoingCall, callInProgress } = inject<any>('twilio')

const isShown = computed(() => props.lead.is_contactable && props.lead.funnel_block.slug !== 'onboarding' && can('call.call'))

const isDisabled = computed(() => !props.lead.phone_number || callInProgress.value)
</script>

<template>
    <RoundButton v-if="isShown" :label="$t('recruitment.leads.card.actions.call')" :disabled="isDisabled" @click="() => startOutgoingCall(lead)">
        <PhoneIcon class="h-4 w-4" aria-hidden="true" />
    </RoundButton>
</template>
