<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { type ShallowRef, inject, type Component, computed, type PropType } from 'vue'
import { ArrowPathIcon } from '@heroicons/vue/24/solid'
import { useUserCan } from '@app/composables/useUserCan'
import type { Lead } from '@app/types/recruitment'
import LeadRestoreForm from '../forms/LeadRestoreForm.vue'

const props = defineProps({
    lead: {
        type: Object as PropType<Lead>,
        required: true,
    },
})

const activeButton = inject<ShallowRef<Component>>('activeButton')
const { can } = useUserCan()

const restorableStatuses = ['unreachable', 'declined']

const showRestoreLead = computed(() => restorableStatuses.includes(props.lead.funnel_block_status.slug) && can('recruitment.lead.restore'))
</script>

<template>
    <RoundButton v-if="showRestoreLead" :label="$t('recruitment.leads.card.actions.restore')" @click="activeButton = LeadRestoreForm">
        <ArrowPathIcon class="h-4 w-4" aria-hidden="true" />
    </RoundButton>
</template>
