<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useActiveButton } from '@app/composables/useDetailCard'
import { useUserCan } from '@app/composables/useUserCan'
import type { Contract } from '@app/types/contracts'
import { CheckIcon } from '@heroicons/vue/16/solid'
import { router } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps<{
    contract: Contract
}>()

const { can } = useUserCan()
const { hide } = useActiveButton()

const show = computed(() => can('contract.approve-signed') && props.contract.status.slug === 'signed')

function approve() {
    router.post(
        route('contract.approve-signed', props.contract.id),
        {},
        {
            onSuccess() {
                hide()
            },
        }
    )
}
</script>

<template>
    <RoundButton v-if="show" :label="$t('contract.actions.approve')" @click="approve">
        <CheckIcon class="size-5" aria-hidden="true" />
    </RoundButton>
</template>
