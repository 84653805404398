<script setup lang="ts">
import FileDownload from '@app/components/ui/button/FileDownload.vue'
import DetailItem from '@app/components/ui/details/DetailItem.vue'
import { useLocale } from '@app/composables/useLocale'
import type { Contract } from '@app/types/contracts'
import type { UserPersonalInformation } from '@app/types/shared'
import { getZonedDate } from '@app/utils/date'
import { isImage } from '@app/utils/file'
import { computed, type PropType } from 'vue'

const props = defineProps({
    contract: {
        type: Object as PropType<Contract>,
        required: true,
    },
    personalInformation: {
        type: Object as PropType<UserPersonalInformation>,
        required: true,
    },
})

const { translate } = useLocale()

const idFront = computed(() => getFilePath('id_front'))
const idBack = computed(() => getFilePath('id_back'))

function getFilePath(type: string) {
    return props.personalInformation.files.find((file) => file.type === type)?.path
}
</script>

<template>
    <div class="mt-4 grid grid-cols-2 gap-4">
        <DetailItem :label="$t('attributes.first_name')">
            {{ personalInformation.first_name }}
        </DetailItem>
        <DetailItem :label="$t('attributes.last_name')">
            {{ personalInformation.last_name }}
        </DetailItem>

        <DetailItem :label="$t('attributes.phone_number')">
            {{ personalInformation.phone_number }}
        </DetailItem>
        <DetailItem :label="$t('attributes.email')">
            {{ personalInformation.email }}
        </DetailItem>

        <DetailItem :label="$t('attributes.date_of_birth')">
            {{ getZonedDate(personalInformation.date_of_birth) }}
        </DetailItem>
        <DetailItem :label="$t('attributes.place_of_birth')">
            {{ personalInformation.place_of_birth }}
        </DetailItem>

        <DetailItem :label="$t('attributes.address')">
            {{ personalInformation.street }} {{ personalInformation.house_number }}{{ personalInformation.house_number_suffix }}
        </DetailItem>
        <DetailItem :label="$t('attributes.postal_code')">
            {{ personalInformation.postal_code }}
        </DetailItem>

        <DetailItem :label="$t('attributes.place_of_residence')">
            {{ personalInformation.place_of_residence }}
        </DetailItem>
        <DetailItem :label="$t('attributes.country')">
            {{ translate(personalInformation.country.name) }}
        </DetailItem>

        <template v-if="idFront && idBack">
            <template v-if="contract.status.slug === 'signed'">
                <DetailItem class="col-span-2" :label="$t('attributes.id_front')">
                    <img v-if="isImage(idFront)" :src="idFront" :alt="$t('attributes.id_front')" class="mt-1 max-w-sm" />
                    <FileDownload v-else :name="$t('attributes.id_front')" :path="idFront" class="mt-1" />
                </DetailItem>
                <DetailItem class="col-span-2" :label="$t('attributes.id_back')">
                    <img v-if="isImage(idBack)" :src="idBack" :alt="$t('attributes.id_back')" class="mt-1 max-w-sm" />
                    <FileDownload v-else :name="$t('attributes.id_back')" :path="idBack" class="mt-1" />
                </DetailItem>
            </template>

            <DetailItem v-else :label="$t('attributes.passport')">
                <FileDownload :name="$t('attributes.id_front')" :path="idFront" class="mt-1" />
                <FileDownload :name="$t('attributes.id_back')" :path="idBack" class="mt-1" />
            </DetailItem>
        </template>
    </div>
</template>
