<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'
import { compileTemplate } from '@app/utils/template/templateCompiler'
import type { Template } from '@app/types/template'
import { useModelSelect } from '@app/composables/useModelSelect'

const switcherTypes = ['button', 'dropdown'] as const

const props = withDefaults(
    defineProps<{
        as: (typeof switcherTypes)[number]
        templates: Template[]
        modelValue?: Template
        aliases: { [alias: string]: string }
    }>(),
    {
        as: 'button',
    }
)

const selectedTemplate = useModelSelect(props.templates)

const emit = defineEmits<{
    (event: 'update:modelValue', value: Template): void
}>()

function switchTemplate(template: Template) {
    emit('update:modelValue', {
        ...template,
        content: compileTemplate(template.content, props.aliases),
    })
}
</script>

<template>
    <div class="block text-sm font-medium text-gray-700">{{ $t('email.template_label') }}</div>

    <Dropdown
        id="email_template_dropdown"
        v-if="as === 'dropdown'"
        :items="templates"
        :translateDb="true"
        propertyName="name"
        :label="$t('email.template_placeholder')"
        @change="switchTemplate"
        v-model="selectedTemplate"
    />

    <div v-if="as === 'button'" class="m-2 flex flex-wrap gap-4">
        <Button v-for="template in templates" color="white" size="sm" @click="() => switchTemplate(template)">{{ $t(template.name) }}</Button>
    </div>
</template>
