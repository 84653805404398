import type { Contract } from '@app/types/contracts'
import { addMonths, formatDuration, subDays } from 'date-fns'

export function calculateEndDate(startDate: Date, durationInMonths: number | null) {
    if (!durationInMonths) {
        return null
    }

    return subDays(addMonths(startDate, durationInMonths), 1)
}

export function formatContractHours(minHours: number, maxHours: number | null) {
    if (maxHours === null || minHours === maxHours) {
        return minHours.toString()
    }

    return `${minHours} - ${maxHours}`
}

export function formatContractDuration(durationInMonths: number | null, locale: Locale) {
    if (!durationInMonths) {
        return 'contract.permanent'
    }

    return formatDuration({ months: durationInMonths }, { locale: locale })
}

export function resolveUserNameFromContract(contract: Contract) {
    return contract.user?.full_name || contract.contract_personal_information?.full_name || contract.lead?.full_name || contract.email
}
