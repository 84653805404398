<script setup>
import ConfirmsPassword from '@app/components/jetstream/ConfirmsPassword.vue'
import Button from '@app/components/ui/button/Button.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import Input from '@app/components/ui/form/Input.vue'
import { useTwoFactorRecoveryCodes } from '@app/composables/fortify/useTwoFactorRecoveryCodes'
import { router, useForm, usePage } from '@inertiajs/vue3'
import { computed, ref, watch } from 'vue'

const props = defineProps({
    requiresConfirmation: Boolean,
})

const enabling = ref(false)
const confirming = ref(false)
const disabling = ref(false)
const qrCode = ref(null)
const setupKey = ref(null)

const confirmationForm = useForm({
    code: '',
})

const { recoveryCodes, regenerateRecoveryCodes, showRecoveryCodes } = useTwoFactorRecoveryCodes()

const twoFactorEnabled = computed(() => !enabling.value && usePage().props.user?.two_factor_enabled)

watch(twoFactorEnabled, () => {
    if (!twoFactorEnabled.value) {
        confirmationForm.reset()
        confirmationForm.clearErrors()
    }
})

const enableTwoFactorAuthentication = () => {
    enabling.value = true
    router.post(
        '/user/two-factor-authentication',
        {},
        {
            preserveScroll: true,
            onSuccess: () => Promise.all([showQrCode(), showSetupKey(), showRecoveryCodes()]),
            onFinish: () => {
                enabling.value = false
                confirming.value = props.requiresConfirmation
            },
        }
    )
}

const showQrCode = () => {
    return axios.get('/user/two-factor-qr-code').then((response) => {
        qrCode.value = response.data.svg
    })
}

const showSetupKey = () => {
    return axios.get('/user/two-factor-secret-key').then((response) => {
        setupKey.value = response.data.secretKey
    })
}

const confirmTwoFactorAuthentication = () => {
    confirmationForm.post('/user/confirmed-two-factor-authentication', {
        errorBag: 'confirmTwoFactorAuthentication',
        preserveScroll: true,
        preserveState: true,
        onSuccess: () => {
            confirming.value = false
            qrCode.value = null
            setupKey.value = null
        },
    })
}

const disableTwoFactorAuthentication = () => {
    disabling.value = true

    router.delete('/user/two-factor-authentication', {
        preserveScroll: true,
        onSuccess: () => {
            disabling.value = false
            confirming.value = false
            confirmationForm.code = null
        },
    })
}
</script>

<template>
    <div class="flex w-full flex-col sm:w-1/2">
        <h2 class="text-lg font-semibold leading-7">
            {{ $t('two_factor_form.title') }}
        </h2>
        <p class="mt-1 text-sm leading-6 text-zinc-400">{{ $t('two_factor_form.description') }}</p>
    </div>
    <div class="w-full sm:w-1/2">
        <h3 v-if="twoFactorEnabled && !confirming" class="text-md font-medium text-zinc-900">
            {{ $t('two_factor_form.content_enabled') }}
        </h3>

        <h3 v-else-if="twoFactorEnabled && confirming" class="text-md font-medium text-zinc-900">
            {{ $t('two_factor_form.content_confirm_enable') }}
        </h3>

        <h3 v-else class="text-md font-medium text-zinc-900">
            {{ $t('two_factor_form.content_disabled') }}
        </h3>

        <div v-if="twoFactorEnabled">
            <div v-if="qrCode">
                <div class="mt-4 max-w-xl text-sm text-zinc-600">
                    <p v-if="confirming" class="font-semibold">
                        {{ $t('two_factor_form.confirm_scan_qr') }}
                    </p>
                    <p v-else>
                        {{ $t('two_factor_form.confirm_success') }}
                    </p>
                </div>

                <div class="mt-4">
                    <img :src="qrCode" class="w-[220px]" />
                </div>

                <div class="mt-4 max-w-xl text-sm text-zinc-600" v-if="setupKey">
                    <p class="font-semibold">
                        {{ $t('two_factor_form.setup_key') }}
                        <span v-html="setupKey"></span>
                    </p>
                </div>

                <Form v-if="confirming" :form="confirmationForm" class="mt-4">
                    <FormField prop="code" label="Code">
                        <Input
                            id="code"
                            v-model="confirmationForm.code"
                            autocomplete="one-time-code"
                            inputmode="numeric"
                            autofocus
                            @keyup.enter="confirmTwoFactorAuthentication"
                        />
                    </FormField>
                </Form>
            </div>

            <div v-if="recoveryCodes.length > 0 && !confirming">
                <div class="mt-4 max-w-xl text-sm text-zinc-600">
                    <p class="font-semibold">
                        {{ $t('two_factor_form.store_recovery_codes') }}
                    </p>
                </div>

                <div class="mt-4 grid max-w-xl gap-1 rounded-lg bg-zinc-100 px-4 py-4 font-mono text-sm">
                    <div v-for="code in recoveryCodes" :key="code">
                        {{ code }}
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-5 flex">
            <template v-if="!twoFactorEnabled">
                <ConfirmsPassword @confirmed="enableTwoFactorAuthentication">
                    <Button color="primary" :disabled="enabling">
                        {{ $t('two_factor_form.button_enable') }}
                    </Button>
                </ConfirmsPassword>
            </template>

            <template v-else>
                <ConfirmsPassword @confirmed="confirmTwoFactorAuthentication">
                    <Button v-if="confirming" class="mr-3" :disabled="enabling" color="black">
                        {{ $t('buttons.confirm') }}
                    </Button>
                </ConfirmsPassword>

                <ConfirmsPassword @confirmed="regenerateRecoveryCodes">
                    <Button v-if="recoveryCodes.length > 0 && !confirming" class="mr-3" color="white">
                        {{ $t('two_factor_form.regenerate_recovery_codes') }}
                    </Button>
                </ConfirmsPassword>

                <ConfirmsPassword @confirmed="showRecoveryCodes">
                    <Button v-if="recoveryCodes.length === 0 && !confirming" class="mr-3" color="white">
                        {{ $t('two_factor_form.show_recovery_codes') }}
                    </Button>
                </ConfirmsPassword>

                <ConfirmsPassword @confirmed="disableTwoFactorAuthentication">
                    <Button v-if="confirming" :disabled="disabling" color="white">
                        {{ $t('buttons.cancel') }}
                    </Button>
                </ConfirmsPassword>

                <ConfirmsPassword @confirmed="disableTwoFactorAuthentication">
                    <Button v-if="!confirming" :disabled="disabling" color="red">
                        {{ $t('two_factor_form.button_disable') }}
                    </Button>
                </ConfirmsPassword>
            </template>
        </div>
    </div>
</template>
