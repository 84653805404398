import last from 'lodash/last'

export function extractFileExtension(filename: string) {
    return last(filename.split('.'))
}

export function isImage(file?: File | string | null) {
    if (file instanceof File) {
        return file.type.startsWith('image/')
    }

    if (file) {
        const url = new URL(file)
        const [_, extension] = url.pathname.split('.')

        return ['jpeg', 'jpg', 'png', 'svg'].includes(extension)
    }

    return false
}
