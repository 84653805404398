<script setup lang="ts">
import { useLocale } from '@app/composables/useLocale'
import { getZonedDateTime } from '@app/utils/date'

defineProps({
    lead: {
        type: Object,
        required: true,
    },
})

const { dbTranslate } = useLocale()
</script>

<template>
    <div class="mt-4 flex w-full flex-wrap justify-start gap-4 text-left">
        <div class="mr-6 flex flex-col justify-start space-y-2 md:w-1/2">
            <div class="flex flex-col flex-wrap">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('recruitment.leads.card.details.status') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ dbTranslate(lead.funnel_block_status.name) }}</span>
            </div>
            <div class="flex flex-col flex-wrap">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('recruitment.leads.card.details.email') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ lead.email }}</span>
            </div>
            <div class="flex flex-col flex-wrap">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('recruitment.leads.card.details.registration') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ getZonedDateTime(lead.registered_at) }}</span>
            </div>
            <div class="flex flex-col flex-wrap">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('recruitment.leads.index.entered_funnel_at') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ getZonedDateTime(lead.entered_funnel_at) }}</span>
            </div>
        </div>
        <div class="flex flex-col justify-start space-y-2">
            <div class="flex flex-col flex-wrap">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('recruitment.leads.card.details.phone') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ lead.phone_number }}</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('recruitment.leads.card.details.channel') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ dbTranslate(lead.channel.name) + ' (' + lead.channel.type.category + ')' }}</span>
            </div>
            <div class="flex flex-col">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('recruitment.leads.card.details.branch') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ lead.branch.name }}</span>
            </div>
        </div>
    </div>
</template>
