<script setup lang="ts">
import { ListboxOption } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/24/solid'
import { inject, type Ref } from 'vue'

const { value, label } = defineProps<{ value: string | number; label: string }>()

const selected = inject('dropdown:registry') as Ref<Map<typeof value, string>>

selected.value.set(value, label)
</script>

<template>
    <ListboxOption as="template" :value v-slot="{ active, selected }">
        <li :class="[active ? 'bg-primary-600 text-white' : 'text-zinc-900', 'relative cursor-default select-none py-2 pl-3 pr-9']" :data-value="value">
            <div class="flex items-center">
                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate sm:ml-3']">
                    <slot>
                        {{ label }}
                    </slot>
                </span>
            </div>
            <span v-if="selected" :class="[active ? 'text-white' : 'text-primary-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                <CheckIcon class="size-5" aria-hidden="true" />
            </span>
        </li>
    </ListboxOption>
</template>
