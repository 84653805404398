<script setup lang="ts">
import { provide } from 'vue'

const model = defineModel({ default: [] })

provide('multi-toggle:model', model)
</script>

<template>
    <div class="flex gap-3 text-xs leading-6 text-zinc-500">
        <slot></slot>
    </div>
</template>
