<script setup>
import { nextTick, reactive, ref } from 'vue'
import Button from '../ui/button/Button.vue'
import FieldError from '../ui/form/FieldError.vue'
import Input from '../ui/form/Input.vue'
import DialogModal from './DialogModal.vue'

const emit = defineEmits(['confirmed'])
const confirmingPassword = ref(false)

const form = reactive({
    password: '',
    error: '',
    processing: false,
})

const passwordInput = ref(null)

const startConfirmingPassword = () => {
    axios.get(route('password.confirmation')).then((response) => {
        if (response.data.confirmed) {
            emit('confirmed')
        } else {
            confirmingPassword.value = true

            setTimeout(() => passwordInput.value.focus(), 250)
        }
    })
}

const confirmPassword = () => {
    form.processing = true

    axios
        .post(route('password.confirm'), {
            password: form.password,
        })
        .then(() => {
            form.processing = false

            closeModal()
            nextTick().then(() => emit('confirmed'))
        })
        .catch((error) => {
            form.processing = false
            form.error = error.response.data.errors.password[0]
            passwordInput.value.focus()
        })
}

const closeModal = () => {
    confirmingPassword.value = false
    form.password = ''
    form.error = ''
}
</script>

<template>
    <span>
        <span @click="startConfirmingPassword">
            <slot />
        </span>

        <DialogModal :show="confirmingPassword" @close="closeModal">
            <template #title>
                {{ $t('passwords.confirm.title') }}
            </template>

            <template #content>
                {{ $t('passwords.confirm.content') }}

                <div class="mt-4">
                    <Input
                        ref="passwordInput"
                        v-model="form.password"
                        type="password"
                        class="mt-1 block w-3/4"
                        placeholder="Password"
                        @keyup.enter="confirmPassword"
                    />

                    <FieldError :message="form.error" class="mt-2" />
                </div>
            </template>

            <template #footer>
                <Button color="white" @click="closeModal">{{ $t('buttons.cancel') }}</Button>
                <Button @click="confirmPassword" :disabled="form.processing" class="ml-3">{{ $t('buttons.confirm') }}</Button>
            </template>
        </DialogModal>
    </span>
</template>
