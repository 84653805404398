<script setup lang="ts">
import ActivityFeedTemplate from '@app/components/ui/activityFeed/ActivityFeedTemplate.vue'
import ActivityFeedTemplateHeader from '@app/components/ui/activityFeed/ActivityFeedTemplateHeader.vue'
import Badge from '@app/components/ui/badge/Badge.vue'
import { useColor } from '@app/composables/useColor'
import type { FunnelBlockActivityFeed } from '@app/types/activity-feed'
import type { FunnelBlock } from '@app/types/recruitment'
import { ForwardIcon } from '@heroicons/vue/20/solid'
import { usePage } from '@inertiajs/vue3'
import { computed, inject } from 'vue'

const { getFunnelBlockColor } = useColor()

const props = defineProps<{
    activity: FunnelBlockActivityFeed
}>()

const blocks = inject<FunnelBlock[]>('funnelBlocks')

const getBlock = (slug: string) => {
    return blocks.find((block: FunnelBlock) => {
        return block.slug === slug
    })
}

const oldBlock = computed(() => getBlock(props.activity.old_block))
const newBlock = computed(() => getBlock(props.activity.new_block))

const getLabel = (block: FunnelBlock) => {
    return block?.name[usePage().props.userLanguage.slug]
}
</script>

<template>
    <ActivityFeedTemplate>
        <template #icon>
            <ForwardIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
        </template>
        <template #header>
            <ActivityFeedTemplateHeader
                :name="activity.causer_full_name"
                :header="$t('activities.recruitment.lead_updated_block_header')"
                :created_at="activity.created_at"
            />
        </template>
        <template #content>
            {{ $t('attributes.block') }}
            {{ $t('activities.updated_from') }}
            <Badge :label="getLabel(oldBlock)" :color="getFunnelBlockColor(activity.old_block)" />
            {{ $t('activities.updated_to') }}
            <Badge :label="getLabel(newBlock)" :color="getFunnelBlockColor(activity.new_block)" />
        </template>
    </ActivityFeedTemplate>
</template>
