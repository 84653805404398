<script setup lang="ts">
import { getDateFromDateTime } from '@app/utils/date'
import { computed } from 'vue'

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    name: {
        type: String,
        required: true,
    },
})

const date = computed(() => getDateFromDateTime(props.item[props.name], 'short'))
</script>

<template>
    <div>
        <div class="text-gray-900">
            {{ date }}
        </div>
    </div>
</template>
