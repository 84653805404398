import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    type ChartEvent,
    type LegendElement,
    type LegendItem,
} from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend)
const defaultLegendClickHandler = ChartJS.defaults.plugins.legend.onClick

export const tailwindColors = {
    fonkyBlue: '#24a1da',
    fonkyOrange: '#da5d24',
    zinc400: '#A1A1AA',
    zinc300: '#D4D4D8',
}

export const commonOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            grid: {
                display: false,
            },
            border: {
                color: tailwindColors.zinc300,
            },
            ticks: {
                maxRotation: 0,
                minRotation: 0,
                color: tailwindColors.zinc400,
                autoSkip: false,
                includeBounds: true,
                font: {
                    size: 12,
                },
            },
        },
        y: {
            grid: {
                display: false,
            },
            border: {
                display: false,
            },
            ticks: {
                display: false,
            },
        },
    },
    plugins: {
        legend: {
            position: 'top',
            align: 'end',
            labels: {
                font: {
                    size: 12,
                },
                // usePointStyle: true,
                pointStyle: 'line',
            },
            onClick(event: ChartEvent, legendItem: LegendItem, legend: LegendElement<'line'>) {
                defaultLegendClickHandler(event, legendItem, legend)
            },
        },
    },
    pointBackgroundColor: 'rgba(0, 0, 0, 0)',
    pointBorderColor: 'rgba(0, 0, 0, 0)',
    tension: 0.5,
    pointRadius: 10,
    pointHoverRadius: 6,
}

export const chartDefaults = () => {
    ChartJS.defaults.font.family = 'Inter'
    ChartJS.defaults.font.weight = 'normal'
}
