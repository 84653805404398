<script setup lang="ts">
import CancellationForm from '@app/components/contracts/CancellationForm.vue'
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import type { Contract, ContractCancellationReason } from '@app/types/contracts'
import { XCircleIcon } from '@heroicons/vue/24/solid'
import { type Component, type ShallowRef, computed, inject } from 'vue'

const activeButton = inject<ShallowRef<Component>>('activeButton')

const props = defineProps<{
    contract: Contract
}>()

const cancellationReasons = inject('contract:cancellationReasons') as ContractCancellationReason[]

const showCancelButton = computed(() => cancellationReasons.length > 0 && ['unsigned', 'signed', 'approved'].includes(props.contract.status.slug))
</script>

<template>
    <RoundButton v-if="showCancelButton" :label="$t('contract.actions.cancel')" @click="activeButton = CancellationForm">
        <XCircleIcon />
    </RoundButton>
</template>
