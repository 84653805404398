<script setup lang="ts">
import ActivityFeedTemplate from '@app/components/ui/activityFeed/ActivityFeedTemplate.vue'
import ActivityFeedTemplateHeader from '@app/components/ui/activityFeed/ActivityFeedTemplateHeader.vue'
import { useLocale } from '@app/composables/useLocale'
import type { LeadUpdateActivityFeed } from '@app/types/activity-feed'
import type { Money } from '@app/types/shared'
import { getZonedDate } from '@app/utils/date'
import { ucfirst } from '@app/utils/transform'
import { PencilSquareIcon } from '@heroicons/vue/20/solid'
import { usePage } from '@inertiajs/vue3'

const props = defineProps<{
    activity: LeadUpdateActivityFeed
    casts?: Record<string, string>
}>()

const { dbTranslate } = useLocale()

const getAttribute = (key: string, value: unknown) => {
    if (!props.casts?.[key]) {
        return value
    }

    switch (props.casts[key]) {
        case 'date':
            return getZonedDate(value as string | null)
        case 'branch':
            return usePage().props.branches?.find((branch) => branch.id === (value as number))?.name
        case 'money':
            return (value as Money).formatted
        case 'contract_hour_type':
            const contractHourType = usePage().props.contractHourTypes?.find((item) => item.id === (value as number))?.name

            return contractHourType ? dbTranslate(contractHourType) : value
        case 'job_title':
            const jobTitleName = usePage().props.jobTitles?.find((item) => item.id === (value as number))?.name

            return jobTitleName ? dbTranslate(jobTitleName) : value
    }
}
</script>

<template>
    <ActivityFeedTemplate>
        <template #icon>
            <PencilSquareIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
        </template>
        <template #header>
            <ActivityFeedTemplateHeader
                :name="activity.causer_full_name"
                :header="$t('activities.updated_header', { subject: $t(`activities.subject.${activity.subject}`) })"
                :created_at="activity.created_at"
            />
        </template>
        <template #content>
            <ul class="ml-4 mt-2 list-disc">
                <li v-for="(oldAttribute, key) in activity.old_attributes">
                    {{ ucfirst($t(`attributes.${key}`)) }}
                    {{ $t('activities.updated_from') }}
                    <span class="italic">{{ getAttribute(key, oldAttribute) ?? $t('history.empty') }}</span>
                    {{ $t('activities.updated_to') }}
                    <span class="italic">{{ getAttribute(key, activity.new_attributes[key]) ?? $t('history.empty') }}</span>
                </li>
            </ul>
        </template>
    </ActivityFeedTemplate>
</template>
