<script setup lang="ts">
import { Head, useForm } from '@inertiajs/vue3'
import Form from '@app/components/ui/form/Form.vue'
import { route } from 'ziggy-js'
import FormField from '@app/components/ui/form/FormField.vue'
import Input from '@app/components/ui/form/Input.vue'
import Button from '@app/components/ui/button/Button.vue'
import Auth from '@app/layouts/Auth.vue'

defineOptions({
    layout: Auth,
})

const props = defineProps({
    email: String,
    token: String,
    expired: Boolean,
})

const form = useForm({
    token: props.token,
    email: props.email,
    password: '',
    password_confirmation: '',
})

function resetPassword() {
    form.post(route('password.update'), {
        onFinish: () => form.reset('password', 'password_confirmation'),
    })
}
</script>

<template>
    <Head title="Reset Password" />

    <Form :form="form" @submit="resetPassword" class="space-y-4">
        <div v-if="expired" class="font-medium text-red-600">
            {{ $t('passwords.token') }}
        </div>

        <FormField prop="email" :label="$t('attributes.email')">
            <Input id="email" v-model="form.email" type="email" required :disabled="expired" />
        </FormField>

        <FormField prop="password" :label="$t('attributes.password')">
            <Input id="password" v-model="form.password" type="password" autocomplete="new-password" required :disabled="expired" />
        </FormField>

        <FormField prop="password_confirmation" :label="$t('attributes.password_confirmation')">
            <Input id="password_confirmation" v-model="form.password_confirmation" type="password" required :disabled="expired" />
        </FormField>

        <div class="mt-4 flex items-center justify-end">
            <Button type="black" :disabled="form.processing || expired">
                {{ $t('passwords.reset.button') }}
            </Button>
        </div>
    </Form>
</template>
