const MFS_URL = import.meta.env.VITE_APPLICATIONS_MFS_URL

export function getMfsLeadCreateUrl() {
    return `${MFS_URL}recruitment/leadadvanced/add`
}

export function getMfsOnboardingFormUrl(id: number) {
    return `${MFS_URL}users/leaduser/add/${id}`
}

export function getMfsOnboardingIndexUrl() {
    return `${MFS_URL}recruitment/introductiontrack`
}
