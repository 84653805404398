<script setup lang="ts">
import { useForm, usePage } from '@inertiajs/vue3'
import VueDatePicker from '@vuepic/vue-datepicker'
import { useLocale } from '@app/composables/useLocale'
import { route } from 'ziggy-js'
import Form from '../ui/form/Form.vue'
import FormField from '../ui/form/FormField.vue'
import Dropdown from '../ui/dropdown/Dropdown.vue'
import SaveButton from '../ui/button/SaveButton.vue'
import CancelButton from '../ui/button/CancelButton.vue'
import { useModelSelect } from '@app/composables/useModelSelect'
import type { Contract, ContractTerminationReason } from '@app/types/contracts'
import { formatISO } from 'date-fns'
import FormActions from '../ui/form/FormActions.vue'

const { getLocale } = useLocale()

const props = defineProps<{
    model: Contract
}>()

const reasons = usePage().props.terminationReasons as ContractTerminationReason[]
const selectedReason = useModelSelect(reasons)
const emit = defineEmits(['content:closed'])

const form = useForm({
    reason: null,
    terminationDate: null,
})

const handleClose = () => {
    form.reset()
    emit('content:closed')
}

const submitTerminationForm = () => {
    form.transform((data) => ({
        ...data,
        terminationDate: data.terminationDate ? formatISO(data.terminationDate, { representation: 'date' }) : null,
    })).post(route('contract.terminate', props.model.id), {
        onSuccess: () => {
            handleClose()
        },
    })
}
</script>

<template>
    <Form :form="form" @submit="submitTerminationForm">
        <FormField prop="terminationDate" :label="$t('contract.termination.date')" class="col-span-12">
            <VueDatePicker
                id="termination-date"
                v-model="form.terminationDate"
                :locale="getLocale().code"
                :auto-position="false"
                auto-apply
                :enable-time-picker="false"
                text-input
                :text-input-options="{
                    format: 'dd-MM-yyyy',
                }"
                format="dd-MM-yyyy"
                class="mt-1 block w-full"
            />
        </FormField>
        <FormField prop="reason" class="col-span-12 mt-3" :label="$t('contract.termination.reason')">
            <Dropdown
                :items="reasons"
                propertyName="name"
                :label="$t('contract.termination.select_reason')"
                v-model="selectedReason"
                v-model:form="form.reason"
                :translateDb="true"
            />
        </FormField>

        <FormActions>
            <CancelButton @click="handleClose()" />
            <SaveButton>{{ $t('contract.actions.terminate') }}</SaveButton>
        </FormActions>
    </Form>
</template>
