<script setup lang="ts">
import { useDetailCard } from '@app/composables/useDetailCard'
import { useLocale } from '@app/composables/useLocale'
import type { Contract } from '@app/types/contracts'
import { resolveUserNameFromContract } from '@app/utils/contracts/contract'
import { Bars4Icon, DocumentMagnifyingGlassIcon, UserIcon } from '@heroicons/vue/20/solid'
import { toRefs } from 'vue'
import ActivityFeed from '../activityFeeds/ActivityFeed.vue'
import ContractStatusActivityFeed from '../activityFeeds/contracts/ContractStatusActivityFeed.vue'
import UpdatedActivityFeed from '../activityFeeds/logs/UpdatedActivityFeed.vue'
import AddNote from '../notes/AddNote.vue'
import Badge from '../ui/badge/Badge.vue'
import DetailCard from '../ui/card/DetailCard.vue'
import DetailCardTab from '../ui/card/DetailCardTab.vue'
import ApproveContract from './actions/ApproveContract.vue'
import ApproveSignedContract from './actions/ApproveSignedContract.vue'
import CancelContract from './actions/CancelContract.vue'
import DeclineSignedContract from './actions/DeclineSignedContract.vue'
import DeleteContract from './actions/DeleteContract.vue'
import EditContract from './actions/EditContract.vue'
import RenewContract from './actions/RenewContract.vue'
import TerminateContract from './actions/TerminateContract.vue'
import ContractDetails from './card/tabs/ContractDetails.vue'
import ContractPersonalInformation from './card/tabs/ContractPersonalInformation.vue'
import FinancialInformation from './card/tabs/FinancialInformation.vue'
import UserContracts from './card/tabs/UserContracts.vue'
import ContractStatus from './ContractStatus.vue'

const props = defineProps<{ contract: Contract }>()

const { contract } = toRefs(props)

const { toggleCard } = useDetailCard(contract, 'selectedContract')
const { dbTranslate } = useLocale()
</script>

<template>
    <DetailCard :model="contract" class="md:min-w-[29rem] lg:w-2/3" :dismissable="true" @close="toggleCard(contract.id)">
        <div class="flex flex-col items-center gap-4">
            <span class="w-full text-center text-lg font-medium leading-6 text-gray-900">{{ resolveUserNameFromContract(contract) }}</span>
            <Badge :round="false" size="sm" v-if="contract.job_title" :label="dbTranslate(contract.job_title.name)" color="gray" />
            <ContractStatus :contract class="text-center" />
        </div>
        <template #actions>
            <ApproveContract :contract />
            <EditContract :contract />
            <AddNote :model="contract" notable-type="contract" />
            <RenewContract :contract />
            <TerminateContract :contract />
            <ApproveSignedContract :contract />
            <DeclineSignedContract :contract />
            <CancelContract :contract />
            <DeleteContract :contract />
        </template>
        <template #tabs>
            <DetailCardTab tab="activity" label="Activity">
                <template #icon>
                    <Bars4Icon />
                </template>
                <ActivityFeed :activities="contract.combined_activity_feeds">
                    <template #contract-status-updated="{ activity }">
                        <ContractStatusActivityFeed :activity="activity" />
                    </template>

                    <template #activity-log-updated="{ activity }">
                        <UpdatedActivityFeed
                            :activity="activity"
                            :casts="{
                                start_date: 'date',
                                end_date: 'date',
                                branch_id: 'branch',
                                gross_monthly_salary: 'money',
                                phone_allowance_amount: 'money',
                                contract_hour_type_id: 'contract_hour_type',
                                job_title_id: 'job_title',
                            }"
                        />
                    </template>
                </ActivityFeed>
            </DetailCardTab>
            <DetailCardTab tab="contract" label="Contract">
                <template #icon>
                    <DocumentMagnifyingGlassIcon />
                </template>
                <ContractDetails :contract />
            </DetailCardTab>

            <DetailCardTab tab="details" label="Details">
                <template #icon>
                    <UserIcon />
                </template>
                <ContractPersonalInformation
                    v-if="contract.user || contract.contract_personal_information"
                    :contract
                    :personal-information="contract.user ?? contract.contract_personal_information"
                />

                <FinancialInformation
                    v-if="contract.user || contract.contract_personal_information"
                    :contract
                    :personal-information="contract.user ?? contract.contract_personal_information"
                />
            </DetailCardTab>

            <DetailCardTab v-if="contract.user && contract.user.contracts.length > 1" tab="contracts" label="Contracts">
                <template #icon>
                    <UserIcon />
                </template>

                <UserContracts :contracts="contract.user.contracts" :selected-contract-id="contract.id" />
            </DetailCardTab>
        </template>
    </DetailCard>
</template>
