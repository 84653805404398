<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { useForm } from '@inertiajs/vue3'
import type { Lead } from '@app/types/recruitment'
import type { EmailTemplate } from '@app/types/email'
import { route } from 'ziggy-js'
import TemplateSwitcher from '@app/components/template/TemplateSwitcher.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import Input from '@app/components/ui/form/Input.vue'
import Label from '@app/components/ui/form/Label.vue'
import FieldError from '@app/components/ui/form/FieldError.vue'
import CancelButton from '@app/components/ui/button/CancelButton.vue'
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import FormTextArea from '@app/components/ui/form/FormTextArea.vue'

const props = defineProps<{
    model: Lead
}>()

const selectedTemplate = ref<EmailTemplate>()

const emailAliases = computed(() => ({
    first_name: props.model.first_name,
    phone: props.model.phone_number,
}))

const emit = defineEmits(['content:closed'])

const form = useForm({
    mailable_id: props.model.id,
    mailable_type: 'recruitment_lead',
    to: props.model.email,
    email_template_id: null,
    subject: '',
    body_text: '',
})

function sendEmailMessage() {
    form.post(route('email.send'), {
        preserveScroll: true,
        onSuccess() {
            form.reset()
            closeContent()
        },
    })
}

function closeContent() {
    emit('content:closed')
}

function cancel() {
    form.reset()
    closeContent()
}

watch(selectedTemplate, () => {
    if (selectedTemplate.value) {
        form.email_template_id = selectedTemplate.value.id
        form.body_text = selectedTemplate.value.content
        form.subject = selectedTemplate.value.subject || ''
    }
})
</script>

<template>
    <section>
        <TemplateSwitcher :templates="model.email_templates" v-model="selectedTemplate" :aliases="emailAliases" as="dropdown" />
        <Form :form="form" @submit="sendEmailMessage" class="mt-2" id="email_form">
            <FormField prop="subject" :label="$t('email.subject')">
                <Input id="subject" v-model="form.subject" autocomplete="off" />
            </FormField>

            <Label for="body" class="mb-1 mt-2">{{ $t('email.body') }}</Label>
            <FormTextArea id="body_text" v-model="form.body_text" :rows="6" autocomplete="off" />
            <FieldError :message="form.errors.body_text" class="mt-2" />

            <div class="mt-4 flex place-content-end space-x-2">
                <CancelButton @click="cancel" />
                <SaveButton :disabled="form.processing" id="email_save_button">{{ $t('buttons.send') }}</SaveButton>
            </div>
        </Form>
    </section>
</template>
