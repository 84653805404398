<script setup>
import { ref } from 'vue'
import Pagination from '@app/components/ui/table/Pagination.vue'
import RolePermissionModal from '@app/components/auth/RolePermissionModal.vue'
import { useUserCan } from '@app/composables/useUserCan'
import Search from '@app/components/ui/table/Search.vue'
import { useHumanReadableDate } from '@app/composables/date/useHumanReadableDate'
import Settings from '@app/layouts/Settings.vue'
import Button from '@app/components/ui/button/Button.vue'
import { router } from '@inertiajs/vue3'
import { route } from 'ziggy-js'
import DataTable from '@app/components/ui/table/DataTable.vue'
import FilterSection from '@app/components/ui/table/FilterSection.vue'

const props = defineProps({
    roles: Object,
    permissions: Array,
})

const { can } = useUserCan()
const { getHumanReadableDate } = useHumanReadableDate()

const isPermissionUpdateOpen = ref(false)
const selectedRole = ref(null)

const openPermissionsModal = (role) => {
    selectedRole.value = role
    isPermissionUpdateOpen.value = true
}

const closePermissionsModal = (role) => {
    isPermissionUpdateOpen.value = false
    selectedRole.value = role
}

function createRole() {
    router.get(route('settings.role.create'))
}

const columns = [
    {
        name: 'role',
        header: 'Role',
    },
    {
        name: 'created_at',
        header: 'attributes.created_at',
    },
    {
        name: 'updated_at',
        header: 'attributes.updated_at',
    },
    {
        name: 'actions',
        header: 'actions.header',
        show: can('role.store'),
    },
]
</script>

<template>
    <Settings group="general">
        <div class="mb-4 md:flex md:items-center md:justify-between">
            <div class="flex place-items-center">
                <Button v-if="can('role.store')" @click="createRole"> {{ $t('actions.create') }} </Button>
            </div>
        </div>

        <DataTable :items="roles" :columns="columns">
            <template #filter>
                <FilterSection>
                    <Search routeName="settings.role.index" />
                </FilterSection>
            </template>

            <template v-slot:column.role="{ item }">
                <span class="font-medium text-gray-900">
                    {{ item.name }}
                </span>
            </template>

            <template v-slot:column.created_at="{ item }">
                <span class="text-gray-500">
                    {{ getHumanReadableDate(item.created_at) }}
                </span>
            </template>

            <template v-slot:column.updated_at="{ item }">
                <span class="text-gray-500">
                    {{ getHumanReadableDate(item.updated_at) }}
                </span>
            </template>

            <template v-slot:column.actions="{ item }">
                <span class="text-gray-900">
                    <button
                        @click="openPermissionsModal(item)"
                        class="duration-50 relative h-7 self-center rounded-full border bg-secondary-100 px-3 transition ease-in hover:bg-secondary-200 md:ml-0"
                    >
                        {{ item.permission_ids.length }}
                        {{ item.permission_ids.length === 1 ? 'permission' : 'permissions' }}
                    </button>
                </span>
            </template>
        </DataTable>

        <RolePermissionModal
            v-if="isPermissionUpdateOpen"
            :role="selectedRole"
            :permissions="permissions"
            :show="isPermissionUpdateOpen"
            @permissions:close="closePermissionsModal()"
        />
    </Settings>
</template>
