<script setup>
import { computed } from 'vue'
import { trans } from 'laravel-vue-i18n'
import DataTable from '@app/components/ui/table/DataTable.vue'
import Search from '@app/components/ui/table/Search.vue'
import { route } from 'ziggy-js'
import Settings from '@app/layouts/Settings.vue'

defineProps({
    marketingChannels: {
        type: Object,
        required: true,
    },
})

const columns = computed(() => [
    {
        type: 'string',
        header: 'marketing_channel.index.table.id',
        properties: { name: 'id' },
        sort: 'id',
    },
    {
        type: 'string',
        header: 'marketing_channel.index.table.name',
        properties: { name: 'name' },
        sort: 'name',
    },
    {
        type: 'string',
        header: 'marketing_channel.index.table.channel',
        properties: { name: 'channel_name' },
    },
    {
        type: 'string',
        header: 'marketing_channel.index.table.affiliate',
        properties: { name: 'affiliate_code' },
    },
    {
        type: 'date',
        header: 'marketing_channel.index.table.created_at',
        properties: { name: 'created_at' },
    },
    {
        type: 'link',
        header: 'marketing_channel.index.table.actions',
        properties: {
            resolveLinkProps: (marketingChannel) => ({
                label: trans('marketing_channel.index.table.edit'),
                href: route('settings.recruitment.marketing-channel.edit', {
                    id: marketingChannel.id,
                }),
            }),
        },
    },
])
</script>

<template>
    <Settings group="recruitment">
        <Search routeName="settings.recruitment.marketing-channel.index" />
        <div class="mt-4 flex items-start gap-4 scrollbar-hide">
            <DataTable
                class="flex w-full flex-col rounded-lg border border-gray-100 transition-all duration-500"
                :items="marketingChannels"
                :columns="columns"
                only="marketingChannels"
            />
        </div>
    </Settings>
</template>
