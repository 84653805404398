<script setup lang="ts">
import { useLocale } from '@app/composables/useLocale'
import type { OrderLine } from '@app/types/orders'
import { trans } from 'laravel-vue-i18n'

defineProps({
    order_lines: {
        type: Array<OrderLine>,
        required: true,
    },
})

const getPaymentFrequency = (paymentFrequency: string) => {
    return trans('orders.payment_frequencies.' + paymentFrequency)
}

const getPrice = (line: OrderLine) => {
    let price = line.price ? line.price.formatted : '€ 0,00'
    return line.product.is_incentive ? '- ' + price : price
}

const { dbTranslate } = useLocale()
</script>

<template>
    <div class="mt-4 flex w-full flex-wrap justify-start gap-4 text-left">
        <div v-for="line in order_lines" class="mr-6 flex flex-col justify-start space-y-2">
            <div class="flex flex-col flex-wrap">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('attributes.order_line_id') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ line.id }}</span>
            </div>
            <div class="flex flex-col flex-wrap">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('attributes.product') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ line.product.name }}</span>
            </div>
            <div class="flex flex-col flex-wrap">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('attributes.product_type') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ dbTranslate(line.product.product_type.name) }}</span>
            </div>
            <div class="flex flex-col flex-wrap">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('attributes.quantity') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ line.quantity }}</span>
            </div>
            <div class="flex flex-col flex-wrap">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('attributes.price') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ getPrice(line) }}</span>
            </div>
            <div class="flex flex-col flex-wrap">
                <span class="text-xs font-normal uppercase leading-4 text-gray-400">{{ $t('attributes.payment_frequency') }}</span>
                <span class="text-sm font-normal leading-5 text-gray-700">{{ getPaymentFrequency(line.payment_frequency) }}</span>
            </div>
        </div>
    </div>
</template>
