import type { Authenticatable } from '@app/types/shared'
import axios from 'axios'
import { ref } from 'vue'
import { route } from 'ziggy-js'

export function useTwoFactorRecoveryCodes(user?: Authenticatable) {
    const recoveryCodes = ref([])
    const processing = ref(false)

    let indexRoute = ''
    let storeRoute = ''

    if (user) {
        const userParams = {
            id: user.id,
            user_type: user.type,
        }

        indexRoute = route('two-factor.recovery-codes', userParams)
        storeRoute = route('two-factor.recovery-codes.store', userParams)
    } else {
        indexRoute = route('two-factor.recovery-codes.own')
        storeRoute = route('two-factor.recovery-codes.own')
    }

    async function showRecoveryCodes() {
        const { data } = await axios.get(indexRoute)

        recoveryCodes.value = data
    }

    async function regenerateRecoveryCodes() {
        processing.value = true

        await axios.post(storeRoute)

        await showRecoveryCodes()

        processing.value = false
    }

    return {
        recoveryCodes,
        processing,
        showRecoveryCodes,
        regenerateRecoveryCodes,
    }
}
