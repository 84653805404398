<script setup lang="ts">
import ActivityFeedTemplate from '@app/components/ui/activityFeed/ActivityFeedTemplate.vue'
import ActivityFeedTemplateHeader from '@app/components/ui/activityFeed/ActivityFeedTemplateHeader.vue'
import Badge from '@app/components/ui/badge/Badge.vue'
import { useColor } from '@app/composables/useColor'
import type { FunnelBlockStatusActivityFeed } from '@app/types/activity-feed'
import type { FunnelBlockStatus } from '@app/types/recruitment'
import { PlayIcon } from '@heroicons/vue/24/outline'
import { usePage } from '@inertiajs/vue3'
import { computed, inject } from 'vue'

const props = defineProps<{
    activity: FunnelBlockStatusActivityFeed
}>()

const { getFunnelBlockStatusColor } = useColor()
const statuses = inject<FunnelBlockStatus[]>('statuses')
const getStatus = (slug: string) => {
    return statuses.find((status) => {
        return status.slug === slug
    })
}

const oldStatus = computed(() => getStatus(props.activity.old_status))
const newStatus = computed(() => getStatus(props.activity.new_status))

const getStatusName = (status: FunnelBlockStatus) => {
    return status.name[usePage().props.userLanguage.slug]
}

const getLabel = (status: FunnelBlockStatus, attempts: number) => {
    return status.slug === 'call-attempt' ? getStatusName(status) + ' (' + attempts + ')' : getStatusName(status)
}
</script>

<template>
    <ActivityFeedTemplate>
        <template #icon>
            <PlayIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
        </template>
        <template #header>
            <ActivityFeedTemplateHeader :name="activity.causer_full_name" :header="$t('activities.updated_status')" :created_at="activity.created_at" />
        </template>
        <template #content>
            {{ $t('attributes.status') }}
            {{ $t('activities.updated_from') }}
            <Badge :label="getLabel(oldStatus, activity.old_call_attempts)" :color="getFunnelBlockStatusColor(activity.old_status)" />
            {{ $t('activities.updated_to') }}
            <Badge :label="getLabel(newStatus, activity.new_call_attempts)" :color="getFunnelBlockStatusColor(activity.new_status)" />
        </template>
    </ActivityFeedTemplate>
</template>
