<script setup lang="ts">
import Button from '@app/components/ui/button/Button.vue'
import Form from '@app/components/ui/form/Form.vue'
import FormActions from '@app/components/ui/form/FormActions.vue'
import FormField from '@app/components/ui/form/FormField.vue'
import Settings from '@app/layouts/Settings.vue'
import { useForm } from '@inertiajs/vue3'
import { route } from 'ziggy-js'
import type { JobTitle, JobTitleExternalApplication, ResourceCollection } from '@app/types/shared'
import { ref } from 'vue'
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'

const props = defineProps<{
    jobTitleExternalApplication: JobTitleExternalApplication
    jobTitles: ResourceCollection<JobTitle>
}>()

const selectedJobTitle = ref(props.jobTitleExternalApplication?.job_title)

const form = useForm({
    job_title_id: props.jobTitleExternalApplication.job_title?.id,
})

function submit() {
    form.put(route('settings.job-title-external-application.update', props.jobTitleExternalApplication.id))
}
</script>

<template>
    <Settings group="general">
        <Form :form="form" class="max-w-xl">
            <FormField prop="job_title_id" :label="$t('settings.job-title.header')" class="col-span-12">
                <Dropdown
                    :items="jobTitles"
                    :label="$t('settings.job-title.select')"
                    propertyName="name"
                    v-model="selectedJobTitle"
                    v-model:form="form.job_title_id"
                />
            </FormField>

            <FormActions>
                <Button :disabled="form.processing" @click="submit">{{ $t('buttons.save') }}</Button>
            </FormActions>
        </Form>
    </Settings>
</template>
