<script setup>
import CalendarInput from '@app/components/ui/form/CalendarInput.vue'
import { format } from 'date-fns'
import { computed, ref, watch, watchEffect } from 'vue'
import { useForm } from '@inertiajs/vue3'
import FieldError from '@app/components/ui/form/FieldError.vue'
import VueDatePicker from '@vuepic/vue-datepicker'
import { getTimeFromTimeObject } from '@app/utils/date'

const props = defineProps({
    lead: {
        type: Object,
        required: true,
    },
})

const emit = defineEmits(['content:closed'])

const selectedDay = ref(null)
const selectedFrom = ref()
const selectedTo = ref()
const manuallySelectedFrom = ref(false)
const defaultAppointmentMinutes = 15

const from = computed(() => (selectedFrom.value ? format(selectedDay.value, 'yyyy-MM-dd' + ' ' + getTimeFromTimeObject(selectedFrom.value)) : null))

const to = computed(() => (selectedTo.value ? format(selectedDay.value, 'yyyy-MM-dd' + ' ' + getTimeFromTimeObject(selectedTo.value)) : null))

const form = useForm({
    lead_id: props.lead.id,
    from: null,
    to: null,
})

watchEffect(() => {
    form.from = from.value
    form.to = to.value
})

const submitCallAppointment = () => {
    form.post(route('recruitment.appointment.call.store'), {
        preserveScroll: true,
        onSuccess() {
            emit('content:closed')
        },
    })
}

const setSelectedDay = (day) => {
    selectedDay.value = day
}

const timeInputOptions = ref({
    format: 'HH:mm',
})

watch(
    () => selectedFrom.value,
    function (newValue) {
        if (manuallySelectedFrom.value || newValue === null) {
            return
        }

        selectedTo.value = { ...newValue }

        if (newValue.minutes + defaultAppointmentMinutes === 60) {
            selectedTo.value.hours = newValue.hours + 1
            selectedTo.value.minutes = newValue.minutes = 0
        } else if (newValue.minutes + defaultAppointmentMinutes > 60) {
            selectedTo.value.hours = newValue.hours + 1
            selectedTo.value.minutes = defaultAppointmentMinutes - 60 + newValue.minutes
        } else {
            selectedTo.value.hours = newValue.hours
            selectedTo.value.minutes = newValue.minutes + defaultAppointmentMinutes
        }
    }
)
</script>

<template>
    <div class="mx-auto mt-10 max-w-sm text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9">
        <CalendarInput @selectedDay:set="setSelectedDay" />
        <FieldError :message="form.errors.lead_id" class="mt-2" />
        <FieldError :message="form.errors.from" class="mt-4" />
        <FieldError v-if="form.errors.to && form.errors.to !== form.errors.from" :message="form.errors.to" class="mt-4" />
        <div class="mt-6 grid grid-cols-2 gap-2">
            <div class="flex flex-col">
                <label for="from" class="text-left text-xs font-medium text-gray-900">{{ $t('recruitment.leads.outcomes.call_appointments.from') }}</label>
                <VueDatePicker
                    v-model="selectedFrom"
                    time-picker
                    text-input
                    :text-input-options="timeInputOptions"
                    :placeholder="$t('recruitment.leads.outcomes.call_appointments.time')"
                    :config="{ modeHeight: 120 }"
                />
            </div>
            <div class="flex flex-col">
                <label for="to" class="block text-left text-xs font-medium text-gray-900">{{ $t('recruitment.leads.outcomes.call_appointments.to') }}</label>
                <VueDatePicker
                    v-model="selectedTo"
                    time-picker
                    text-input
                    :text-input-options="timeInputOptions"
                    :placeholder="$t('recruitment.leads.outcomes.call_appointments.time')"
                    @update:model-value="manuallySelectedFrom = true"
                    @cleared="manuallySelectedFrom = false"
                    :config="{ modeHeight: 120 }"
                />
            </div>
        </div>

        <button
            type="button"
            class="mt-4 w-full rounded-md border border-transparent bg-primary-600 py-2 text-sm font-medium text-white shadow hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:px-4"
            @click="submitCallAppointment"
        >
            {{ $t('recruitment.leads.outcomes.call_appointments.create') }}
        </button>
    </div>
</template>
