<script setup lang="ts">
import { computed, inject, onMounted, useSlots } from 'vue'

const props = defineProps<{
    tab: string
    label: string
}>()

const { activeTab, activeTabComponent } = inject<any>('tabs')

onMounted(() => {
    if (!activeTab.value) {
        setActiveTab()
    }
})

const slots = useSlots()

if (!activeTab) {
    throw new Error('LeadCard')
}

const isActive = computed(() => activeTab.value === props.tab)

function setActiveTab() {
    activeTab.value = props.tab
    activeTabComponent.value = slots.default
}
</script>

<template>
    <a
        @click="setActiveTab"
        v-bind:class="[
            isActive
                ? 'border-primary-500 text-primary-600'
                : 'border-transparent text-gray-500 hover:cursor-pointer hover:border-gray-300 hover:text-gray-700',
            'flex w-full justify-center whitespace-nowrap border-b-2 px-1 py-4 text-center text-sm font-medium',
        ]"
    >
        <i v-bind:class="[isActive ? 'text-primary-500' : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5']">
            <slot name="icon"></slot>
        </i>

        <span>{{ label }}</span>
    </a>
</template>
