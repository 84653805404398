<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useActiveButton } from '@app/composables/useDetailCard'
import { useUserCan } from '@app/composables/useUserCan'
import type { User } from '@app/types/shared'
import { MapPinIcon } from '@heroicons/vue/24/solid'
import WorkingRangeForm from '../WorkingRangeForm.vue'

defineProps<{ user: User }>()

const { can } = useUserCan()
const { toggle } = useActiveButton()
</script>

<template>
    <RoundButton v-if="can('user.working-range.store')" :label="$t('user.actions.working_range')" @click="toggle(WorkingRangeForm)">
        <MapPinIcon class="h-4 w-4" aria-hidden="true" />
    </RoundButton>
</template>
