<script setup lang="ts">
import RoundButton from '@app/components/ui/button/RoundButton.vue'
import { useActiveButton } from '@app/composables/useDetailCard'
import { useUserCan } from '@app/composables/useUserCan'
import type { Contract } from '@app/types/contracts'
import { CheckIcon } from '@heroicons/vue/16/solid'
import { router } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps<{
    contract: Contract
}>()

const { can, loggedInUser } = useUserCan()
const { hide } = useActiveButton()

const show = computed(
    () => can('contract.approve') && props.contract.status.slug === 'new' && (loggedInUser.is_super_admin || loggedInUser.id !== props.contract.created_by)
)

function approve() {
    router.post(
        route('contract.approve', { contract: props.contract.id }),
        {},
        {
            onSuccess() {
                hide()
            },
        }
    )
}
</script>

<template>
    <RoundButton v-if="show" :label="$t('contract.actions.approve')" @click="approve">
        <CheckIcon class="size-5" aria-hidden="true" />
    </RoundButton>
</template>
