<script lang="ts" setup generic="T extends object">
import { type InertiaForm } from '@inertiajs/vue3'
import { provide } from 'vue'

const props = defineProps<{
    form: InertiaForm<T>
}>()

const emit = defineEmits<{
    (event: 'submit'): void
}>()

provide('form', props.form)
</script>

<template>
    <form action="POST" @submit.prevent="emit('submit')" class="space-y-4">
        <slot></slot>
    </form>
</template>
