<script setup lang="ts">
import DetailItem from '@app/components/ui/details/DetailItem.vue'
import { useUserCan } from '@app/composables/useUserCan'
import type { User } from '@app/types/shared'
import { useForm } from '@inertiajs/vue3'
import { computed } from 'vue'
import { route } from 'ziggy-js'
import FieldError from '../ui/form/FieldError.vue'
import FileInputZone from '../ui/form/file/FileInputZone.vue'

const props = defineProps<{ user: User }>()

const { can, loggedInUser } = useUserCan()

const form = useForm({
    signature: props.user.signature_path,
})

function changeSignature() {
    form.post(route('user.update-signature', props.user.id), {
        only: ['selectedUser'],
    })
}

const canUpdateSignature = computed(() => props.user.id === loggedInUser.id || can('user.update-signature'))
</script>

<template>
    <div class="mt-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
        <DetailItem :label="$t('attributes.first_name')">
            {{ user.first_name }}
        </DetailItem>

        <DetailItem :label="$t('attributes.last_name')">
            {{ user.last_name }}
        </DetailItem>

        <DetailItem :label="$t('attributes.phone_number')">
            {{ user.phone_number }}
        </DetailItem>

        <DetailItem :label="$t('attributes.email')">
            {{ user.email }}
        </DetailItem>

        <DetailItem v-if="canUpdateSignature" :label="$t('attributes.signature')" :breakable="false">
            <FileInputZone class="mt-1 max-w-48" @change="changeSignature" v-model="form.signature" accept="image/png" :fileTypes="['.png']" :limit="5096" />

            <FieldError :message="form.errors.signature" />
        </DetailItem>
    </div>
</template>
