import { router } from '@inertiajs/vue3'
import { route } from 'ziggy-js'

export function updateFunnelBlockStatus(leadId: number, status: string, funnelBlock: string | null = null) {
    router.put(route('recruitment.lead.update-funnel-block-status', leadId), {
        status,
        funnelBlock,
    })
}

export function getLeadsLink(funnel?: string, funnelBlock?: string, params = {}) {
    return route('recruitment.lead.index', {
        funnel: funnel ?? route().params.funnel,
        funnelBlock: funnelBlock ?? route().params.funnelBlock,
        ...params,
    })
}

export function getLeadsStoredFilterName(funnel: string, funnelBlock: string) {
    return `recruitment.lead.${funnel}.${funnelBlock}.index`
}
