<script setup lang="ts">
import DetailCard from '@app/components/ui/card/DetailCard.vue'
import DetailCardLabel from '@app/components/ui/card/DetailCardLabel.vue'
import DetailCardTitle from '@app/components/ui/card/DetailCardTitle.vue'
import type { ShiftAvailability } from '@app/types/shifts'
import { type PropType } from 'vue'
import CreateShift from './actions/CreateShift.vue'
import DeleteAvailability from './actions/DeleteAvailability.vue'
import EditAvailability from './actions/EditAvailability.vue'

defineProps({
    availability: {
        type: Object as PropType<ShiftAvailability>,
        required: true,
    },
})

const emit = defineEmits(['close'])
</script>

<template>
    <DetailCard :model="availability" :dismissable="true" @close="emit('close')" class="w-full md:w-1/3">
        <div class="flex w-full flex-col items-center justify-between gap-4">
            <DetailCardTitle>Availability</DetailCardTitle>
            <div class="flex flex-col items-end gap-4 text-base">
                <DetailCardLabel :label="'Client project' + ' :'">
                    <span>Project name</span>
                </DetailCardLabel>
                <DetailCardLabel :label="'Date' + ' :'">
                    <span>18/09/2024</span>
                </DetailCardLabel>
                <DetailCardLabel :label="'Branch' + ' :'">
                    <span>18/09/2024</span>
                </DetailCardLabel>
            </div>
        </div>
        <template #actions>
            <DeleteAvailability />
            <EditAvailability />
            <CreateShift />
        </template>
    </DetailCard>
</template>
