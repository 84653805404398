<script setup lang="ts">
import type { ShiftAvailability } from '@app/types/shifts'
import { format } from 'date-fns'
import { computed, type PropType } from 'vue'

const props = defineProps({
    availability: {
        type: Object as PropType<ShiftAvailability>,
        required: true,
    },
})

const getEventColors = (project: string) => {
    const eventColors: { [key: string]: string } = {
        'gdl-npl': 'bg-primary-100 text-primary-700',
        'gdl-vl': 'bg-primary-100 text-primary-700',
        'kpn-promotions': 'bg-green-100 text-green-700',
        'kpn-field-marketing': 'bg-green-100 text-green-700',
        'kpn-telemarketing': 'bg-green-100 text-green-700',
        'hs-lottery': 'bg-red-100 text-red-700',
        'hs-donor-recruitment': 'bg-red-100 text-red-700',
        'kfw-donor-recruitment': 'bg-red-100 text-red-700',
        'fonky-recruitment': 'bg-secondary-100 text-secondary-700',
        'hs-telemarketing': 'bg-red-100 text-red-700',
        'kwf-telemarketing': 'bg-red-100 text-red-700',
        'solcon-field-marketing': 'bg-secondary-100 text-secondary-700',
        default: 'bg-gray-100 text-gray-700',
    }

    return eventColors[project] || eventColors.default
}

const isShift = (project: string) => {
    const shiftColors: { [key: string]: string } = {
        'gdl-npl': 'bg-primary-400',
        'gdl-vl': 'bg-primary-400',
        'kpn-promotions': 'bg-green-600',
        'kpn-field-marketing': 'bg-green-600',
        'kpn-telemarketing': 'bg-green-600',
        'hs-lottery': 'bg-red-600',
        'hs-donor-recruitment': 'bg-red-600',
        'kfw-donor-recruitment': 'bg-red-600',
        'fonky-recruitment': 'bg-secondary-500',
        'hs-telemarketing': 'bg-red-600',
        'kwf-telemarketing': 'bg-red-600',
        'solcon-field-marketing': 'bg-secondary-500',
        default: 'bg-gray-400',
    }

    return shiftColors[project] || shiftColors.default
}

const from = computed(() => format(new Date(props.availability.from), 'HH:mm'))
const to = computed(() => format(new Date(props.availability.to), 'HH:mm'))
</script>

<template>
    <div class="relative flex min-h-0 w-full flex-1 overflow-hidden text-ellipsis transition-all duration-300 hover:brightness-90">
        <a
            href="#"
            class="mx-pt group mx-1 flex w-full cursor-pointer flex-col overflow-y-auto whitespace-nowrap rounded-lg px-2 py-1 text-xs font-medium leading-5 duration-150 scrollbar-hide"
            :class="[
                getEventColors(availability.client_project_slug),
                availability.has_shift ? isShift(availability.client_project_slug) : '',
                availability.has_shift ? 'rounded-lg border-none text-white' : '',
            ]"
            :title="availability.client_project_name + ' - ' + availability.location_type_name + ' - ' + from + ' to ' + to"
        >
            <time class="flex flex-col font-light">{{ from }} - {{ to }}</time>
            <p class="whitespace-normal uppercase">{{ availability.client_project_name }} / {{ availability.location_type_name }}</p>
            <div class="flex flex-col font-light uppercase">
                <p class="italic">{{ availability.branch_name }}</p>
            </div>
        </a>
    </div>
</template>
