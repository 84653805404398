<script setup>
import { UserGroupIcon } from '@heroicons/vue/20/solid'
import { computed } from 'vue'
import { format } from 'date-fns'

const props = defineProps({
    title: String,
    content: String,
    from: String,
    to: String,
    colors: String,
})

const getEventColors = (name) => {
    const eventColors = {
        primary: 'bg-primary-400 hover:bg-primary-500',
        primaryLight: 'bg-primary-200 hover:bg-primary-300',
        secondary: 'bg-secondary-300 hover:bg-secondary-400',
        secondaryLight: 'bg-secondary-100 hover:bg-secondary-200',
        default: 'bg-gray-100 hover:bg-gray-200',
    }

    return eventColors[name] || eventColors.default
}

const from = computed(() => format(new Date(props.from), 'HH:mm'))
const to = computed(() => format(new Date(props.to), 'HH:mm'))
</script>

<template>
    <div
        class="group absolute inset-0.5 flex cursor-pointer flex-col overflow-y-auto rounded-lg p-1 text-xs leading-5 scrollbar-hide"
        :class="getEventColors(colors)"
    >
        <p class="order-1 mx-1 font-semibold text-gray-700">{{ title }}</p>
        <div class="mx-1 flex flex-col items-start justify-between font-medium text-gray-700 scrollbar-hide lg:flex-row">
            <time class="hidden whitespace-nowrap lg:flex" datetime="2022-01-12T06:00">{{ from }} - {{ to }}</time>
            <div class="flex items-center gap-1">
                <UserGroupIcon class="h-4 w-4" />
                <span>{{ content }}</span>
            </div>
        </div>
    </div>
</template>
